import { Modal } from "antd";
import * as Icons from "@ant-design/icons";
import * as React from "react";
import { isArray } from "lodash";
import { upload } from "@/api";

export const getRandomInt = (min: number, max: number) => {};

export const getTimeFormat = (
  time: string | Date | number,
  format = "yyyy-MM-dd HH:mm:ss"
): string => {
  const t = new Date(time);
  const year = t.getFullYear();
  const month = t.getMonth() + 1;
  const day = t.getDate();
  const hour = t.getHours();
  const minute = t.getMinutes();
  const second = t.getSeconds();

  return format
    .replace("yyyy", year.toString())
    .replace("MM", month.toString().padStart(2, "0"))
    .replace("dd", day.toString().padStart(2, "0"))
    .replace("HH", hour.toString().padStart(2, "0"))
    .replace("mm", minute.toString().padStart(2, "0"))
    .replace("ss", second.toString().padStart(2, "0"));
};

export const getDays = (time: string | Date | number): string => {
  const days = ["日", "一", "二", "三", "四", "五", "六"];
  return "周" + days[new Date(time).getDay()];
};

export const setIcons = (
  name: string,
  style?: React.CSSProperties
): React.ReactElement => {
  let element;
  try {
    element = Icons && (Icons as any)?.[name] && React.createElement((Icons as any)[name], {
      style,
    });
  } catch (error) {
    element = React.createElement("div", {});
  }
  return element;
};

export const getStorage = (key: string): string | Record<string, any> => {
  return JSON.parse(localStorage.getItem(key) || "null");
};

export const setStorage = (key: string, value: string | object): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeStorage = (key: string): void => {
  localStorage.removeItem(key);
};

export const showModal = (data: {
  title: string;
  content: string | React.ReactNode;
  footer?: null;
  type?: null;
}): Promise<any> => {
  const { title, content, footer } = data;
  const promise = new Promise((res, rej) => {
    Modal.confirm({
      title: title,
      content: content,
      closable: true,
      footer: footer,
      cancelText: "取消",
      okText: "确定",
      onOk() {
        res("ok");
      },
      onCancel() {
        res("cancel");
      },
    });
  });
  return promise;
};

interface TreeNode {
  id: number;
  name: string;
  parentId?: number;
  children?: TreeNode[];
}

export const convertToTree = (
  flatArray: any[],
  labelReset: { id: string; name: string; parentId: string } = {
    id: "id",
    name: "name",
    parentId: "parentId",
  }
): any[] => {
  const { id, name, parentId } = labelReset;
  const map = new Map<number, TreeNode>();
  const tree: TreeNode[] = [];

  flatArray.forEach((node) => {
    map.set(node[id], { ...node, children: null });
  });

  flatArray.forEach((node) => {
    if (node[parentId] !== 0) {
      const parent = map.get(node[parentId] as number);
      if (parent) {
        parent.children = parent.children || [];
        parent.children?.push(map.get(node[id])!);
      } else {
        tree.push(map.get(node[id])!);
      }
    } else {
      tree.push(map.get(node[id])!);
    }
  });
  return tree;
};

export const resetForm = (form: Record<string, any>): Record<string, any> => {
  for (const key in form) {
    if (form.hasOwnProperty(key)) {
      if (key === "ypageNum") {
        form[key] = 1;
      } else if (key === "ypageSize") {
        form[key] = 10;
      } else if (typeof form[key] === "string") {
        form[key] = "";
      } else if (typeof form[key] === "number") {
        form[key] = undefined;
      } else if (Array.isArray(form[key])) {
        form[key] = [];
      } else if (typeof form[key] === "object" && form[key] !== null) {
        form[key] = {};
      }
    }
  }
  return form;
};

export const logout = async () => {
  const data = await showModal({
    title: "提示",
    content: "确定注销并退出系统吗？",
  });
  if (data === "ok") {
    setStorage("Authorization", "");
    window.location.href = origin + "#/login";
    window.location.reload();
  }
};

export const downloadFile = (
  str: string,
  filename: string,
  fileType = "xlsx"
) => {
  const url = window.URL.createObjectURL(new Blob([str]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${filename}.${fileType}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

type TreeNodes = {
  label: string;
  id: number;
  children?: TreeNodes[];
};

export const findNodesWithoutChildren = (treeData: TreeNodes[]): number[] => {
  const result: number[] = [];
  const traverse = (nodes: TreeNodes[]) => {
    for (const node of nodes) {
      if (!node.children || node.children.length === 0) {
        result.push(node.id);
      } else {
        traverse(node.children);
      }
    }
  };

  traverse(treeData);
  return result;
};
export const getFirstDay = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const customRequest = async (options: any, list: any[]) => {
  const { file, onSuccess, onError, onProgress, onStart, onChange } = options;
  return new Promise(async (res, rej) => {
    try {
      const { data } = await upload(
        file
        // (progressEvent: { loaded: number; total: number }) => {
        //   const percent = Math.round(
        //     (progressEvent.loaded * 100) / (progressEvent.total || 0)
        //   );
        //   // setUploadProgress(percent);
        // }
      );
      onSuccess();
      return res([...list, { url: data, uid: file.uid, name: file.name }]);
      // setUploadProgress(0);
    } catch (error) {
      onError();
      return rej(null);
    }
  });
};
export const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

// export const uploadMessage = (message: string) => {
//       return (
//       <div>
//         {setIcons("PlusOutlined")}
//         <div style={{ marginTop: 8 }}>上传</div>
//       </div>
//     );
//     return uploadProgress ? (
//       <div>
//         <div>上传中...</div>
//         {/* <Progress
//           showInfo={false}
//           size="small"
//           percent={uploadProgress}
//           status={uploadProgress === 100 ? "success" : "active"}
//         /> */}
//       </div>
//     ) : (
//       <div>
//         {setIcons("PlusOutlined")}
//         <div style={{ marginTop: 8 }}>上传</div>
//       </div>
//     );
// }
