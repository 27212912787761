import { Button, Table, Pagination, Spin } from "antd";
import React, { useState } from "react";
import styles from "../../static/css/index.module.css";
import "./i-pageTable.css";
import type {
  RowSelectMethod,
  TableRowSelection,
} from "antd/es/table/interface";
import { setIcons } from "@/utils/common";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

const IPageTable = (props: {
  columns: any[];
  data: any[];
  selection?: boolean;
  pageChange?: (page: number, pageSize: number) => void;
  pagination?: { current?: number; pageSize?: number; total: number };
  rowKey?: string | ((arg0: any) => string);
  expandable?: any | undefined;
  size?: any;
  loading?: boolean;
  selectedRowKeys?: any[];
  selectionChange?:
    | ((
        selectedRowKeys: React.Key[],
        selectedRows: any[],
        info: {
          type: RowSelectMethod;
        }
      ) => void)
    | undefined;
}) => {
  const {
    columns,
    data,
    pagination,
    selection,
    pageChange,
    rowKey,
    selectionChange,
    expandable,
    size = "small",
    loading,
    selectedRowKeys,
  } = props;
  const [checkStrictly, setCheckStrictly] = useState(false);

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: selectionChange,
    onSelect: (record, selected, selectedRows) => {
      // console.log(record, 'record',  selected, 'selected', selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      // console.log(selected, selectedRows, changeRows);
    },
  };
  const getRowClassName = (record: any, index: number) => {
    return index % 2 === 0 ? "even-row" : "odd-row";
  };

  const [expandedIcon, setExpandedIcon] = useState(false);

  return (
    <div style={{ marginTop: "20px" }}>
      <Table
        bordered
        size={size}
        rowKey={rowKey}
        rowSelection={
          selection ? { ...rowSelection, checkStrictly } : undefined
        }
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={
          loading && { tip: "加载中...", indicator: <Spin size={size} /> }
        }
        rowClassName={getRowClassName}
        expandable={
          expandable && {
            ...expandable,
            expandIcon: ({ expanded, onExpand, record }) => 
            ((record?.children && record?.children.length) || record?.cmsChildOrderPageVos) && (
              <CaretDownOutlined
                className={expanded ? "expand-icon" : 'expand-icon1'}
                onClick={(e) => {
                  setExpandedIcon(!expanded);
                  return onExpand(record, e);
                }}
              />
            ),
          }
        }
        scroll={{ x: "max-content" }}
      />
      <div className={styles.pagination}>
        {pagination?.total ? (
          <Pagination
            defaultPageSize={10}
            defaultCurrent={1}
            {...pagination}
            showSizeChanger
            showQuickJumper
            onChange={pageChange}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default IPageTable;
