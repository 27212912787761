import { configureStore } from "@reduxjs/toolkit";
import userTreeReducer from "./userTreeReducer";
import userInfo from "./userInfo";
import syncStore from "./syncStore";
import dictInfo from "./dictInfo";

export default configureStore({
  reducer: {
    menuTree: userTreeReducer,
    userInfo,
    store: syncStore,
    dictInfo,
  },
});
