import React, { useEffect, useRef, useState } from "react";
import PageTab from "@/components/i-pageTab/i-pageTab";
import PageTable from "@/components/i-pageTable/i-pageTable";
import {
  setIcons,
  showModal,
  downloadFile,
  getTimeFormat,
  getFirstDay,
} from "@/utils/common";
import { storeList, storeDetail } from "@/api/merchant";
import { text } from "stream/consumers";
import { Button, Modal, Space, Tag } from "antd";
import IForm from "@/components/i-form/i-form";

const MerchantManager = () => {
  const [pageData, setPageData] = useState<SelectCmsMerStorePageRes["rows"]>(
    []
  );
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState<SelectCmsMerStorePageParams>({
    startDate: getTimeFormat(getFirstDay(new Date()), "yyyy-MM-dd HH:mm:ss"),
    endDate: getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss"),
    merMobile: "",
    merName: "",
    mobile: "",
    status: "",
    storeName: "",
    ypageNum: 1,
    ypageSize: 10,
  });

  const formTab = [
    {
      label: "商户手机号",
      name: "merMobile",
      type: "input",
    },
    {
      label: "商户名称",
      name: "merName",
      type: "input",
    },
    {
      label: "门店名称",
      name: "storeName",
      type: "input",
    },
    {
      label: "门店电话",
      name: "mobile",
      type: "input",
    },
    {
      label: "门店状态",
      name: "status",
      type: "select",
      options: [
        {
          label: "正常",
          value: "0",
        },
        {
          label: "下线",
          value: "1",
        },
      ],
    },
    {
      label: "创建时间",
      name: "rangePicker",
      type: "rangePicker",
      width: "350px",
      params: {},
    },
  ];

  // const footer = [
  //   {
  //     title: "导出",
  //     icon: setIcons("DownloadOutlined"),
  //     onClick: () => exportExcel(),
  //     loading: exportLoading,
  //   },
  // ];

  const columns = [
    {
      title: "门店logo",
      dataIndex: "doorPhotoUrl",
      key: "doorPhotoUrl",
      fixed: "left",
      render: (data: string) => {
        return <img style={{ height: "40px" }} src={data} alt="" />;
      },
    },
    {
      title: "门店名称",
      dataIndex: "storeName",
      key: "storeName",
      fixed: "left",
    },
    {
      title: "商户类型",
      dataIndex: "merTypeName",
      key: "merTypeName",
    },
    {
      title: "业务类型",
      dataIndex: "businessTypeName",
      key: "businessTypeName",
    },
    {
      title: "门店电话",
      dataIndex: "mobile",
      key: "mobile",
      fixed: "left",
    },
    {
      title: "法人名称",
      dataIndex: "contacts",
      key: "contacts",
    },
    {
      title: "商户名称",
      dataIndex: "merName",
      key: "merName",
    },
    {
      title: "门店详细地址",
      dataIndex: "address",
      key: "address",
      width: "240px",
    },
    {
      title: "是否推荐",
      dataIndex: "isRecommend",
      key: "isRecommend",
      render: (val: string) => <span>{val === "0" ? "是" : "否"}</span>,
    },
    {
      title: "评分星级",
      dataIndex: "star",
      key: "star",
    },

    {
      title: "门店纬度",
      dataIndex: "latitude",
      key: "latitude",
    },
    {
      title: "门店经度",
      dataIndex: "longitude",
      key: "longitude",
    },
    {
      title: "门店佣金比例",
      dataIndex: "storeRate",
      key: "storeRate",
    },

    {
      title: "门店状态",
      dataIndex: "status",
      key: "status",
      render: (val: string) => (
        <Tag color="blue">{val === "0" ? "正常" : "下线"}</Tag>
      ),
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "销量",
      dataIndex: "totalNum",
      key: "totalNum",
    },
    {
      title: "操作",
      dataIndex: "storeId",
      key: "storeId",
      fixed: "right",
      render: (val: string, rows: any) => (
        <Space>
          <Button
            size="small"
            icon={setIcons("SnippetsOutlined")}
            type="text"
            onClick={(e) => {
              outDetail(val);
            }}
          >
            详情
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await storeList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  // const exportExcel = async () => {
  //   setExportLoading(true);
  //   try {
  //     const data = await storeList(formData);
  //     downloadFile(
  //       data as any,
  //       "用户--门店与推荐会员订单统计" +
  //         getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss")
  //     );
  //   } catch (error) {
  //   } finally {
  //     setExportLoading(false);
  //   }
  // };

  const search = (val: any) => {
    setFormData({
      ...formData,
      ...val,
      ypageNum: 1,
    });
  };

  const outDetail = async(val: string) => {
    try {
    const {data}  = await storeDetail(val)
    console.log(data, 'ddddd');
    Modal.info({
      width: "48%",
      okText: "好的",
      icon: null,
      content: (
        <IForm
          formTab={[
            {
              label: "执照名称",
              type: "text",
              name: data.licenseName,
            },
            {
              label: "地区",
              type: "text",
              name: data.provinceName +data.cityName +data.countryName,
            },            
            {
              label: "身份证正面",
              type: "image",
              name: data.idCardPic1,
              span: 24,
              style: {
                height: "180px",
                width: "180px",
              },
            },
            {
              label: "身份证背面",
              type: "image",
              name: data.idCardPic2,
              span: 24,
              style: {
                width: "180px",
                height: "180px",
              },
            },
            {
              label: "营业执照",
              type: "image",
              name: data.licensePic,
              span: 24,
              style: {
                width: "180px",
                height: "180px",
              },
            },
            
            
          ]}
        ></IForm>)
    })
      
    } catch (error) {
      
    }
  }

  const reset = () => {
    setFormData({
      startDate: getTimeFormat(getFirstDay(new Date()), "yyyy-MM-dd HH:mm:ss"),
      endDate: getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss"),
      mobile: "",
      merMobile: "",
      ypageNum: 1,
      ypageSize: 10,
    });
  };

  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };
  return (
    <div>
      <PageTab
        search={search}
        reset={reset}
        formTab={formTab}
        // footer={footer}
      ></PageTab>
      <PageTable
        loading={loading}
        pageChange={pageChange}
        size="small"
        rowKey="userId"
        columns={columns}
        data={pageData}
        pagination={{
          current: formData.ypageNum!,
          pageSize: formData.ypageSize!,
          total: total,
        }}
      ></PageTable>
    </div>
  );
};

export default MerchantManager;
