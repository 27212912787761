import React, { useEffect, useState } from "react";
import PageTable from "@/components/i-pageTable/i-pageTable";
import PageTab from "@/components/i-pageTab/i-pageTab";
import { getincomeOrderList } from "@/api/order";
import {Tag } from "antd";
import { getFirstDay, getTimeFormat, resetForm, setIcons } from "@/utils/common";
import { useSelector, useDispatch, useStore } from "react-redux";
import { dictInfoAsync } from "@/stores/dictInfo";

const IncomeOrderList = () => {
  const dispatch = useDispatch();
  const {
    state: { rechange_status_type },
  } = useSelector((state: { dictInfo: any }) => state.dictInfo);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const [pageData, setPageData] = useState<CmsIncomeOrderListRes["rows"]>([]);

  const [formData, setFormData] = useState<CmsIncomeOrderListParams>({
    startTime: getTimeFormat(getFirstDay(new Date()), "yyyy-MM-dd HH:mm:ss"),
    endTime: getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss"),
    ypageNum: 1,
    ypageSize: 10,
  });
  const formTab = [
    {
      label: "订单号",
      name: "merOrderId",
      type: "input",
    },
    {
      label: "商家名称",
      name: "merName",
      type: "input",
    },
    {
      label: "交易状态",
      name: "rechangeStatus",
      type: "select",
      options: rechange_status_type,
    },
    {
      label: "创建时间",
      name: "rangePicker",
      type: "rangePicker",
      width: "350px",
      params: {},
    },
  ];
  const columns = [
    {
      title: "订单号",
      dataIndex: "merOrderId",
      key: "merOrderId",
    },
    {
      title: "商家名称",
      dataIndex: "merName",
      key: "merName",
    },
    {
      title: "手机号",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "原价",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "优惠价",
      dataIndex: "realAmount",
      key: "realAmount",
    },
    {
      title: "结算价",
      dataIndex: "finalPrice",
      key: "finalPrice",
    },
    {
      title: "交易状态",
      dataIndex: "rechangeStatus",
      key: "rechangeStatus",
      render: (val: string) => (
        <Tag color="processing">{rechange_status_type.find((i: {value: string})=> i.value === val)?.label}</Tag>
      )
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  useEffect(() => {
    !rechange_status_type && dispatch(dictInfoAsync("rechange_status_type") as any);
    
  }, []);

  const search = (val: any) => {
    const params = {
      ...val,
    }
    if (val.startDate) {
      params.startTime = val.startDate
      params.endTime = val.endDate
    }
    setFormData({
      ...formData,
      ...params,
      ypageNum: 1,
    });
  };

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await getincomeOrderList(formData);
      setPageData(data.rows.map((i: any, index: number)=> {
        return {
          ...i,
          id: index
        }
      }));
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };

  const reset = () => {
    setFormData({
      startTime: getTimeFormat(getFirstDay(new Date()), "yyyy-MM-dd HH:mm:ss"),
      endTime: getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss"),
      ypageNum: 1,
      ypageSize: 10,
    });
  };

  return (
    <div>
      <PageTab
        search={search}
        reset={reset}
        formTab={formTab}
        footer={[]}
      ></PageTab>
      <PageTable
        loading={loading}
        rowKey="id"
        pageChange={pageChange}
        data={pageData}
        columns={columns}
        pagination={{
          total: total,
          current: formData.ypageNum,
        }}
      ></PageTable>
    </div>
  );
};

export default IncomeOrderList;
