import { AxiosRequestConfig } from "axios";
import http from "../utils/request";

// CMS登录
export const loginIn = (params: LoginParams): Promise<LoginRes> => {
  return http.post("/login", params);
};
// CMS登录验证码
export const getCaptchaImage = (): Promise<GetCaptchaImageRes> => {
  return http.get("/captchaImage");
};
// CMS登录验证码
export const updatePwd = (params: {
  newPassword: string;
  oldPassword: string;
}): Promise<defaultRes> => {
  return http.post("/system/user/updatePwd", params);
};

// CMS获取menu树
export const getUserRouters = (): Promise<any> => {
  return http.get("/getRouters");
};

// CMS获取用户信息
export const getInfo = (): Promise<Record<string, any>> => {
  return http.get("/getInfo");
};
// // 查询字典
// export const dictInfoList = (params: GetCmsDictPageParams): Promise<GetCmsDictPageRes> => {
//   return http.post("/cms/auth/dict/info/list", params);
// };
// 查询字典
export const dictBaseList = (dictType: string): Promise<GetBaseDictVosRes> => {
  return http.get(`/system/dict/data/type/${dictType}`);
};
// 获取文件上传流
const config: AxiosRequestConfig<{ file: File }> = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};
export const upload = (
  file: any,
  onUploadProgress?: any
): Promise<defaultRes> => {
  return http.post(
    "/file/upload",
    { file: file },
    { ...config, onUploadProgress }
  );
};

// 首页趋势
export const getCmsIndexTendency = (params: {
  dateType: string;
  endDate?: string;
  startDate?: string;
  orderType?: string;
}): Promise<GetBaseDictVosRes> => {
  return http.get("/cms/auth/index/info/getCmsIndexTendency", { params });
};

// 首页统计
export const getCmsIndexVo = (): Promise<any> => {
  return http.get("/cms/auth/index/info/getCmsIndexVo");
};




// APP基础用户查询
export const userInfoBaseList = (
  mobile: string
): Promise<SelectCmsBaseAppUserListRes> => {
  return http.get(`/cms/auth/user/info/baseUser?mobile=${mobile}`);
};

// 商户基础信息查询
interface SelectCmsMerchantsBaseRes {
  code: number;
  data: {
    merId: string;
    merMobile: string;
    merName: string;
    userId: string;
  }[];
  msg: string;
}
export const storeInfoBaseList = (
  keywords: string
): Promise<SelectCmsMerchantsBaseRes> => {
  return http.get(`/cms/auth/mer/info/baseList?keywords=${keywords}`);
};

// 查询地区配置
export const mapBaseList = (params: {
  areaCode?: string | number;
  areaName?: string;
  level?: number;
  parentId?: string | number;
}): Promise<any> => {
  return http.post("/cms/auth/sys/area/baseList", params);
};

export const statistics = (
  type?: string
): Promise<SelectCmsMerchantsBaseRes> => {
  return http.get(`/index/statistics/${type}`);
};
