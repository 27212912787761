import React, { useEffect, useRef, useState } from "react";
import PageTab from "@/components/i-pageTab/i-pageTab";
import PageTable from "@/components/i-pageTable/i-pageTable";
import styles from "@/static/css/index.module.css";
import {
  getOperationStorePageList,
  operationFinanceMonthExport,
} from "@/api/operation";

import { downloadFile, getTimeFormat, setIcons } from "@/utils/common";
import { useSelector, useDispatch, useStore } from "react-redux";
import { dictInfoAsync } from "@/stores/dictInfo";
import dayjs from "dayjs";
import { Tag } from "antd";

const Store_detail = () => {
  const dispatch = useDispatch();

  const [pageData, setPageData] = useState<
    GetOperationStorePageListRes["rows"]
  >([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [formData, setFormData] = useState<GetOperationStorePageListParams>({
    ypageNum: 1,
    ypageSize: 10,
  });

  const formTab = [
    {
      label: "商户名称",
      name: "merName",
      type: "input",
    },
    {
      label: "商户手机号",
      name: "merMobile",
      type: "input",
    },
    {
      label: "门店名称",
      name: "storeName",
      type: "input",
    },
    {
      label: "门店手机号",
      name: "mobile",
      type: "input",
    },
    {
      label: "门店状态",
      name: "status",
      type: "select",
      options: [
        {
          label: "正常",
          value: "0",
        },
        {
          label: "禁用",
          value: "1",
        },
      ],
    },
  ];

  // const footer = [
  //   {
  //     title: "导出",
  //     icon: setIcons("DownloadOutlined"),
  //     onClick: () => exportExcel(),
  //     loading: exportLoading,
  //   },
  // ];

  const columns = [
    {
      title: "商户名称",
      dataIndex: "merName",
      key: "merName",
    },
    {
      title: "商户手机号",
      dataIndex: "merMobile",
      key: "merMobile",
    },
    {
      title: "门店名称",
      dataIndex: "storeName",
      key: "storeName",
    },
    {
      title: "门店电话",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "门店状态",
      dataIndex: "status",
      key: "status",
      render: (val: string) => (
        <Tag color="blue">{val === "0" ? "正常" : "禁用"}</Tag>
      ),
    },
    {
      title: "今日收入",
      dataIndex: "todayMerchantRevenue",
      key: "todayMerchantRevenue",
    },
    {
      title: "本月收入",
      dataIndex: "monthMerchantRevenue",
      key: "monthMerchantRevenue",
    },
    {
      title: "累计总收入",
      dataIndex: "totalMerchantRevenue",
      key: "totalMerchantRevenue",
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await getOperationStorePageList(formData);
      const page = data.rows?.map((i, v) => {
        return {
          ...i,
          key: v,
        };
      });
      setPageData(page);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const exportExcel = async () => {
    setExportLoading(true);
    try {
      const data = await operationFinanceMonthExport(formData);
      downloadFile(
        data as any,
        "运营商财务按月统计" + getTimeFormat(new Date(), "yyyy-MM")
      );
    } catch (error) {
    } finally {
      setExportLoading(false);
    }
  };

  const search = (val: any) => {
    setFormData({
      ...formData,
      ...val,
      ypageNum: 1,
    });
  };

  const reset = () => {
    setFormData({
      ypageNum: 1,
      ypageSize: 10,
    });
  };

  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };
  return (
    <div>
      <PageTab
        search={search}
        reset={reset}
        formTab={formTab}
      ></PageTab>
      <PageTable
        loading={loading}
        pageChange={pageChange}
        size="small"
        rowKey="key"
        columns={columns}
        data={pageData}
        pagination={{
          current: formData.ypageNum!,
          pageSize: formData.ypageSize!,
          total: total,
        }}
      ></PageTable>
    </div>
  );
};

export default Store_detail;
