export const orderType = {
  "10003": 0,
};

export const rechange_status_type = {
  10006001: "待付款",
  10006002: "交易成功",
  10006003: "交易关闭",
  10006004: "交易退款",
  10006005: "交易异常",
  10006006: "退款中",
};

export const isUse_enum: Record<string, string> = {
  "0": "已使用",
  "1": "未使用",
  "2": "已取消",
  "3": "退款中",
};

export const goodsType_enum: Record<string, string> = {
  "0": "普通商品",
  "1": "签到商品",
  "2": "自驾游",
  "3": "跟团游",
  "4": "赠送vip",
};

