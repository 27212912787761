import React, { useEffect, useRef, useState } from "react";
import PageTab from "@/components/i-pageTab/i-pageTab";
import PageTable from "@/components/i-pageTable/i-pageTable";
import {
  operationList,
  operationAdd,
  operationEdit,
  operationNegationStatus,
} from "@/api/operation";
import { mapBaseList } from "@/api/index";
import { Button, message, Modal, Space, Switch, Tag, Image } from "antd";
import { customRequest, normFile, setIcons, showModal } from "@/utils/common";
import { useSelector, useDispatch, useStore } from "react-redux";
import { dictInfoAsync } from "@/stores/dictInfo";

interface Option {
  value?: string | number | null;
  label: React.ReactNode;
  children?: Option[];
  isLeaf?: boolean;
}

const OperationManager = () => {
  const dispatch = useDispatch();
  const {
    state: { operation_center_type },
  } = useSelector((state: { dictInfo: any }) => state.dictInfo);

  const [pageData, setPageData] = useState<
    SelectCmsOperationCenterOperatorPageRes["rows"]
  >([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(false);

  const [open, setOpen] = useState(false);
  const [modalFormData, setModalFormData] = useState<pageHomeAddParams>({
    status: "0",
    voucherImgUrlsList: [],
  });
  const [mapList, setMapList] = useState<any>([]);

  const [formData, setFormData] = useState<GetCmsPageHomeParams>({
    isnew: "",
    status: "",
    systemType: "",
    title: "",
    ypageNum: 1,
    ypageSize: 10,
  });
  const modalFormTab = [
    {
      label: "运营商类型",
      name: "operationCenterType",
      type: "select",
      width: "320px",
      rules: [{ required: true, message: "请选择运营商类型" }],
      options: operation_center_type,
      params: {
        disabled: modalFormData.operatorId ? true : false,
      },
    },
    {
      label: "运营商名称",
      name: "operatorName",
      type: "input",
      width: "320px",
      rules: [{ required: true, message: "请输入运营商名称" }],
    },
    {
      label: "公司名称",
      name: "companyName",
      type: "input",
      width: "320px",
      rules: [{ required: true, message: "请输入公司名称" }],
      labelCol: { span: 0, offset: 1 },
    },
    {
      label: "地区",
      name: "cityCodeList",
      type: "cascader",
      width: "320px",
      labelCol: modalFormData.operatorId
        ? { span: 0, offset: 4 }
        : { span: 0, offset: 3 },
      rules: modalFormData.operatorId
        ? []
        : [{ required: true, message: "请选择地区" }],
      params: {
        placeholder: modalFormData.operatorId
          ? modalFormData.provinceName +
            modalFormData.countyName +
            modalFormData.cityName
          : "请选择地区",
        disabled: modalFormData.operatorId ? true : false,
        changeOnSelect: true,
        options: mapList,
        loadData: async (selectedOptions: Option[]) => {
          const targetOption = selectedOptions[
            selectedOptions.length - 1
          ] as any;
          try {
            const { data } = await mapBaseList({
              parentId: targetOption.value,
            });
            if (data) {
              targetOption.children = data?.map((i: any) => {
                return {
                  label: i.areaName,
                  value: i.areaCode,
                  children: [],
                  isLeaf: selectedOptions.length! >= 2 ? true : false,
                };
              });
              setMapList([...mapList]);
            }
          } catch (error) {}
        },
      },
    },
    {
      label: "办公地址",
      name: "companyAddr",
      type: "input",
      width: "320px",
      rules: [{ required: true, message: "请输入办公地址" }],
      labelCol: { span: 0, offset: 1 },
    },

    {
      label: "法人姓名",
      name: "payeeName",
      type: "input",
      width: "320px",
      labelCol: { span: 0, offset: 1 },
      rules: [{ required: true, message: "请输入法人姓名" }],
    },
    {
      label: "法人手机号",
      name: "legalPersonMobile",
      type: "input",
      width: "320px",
      rules: [{ required: true, message: "请输入法人手机号" }],
    },
    {
      label: "登录用户名",
      name: "mobile",
      type: "input",
      width: "320px",
      rules: [{ required: true, message: "请输入登录用户名或手机号" }],
      params: {
        disabled: modalFormData.operatorId ? true : false,
      },
    },
    {
      label: "打款凭证",
      name: "voucherImgUrlsList",
      type: "upload",
      labelCol: { span: 4, offset: 0 },
      width: "580px",
      rules: [{ required: true, message: "请上传打款凭证" }],
      getValueFromEvent: normFile,
      params: {
        maxCount: 4,
        fileList: modalFormData.voucherImgUrlsList,
        beforeUpload: () => {
          setUploadProgress(true);
        },
        uploadMessage: uploadProgress ? (
          <div>
            {setIcons("LoadingOutlined")}
            <div>上传中...</div>
          </div>
        ) : (
          <div>
            {setIcons("PlusOutlined")}
            <div style={{ marginTop: 8 }}>上传</div>
          </div>
        ),
        customRequest: async (e: any) => {
          try {
            const data = await customRequest(
              e,
              modalFormData.voucherImgUrlsList as any[]
            );
            setModalFormData({
              ...modalFormData,
              voucherImgUrlsList: data as any,
            });
          } catch (error) {
          } finally {
            setUploadProgress(false);
          }
        },
        onRemove: (e: any) => {
          setModalFormData({
            ...modalFormData,
            voucherImgUrlsList: modalFormData.voucherImgUrlsList?.filter(
              (i: any) => i.uid !== e.uid
            ),
          });
        },
      },
    },
  ];
  const formTab = [
    {
      label: "运营商名称",
      name: "operatorName",
      type: "input",
    },
    {
      label: "法人手机号",
      name: "legalPersonMobile",
      type: "input",
    },
    {
      label: "运营商类型",
      name: "operationCenterType",
      type: "select",
      options: operation_center_type,
    },
  ];

  const footer = [
    {
      title: "新增运营商",
      icon: setIcons("PlusOutlined"),
      onClick: () => setOpen(true),
    },
  ];

  const columns = [
    {
      title: "运营商名称",
      dataIndex: "operatorName",
      key: "operatorName",
    },
    {
      title: "公司名称",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "办公地址",
      dataIndex: "companyAddr",
      key: "companyAddr",
    },
    {
      title: "法人姓名",
      dataIndex: "payeeName",
      key: "payeeName",
    },
    {
      title: "法人手机号",
      dataIndex: "legalPersonMobile",
      key: "legalPersonMobile",
    },

    {
      title: "运营商类型",
      dataIndex: "operationCenterTypeLabel",
      key: "operationCenterTypeLabel",
      render: (val: string) => <Tag color="blue">{val}</Tag>,
    },
    {
      title: "可提现金额",
      dataIndex: "income",
      key: "income",
    },
    {
      title: "总收入",
      dataIndex: "totalIncome",
      key: "totalIncome",
    },
    {
      title: "打款凭证",
      dataIndex: "voucherImgList",
      key: "voucherImgList",
      render: (val: Array<string>) => (
        <div>
          {val.map((i: string, v: number) => (
            <Image height={80} src={i} key={v}></Image>
          ))}
        </div>
      ),
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (val: string, data: any) => (
        <Switch
          defaultChecked={val === "0"}
          checked={val === "0"}
          onClick={(_) => statusModal(data)}
        ></Switch>
      ),
    },
    {
      title: "机油代理",
      dataIndex: "isOilAgency",
      key: "isOilAgency",
      render: (val: string) => (
        <Tag color="blue">{val === "0" ? "是" : "否"}</Tag>
      ),
    },
    {
      title: "操作",
      dataIndex: "operatorId",
      key: "operatorId",
      fixed: "right",
      width: "100px",
      render: (val: string, rows: any) => (
        <Space size="small">
          <Button
            size="small"
            icon={setIcons("EditOutlined")}
            type="text"
            onClick={(e) => {
              setModalFormData({
                ...rows,
                voucherImgUrlsList: rows.voucherImgList.map((i: string) => {
                  return {
                    url: i,
                    uid: i,
                  };
                }),
              });
              setOpen(true);
            }}
          >
            修改
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);
  useEffect(() => {
    getMapList();
    !operation_center_type &&
      dispatch(dictInfoAsync("operation_center_type") as any);
  }, []);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await operationList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const add = () => {};

  const getMapList = async (options?: any, index?: number) => {
    try {
      const { data } = await mapBaseList({ level: 1 });
      setMapList(
        data.map((i: any) => {
          return {
            label: i.areaName,
            value: i.areaCode,
            children: [],
            isLeaf: false,
          };
        })
      );
    } catch (error) {}
  };

  const statusModal = async (item: any) => {
    const data = await showModal({
      title: "系统提示",
      content: (
        <div>确认要{item.status === "0" ? "禁用" : "启用"}选中的运营商吗？</div>
      ),
    });
    if (data === "ok") {
      try {
        await operationNegationStatus(item.operatorId);
        message.success("状态修改成功");
        getPageData();
      } catch (error) {}
    }
  };

  const search = (val: any) => {
    setFormData({
      ...formData,
      ...val,
      ypageNum: 1,
    });
  };

  const reset = () => {
    setFormData({
      isnew: "",
      status: "",
      systemType: "",
      title: "",
      ypageNum: 1,
      ypageSize: 10,
    });
  };
  const resetModal = () => {
    setModalFormData({
      status: "0",
      voucherImgUrlsList: [],
    });
    setOpen(false);
  };

  const confirmSend = async (val: any) => {
    try {
      const params = {
        ...val,
        voucherImgUrlsList: val.voucherImgUrlsList?.map(
          (i: { url: string }) => i.url
        ),
        provinceCode: val.cityCodeList?.[0],
        cityCode: val.cityCodeList?.[1],
        countyCode: val.cityCodeList?.[2],
      };
      delete params.cityCodeList;
      console.log(params, 'paramsparams');
      
      if (val.operatorId) {
        operationEdit(params);
        message.success("编辑成功");
      } else {
        await operationAdd(params);
        message.success("新增成功");
      }
      resetModal();
      getPageData();
    } catch (error) {}
  };

  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };
  return (
    <div>
      <Modal
        destroyOnClose
        open={open}
        title={modalFormData.operatorId ? "编辑运营商" : "新增运营商"}
        width="42%"
        footer={<></>}
        onCancel={resetModal}
      >
        <PageTab
          subFoot
          gap="12px 32px"
          search={confirmSend}
          reset={resetModal}
          formTab={modalFormTab}
          initialValues={modalFormData}
        ></PageTab>
      </Modal>
      <PageTab
        search={search}
        reset={reset}
        formTab={formTab}
        footer={footer}
      ></PageTab>
      <PageTable
        loading={loading}
        pageChange={pageChange}
        size="small"
        rowKey="operatorId"
        columns={columns}
        data={pageData}
        pagination={{
          current: formData.ypageNum!,
          pageSize: formData.ypageSize!,
          total: total,
        }}
      ></PageTable>
    </div>
  );
};

export default OperationManager;
