import React, { useEffect, useState } from "react";
import PageTab from "@/components/i-pageTab/i-pageTab";
import PageTable from "@/components/i-pageTable/i-pageTable";
import { setIcons, showModal } from "@/utils/common";
import {
  merInfoList,
  merchantsEdit,
  updateMerchantsStatus,
  merchantsClubBind,
  merDelete,
  merchantsAudit,
  getMerDetail,
} from "@/api/merchant";
import { userInfoBaseList } from "@/api/index";
import { operationBaseList } from "@/api/operation";

import { Button, Input, message, Modal, Space, Switch, Tag } from "antd";
import { useSelector, useDispatch, useStore } from "react-redux";
import { dictInfoAsync } from "@/stores/dictInfo";
import IForm from "@/components/i-form/i-form";

const { TextArea } = Input;

const MerInfo = () => {
  const dispatch = useDispatch();
  const {
    state: { entry_status },
  } = useSelector((state: { dictInfo: any }) => state.dictInfo);

  const [pageData, setPageData] = useState<
    SelectCmsMerchantInfoPageRes["rows"]
  >([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [bindOpen, setBindOpen] = useState(false);
  const [renterOpen, setRenterOpen] = useState(false);
  const [bindData, setBindData] = useState<{
    merName?: string;
    merId: string;
  }>({
    merId: "",
  });

  const [modalFormData, setModalFormData] = useState<MerchantsEditParams>();
  const [baseList, setBaseList] = useState<any>([]);

  const [zszData, setZszData] = useState<{
    label: string;
    value: string;
    vip?: number;
  } | null>();
  const [fwzData, setFwzData] = useState<{
    label: string;
    value: string;
    vip?: number;
  }>();

  const [formData, setFormData] = useState<SelectCmsMerchantInfoPageParams>({
    startDate: "",
    endDate: "",
    ypageNum: 1,
    ypageSize: 10,
  });

  const formTab = [
    {
      label: "入驻状态",
      name: "entryStatus",
      type: "select",
      options: entry_status,
    },
    {
      label: "商家名称",
      name: "merName",
      type: "input",
    },
    {
      label: "商家手机号",
      name: "merMobile",
      type: "input",
    },
    {
      label: "商户状态",
      name: "merStatus",
      type: "select",
      options: [
        {
          label: "启用",
          value: "0",
        },
        {
          label: "禁用",
          value: "1",
        },
      ],
    },
    {
      label: "时间范围",
      name: "rangePicker",
      type: "rangePicker",
      width: "360px",
      params: {},
    },
  ];
  const columns = [
    {
      title: "商家名称",
      dataIndex: "merName",
      key: "merName",
      fixed: "left",
    },

    {
      title: "商家手机号",
      dataIndex: "merMobile",
      key: "merMobile",
      fixed: "left",
    },
    {
      title: "昵称",
      dataIndex: "userNickName",
      key: "userNickName",
    },
    {
      title: "状态",
      dataIndex: "merStatus",
      key: "merStatus",
      render: (val: string, data: any) => (
        <Switch
          defaultChecked={val === "0"}
          checked={val === "0"}
          onClick={(_) => statusModal(data)}
        ></Switch>
      ),
    },
    {
      title: "入驻状态",
      dataIndex: "entryStatus",
      key: "entryStatus",
      render: (val: string) => (
        <Tag color="blue">
          {
            entry_status.find(
              (i: { label: string; value: string }) => i.value === val
            )?.label
          }
        </Tag>
      ),
    },
    {
      title: "所属俱乐部",
      dataIndex: "operatorName",
      key: "operatorName",
      width: "240px",
    },
    {
      title: "商户类型",
      dataIndex: "merTypeName",
      key: "merTypeName",
    },
    {
      title: "业务类型",
      dataIndex: "businessTypeName",
      key: "businessTypeName",
    },
    {
      title: "招商者",
      dataIndex: "investorMobile",
      key: "investorMobile",
    },
    {
      title: "招商者奖励",
      dataIndex: "investorRewardStatus",
      key: "investorRewardStatus",
      render: (val: number) => (
        <Tag color="blue">{val === 0 ? "否" : "是"}</Tag>
      ),
    },
    {
      title: "服务者",
      dataIndex: "attendantMobile",
      key: "attendantMobile",
    },
    {
      title: "服务者奖励",
      dataIndex: "attendantRewardStatus",
      key: "attendantRewardStatus",
      render: (val: number) => (
        <Tag color="blue">{val === 0 ? "否" : "是"}</Tag>
      ),
    },
    {
      title: "推荐人手机号",
      dataIndex: "inviterMobile",
      key: "inviterMobile",
    },
    {
      title: "推荐人昵称",
      dataIndex: "inviterUserNickName",
      key: "inviterUserNickName",
    },
    {
      title: "推荐人等级",
      dataIndex: "inviterVip",
      key: "inviterVip",
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "操作",
      dataIndex: "merId",
      key: "merId",
      fixed: "right",
      width: "100px",
      render: (val: string, rows: any) => (
        <Space size="small">
          {rows.entryStatus === "2" ? (
            <>
              <Button
                size="small"
                icon={setIcons("EditOutlined")}
                type="text"
                onClick={() => {
                  console.log(rows, "rows");
                  setModalFormData(rows);
                  setZszData({
                    label: rows.investorNickName,
                    value: rows.investorId,
                    vip: rows.investorVip,
                  });
                  setFwzData({
                    label: rows.attendantNickName,
                    value: rows.attendantId,
                    vip: rows.attendantVip,
                  });
                  setOpen(true);
                }}
              >
                编辑
              </Button>
              {rows.merType !==  "1"?(rows.isClub === 0? (
                <Button
                  size="small"
                  icon={setIcons("LinkOutlined")}
                  type="text"
                  onClick={(e) => {
                    setBindData({
                      merName: rows.merName,
                      merId: rows.merId,
                    });
                    setBindOpen(true);
                  }}
                >
                  绑定俱乐部
                </Button>
              ) : (
                <Button
                  size="small"
                  icon={setIcons("DisconnectOutlined")}
                  type="text"
                  onClick={() => {
                    resetBind(rows);
                  }}
                >
                  解绑俱乐部
                </Button>
              )):""}
            </>
          ) : (
            ""
          )}
          {rows.entryStatus === "0" ? (
            <>
              <Button
                size="small"
                icon={setIcons("SnippetsOutlined")}
                type="text"
                onClick={() => {
                  outDetail(val);
                }}
              >
                申请信息
              </Button>
              <Button
                size="small"
                icon={setIcons("CarryOutOutlined")}
                type="text"
                onClick={() => {
                  renterSave(rows);
                }}
              >
                入驻
              </Button>
              <Button
                size="small"
                icon={setIcons("StopOutlined")}
                type="text"
                onClick={() => {
                  setBindData(rows);
                  setRenterOpen(true);
                }}
              >
                驳回
              </Button>
            </>
          ) : (
            ""
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);
  useEffect(() => {
    !entry_status && dispatch(dictInfoAsync("entry_status") as any);
  }, []);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await merInfoList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (val: string) => {
    if (val.length !== 11) {
      return;
    }
    try {
      const { data } = await userInfoBaseList(val);
      setZszData({ label: data.nickName, value: data.userId, vip: data.vip });
    } catch (error) {}
  };
  const fwzHandleSearch = async (val: string) => {
    if (val.length !== 11) {
      return;
    }
    try {
      const { data } = await userInfoBaseList(val);
      setFwzData({ label: data.nickName, value: data.userId, vip: data.vip });
    } catch (error) {}
  };

  const resetBind = async (item: { merId: string }) => {
    const data = await showModal({
      title: "系统提示",
      content: <div>确认要解绑俱乐部吗？</div>,
    });
    if (data === "ok") {
      try {
        await merDelete(item.merId);
        message.success("俱乐部解绑成功");
        getPageData();
      } catch (error) {}
    }
  };

  const renterSave = async (item: { merId: string }) => {
    const data = await showModal({
      title: "系统提示",
      content: <div>确认要同意商户入驻吗？</div>,
    });
    if (data === "ok") {
      try {
        await merchantsAudit({
          merId: item.merId,
          entryStatus: "2",
        });
        message.success("商户入驻成功");
        getPageData();
      } catch (error) {}
    }
  };

  const statusModal = async (item: { merId: string; merStatus: "0" | "1" }) => {
    const data = await showModal({
      title: "系统提示",
      content: (
        <div>
          确认要{item.merStatus === "0" ? "禁用" : "启用"}选中的商户吗？
        </div>
      ),
    });
    if (data === "ok") {
      try {
        await updateMerchantsStatus({
          id: item.merId,
          status: item.merStatus === "0" ? "1" : "0",
        });
        message.success("商户状态修改成功");
        getPageData();
      } catch (error) {}
    }
  };

  const outDetail = async (val: string) => {
    try {
      const {data} = await getMerDetail(val);
      const storeInfo = data.storeInfo
      Modal.info({
        width: "48%",
        okText: "好的",
        icon: null,
        content: (
          <IForm
            formTab={[
              {
                label: "门店名称",
                name: storeInfo.storeName,
              },
              {
                label: "门店电话",
                name: storeInfo.mobile,
              },
              {
                label: "地区",
                name: data.provinceName + data.cityName + data.countryName,
              },
              
              {
                label: "门店详细地址",
                name: storeInfo.address,
              },

              {
                label: "门店logo",
                type: "image",
                name: storeInfo.doorPhotoUrl,
                span: 24,
                style: {
                  height: "120px",
                  width: "120px",
                },
              },
              // {
              //   label: "营业执照",
              //   type: "image",
              //   name: storeInfo.licensePic,
              //   span: 24,
              //   style: {
              //     height: "120px",
              //     width: "120px",
              //   },
              // },
              // {
              //   label: "身份证正面",
              //   type: "image",
              //   name: storeInfo.idCardPic1,
              //   span: 24,
              //   style: {
              //     height: "120px",
              //     width: "120px",
              //   },
              // },
              // {
              //   label: "身份证背面",
              //   type: "image",
              //   name: storeInfo.idCardPic2,
              //   span: 24,
              //   style: {
              //     height: "120px",
              //     width: "120px",
              //   },
              // },
              {
                label: "门店图片",
                type: "imageList",
                name: data.images,
                span: 24,
                style: {
                  height: "120px",
                  width: "160px",
                },
              },
              
            ]}
          ></IForm>
        ),
      });
    } catch (error) {}
  };

  const search = (val: any) => {
    setFormData({
      ...formData,
      ...val,
      ypageNum: 1,
    });
  };

  const reset = () => {
    setFormData({
      startDate: "",
      endDate: "",
      ypageNum: 1,
      ypageSize: 10,
    });
  };

  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };

  const resetModal = () => {
    setModalFormData({
      merId: "",
    });
    setOpen(false);
  };

  const confirmSend = async (val: any) => {
    if ((zszData?.vip || zszData?.vip === 0) && (zszData?.vip || 0) < 4) {
      message.error("招商者仅能绑定“V4”用户");
      return;
    }
    if ((fwzData?.vip || fwzData?.vip === 0) && (fwzData?.vip || 0) < 4) {
      message.error("服务者仅能绑定“V4”用户");
      return;
    }
    try {
      await merchantsEdit(val);
      message.success("编辑成功");
      resetModal();
      getPageData();
    } catch (error) {}
  };

  const getBaseList = async (name: string) => {
    try {
      const { data } = await operationBaseList({
        operationCenterType: "10120003",
        operatorName: name,
      });
      setBaseList(data);
    } catch (error) {}
  };

  const confirmBind = async (val: { operationId: string }) => {
    try {
      await merchantsClubBind({
        merchantId: bindData.merId,
        operationId: val.operationId,
      });
      message.success("商户绑定俱乐部成功");
      setBindOpen(false);
      getPageData();
    } catch (error) {}
  };

  const confirmRenter = async (val: { entryRemark: string }) => {
    try {
      await merchantsAudit({
        merId: bindData.merId,
        entryStatus: "1",
        entryRemark: val.entryRemark,
      });
      message.success("已驳回");
      setRenterOpen(false);
      getPageData();
    } catch (error) {}
  };

  const renterModalFormTab = [
    {
      label: "商家名称",
      name: "merName",
      type: "text",
      width: "320px",
      labelCol: { span: 0, offset: 1 },
      params: {
        text: bindData.merName,
      },
    },
    {
      label: "驳回原因",
      name: "entryRemark",
      type: "textArea",
      width: "320px",
      rules: [{ required: true, message: "请填写驳回原因" }],
    },
  ];

  const bindModalFormTab = [
    {
      label: "商家名称",
      name: "merName",
      type: "text",
      width: "320px",
      params: {
        text: bindData.merName,
      },
    },
    {
      label: "俱乐部",
      name: "operationId",
      type: "select",
      width: "320px",
      rules: [{ required: true, message: "请选择俱乐部" }],
      options: (baseList || []).map(
        (d: { operatorId: string; operatorName: string }) => ({
          value: d.operatorId,
          label: d.operatorName,
        })
      ),
      params: {
        defaultActiveFirstOption: false,
        showSearch: true,
        filterOption: false,
        onSearch: (e: string) => {
          getBaseList(e);
        },
      },
    },
  ];

  const modalFormTab = [
    {
      label: "商家名称",
      name: "merName",
      type: "input",
      width: "320px",
      rules: [{ required: true, message: "请输入运营商名称" }],
      // labelCol: { span: 0, offset: 1 },
    },
    {
      label: "招商者",
      name: "investorId",
      type: "select",
      width: "320px",
      labelCol: { span: 0, offset: 2 },
      options: [zszData],
      params: {
        showSearch: true,
        onSearch: handleSearch,
        filterOption: false,
        defaultActiveFirstOption: false,
        onClear: () => {
          setZszData({
            label: "",
            value: "",
          });
          setModalFormData({
            ...modalFormData,
            investorId: "",
          } as any);
        },
      },
      // rules: [{ required: true, message: "请选择招商者" }],
    },
    {
      label: "招商者奖励",
      name: "investorRewardStatus",
      type: "radio",
      width: "320px",
      // labelCol: { span: 0, offset: 1 },
      options: [
        { value: 0, label: "否" },
        { value: 1, label: "是" },
      ],
    },
    {
      label: "服务者",
      name: "attendantId",
      type: "select",
      width: "320px",
      labelCol: { span: 0, offset: 2 },
      options: [fwzData],
      params: {
        showSearch: true,
        onSearch: fwzHandleSearch,
        filterOption: false,
        defaultActiveFirstOption: false,
        onClear: () => {
          setFwzData({
            label: "",
            value: "",
          });
          setModalFormData({
            ...modalFormData,
            attendantId: "",
          } as any);
        },
      },
    },
    {
      label: "服务者奖励",
      name: "attendantRewardStatus",
      type: "radio",
      width: "320px",
      style: {
        marginBottom: "102px",
      },
      options: [
        { value: 0, label: "否" },
        { value: 1, label: "是" },
      ],
    },
  ];

  return (
    <div>
      <Modal
        destroyOnClose
        open={renterOpen}
        title="驳回"
        width="28%"
        footer={<></>}
        onCancel={() => setRenterOpen(false)}
      >
        <div style={{ position: "relative" }}>
          <PageTab
            subFoot
            search={confirmRenter}
            formTab={renterModalFormTab}
            reset={() => setRenterOpen(false)}
          ></PageTab>
        </div>
      </Modal>
      <Modal
        destroyOnClose
        open={bindOpen}
        title="绑定俱乐部"
        width="24%"
        footer={<></>}
        onCancel={() => setBindOpen(false)}
      >
        <div style={{ position: "relative" }}>
          <PageTab
            subFoot
            search={confirmBind}
            formTab={bindModalFormTab}
            reset={() => setBindOpen(false)}
          ></PageTab>
        </div>
      </Modal>
      <Modal
        destroyOnClose
        open={open}
        title="编辑商家"
        width="24%"
        footer={<></>}
        onCancel={resetModal}
      >
        <div style={{ position: "relative" }}>
          <PageTab
            subFoot
            gap="12px 32px"
            search={confirmSend}
            reset={resetModal}
            formTab={modalFormTab}
            initialValues={modalFormData}
          ></PageTab>
          <div
            style={{ position: "absolute", bottom: "90px", color: "#6e6e6e" }}
          >
            {/* <span>1. 招商者和服务者仅能绑定“V4”用户</span>
            <br /> */}
            <span>
              1.招商者或服务者奖励开关关闭后，则该商家的机油订单、会员订单不会发放给招商者或服务者
            </span>
          </div>
        </div>
      </Modal>
      <PageTab
        search={search}
        reset={reset}
        formTab={formTab}
        defaultTimer={[]}
        // footer={footer}
      ></PageTab>
      <PageTable
        loading={loading}
        pageChange={pageChange}
        size="small"
        rowKey="merId"
        columns={columns}
        data={pageData}
        pagination={{
          current: formData.ypageNum!,
          pageSize: formData.ypageSize!,
          total: total,
        }}
      ></PageTable>
    </div>
  );
};

export default MerInfo;
