import React, { useEffect, useRef, useState } from "react";
import PageTable from "@/components/i-pageTable/i-pageTable";
import PageTab from "@/components/i-pageTab/i-pageTab";
import {
  dictList,
  dictAdd,
  dictEdit,
  dictTypeOptionSelect,
  dictDelete,
  refreshCache,
} from "@/api/setting";
import { Button, message, Modal, Space, Tag } from "antd";
import { resetForm, setIcons, showModal } from "@/utils/common";
import { useLocation, useParams } from "react-router-dom";
const Dict = () => {
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectKeys, setSelectKeys] = useState<any[]>([]);
  const [pageData, setPageData] = useState<GetCmsDictPageRes["rows"]>([]);
  const [dictTypeList, setDictTypeList] = useState<{label: string, value: string}[]>([]);
  const params = useParams()
  const [formData, setFormData] = useState<any>({
    dictType: params.id,
    status: "",
    ypageNum: 1,
    ypageSize: 10,
  });
  const [modalFormData, setModalFormData] = useState<any>({
    label: "",
    status: "0",
    value: "",
  });

  const formTab = [
    {
      label: "字典名称",
      name: "dictType",
      type: "select",
      params: {
        defaultValue: formData.dictType,
        showSearch: true,
        allowClear: false,
        filterOption: (input: any, option: { label: any; }) => (option?.label ?? '').includes(input)
      },
      options: dictTypeList,
    },
    {
      label: "状态",
      name: "status",
      type: "select",
      options: [
        { value: "0", label: "正常" },
        { value: "1", label: "停用" },
      ],
    },
  ];

  const footer = [
    {
      title: "新增",
      icon: setIcons("PlusOutlined"),
      onClick: () => {
        handleAdd();
      },
    },
    // {
    //   title: "修改",
    //   icon: setIcons("EditOutlined"),
    //   disabled: selectKeys.length !== 1,
    //   onClick: () => {
    //     handleEdit(selectKeys[0]);
    //   },
    // },
    {
      title: "停用",
      icon: setIcons("DeleteOutlined"),
      disabled: !selectKeys.length,
      onClick: () => {
        handleDelete(selectKeys);
      },
    },

  ];

  const modalFormTab = [
    {
      label: "字典类型",
      name: "dictType",
      type: "input",
      params: {
        defaultValue: formData.dictType,
        // placeholder: "请选择字典类型",
        disabled: true,
        // filterOption: (input: any, option: { label: any; }) => (option?.label ?? '').includes(input)
      },
    },
    {
      label: "字典值",
      name: "dictLabel",
      type: "input",
      width: "300px",
      rules: [{ required: true, message: "请填写字典值" }],
    },
    {
      label: "字典键",
      name: "dictValue",
      type: "input",
      width: "300px",
      labelCol: { span: 0, offset: 1 },
      rules: [{ required: true, message: "请填写字典键" }],
    },
    {
      label: "字典状态",
      name: "status",
      type: "radio",
      labelCol: { span: 0, offset: 1 },
      options: [
        { value: "0", label: "正常" },
        { value: "1", label: "停用" },
      ],
    },
    {
      label: "备注",
      name: "remark",
      type: "textArea",
      labelCol: { span: 0, offset: 2 },
      width: "300px",
      rules: [{ required: true, message: "请填写备注" }],
      params: {
        placeholder: "请输入备注",
        rows: 3,
        maxLength: 100,
      },
    },
    {
      label: "排序",
      name: "dictSort",
      type: "input",
      width: "300px",
      labelCol: { span: 0, offset: 2 },
      params: {
        type: "number",
      },
      rules: [{ required: true, message: "请输入排序" }],
    },
  ];

  const columns = [
    {
      title: "字典Id",
      dataIndex: "dictCode",
      key: "dictCode",
    },
    {
      title: "字典类型",
      dataIndex: "dictType",
      key: "dictType",
    },
    {
      title: "字典键",
      dataIndex: "dictValue",
      key: "dictValue",
    },
    {
      title: "字典值",
      dataIndex: "dictLabel",
      key: "dictLabel",
    },
    {
      title: "备注",
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (val: string) => (
        <Tag color="blue">{val === "0" ? "正常" : "停用"}</Tag>
      ),
    },
    {
      title: "操作",
      dataIndex: "dictCode",
      key: "dictCode",
      render: (val: string, rows: any) => (
        <Space size="small">
          <Button
            size="small"
            icon={setIcons("EditOutlined")}
            type="text"
            onClick={() => {
              handleEdit(rows);
            }}
          >
            修改
          </Button>
          <Button
            size="small"
            icon={setIcons("DisconnectOutlined")}
            type="text"
            onClick={() => {
              handleDelete(val);
            }}
          >
            停用
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);
  useEffect(() => {
    
    getDictTypeOptionSelect('');
  }, []);
  

  const getDictTypeOptionSelect = async (dictTypeName: string) => {
    const { data } = await dictTypeOptionSelect(dictTypeName) as any;
    setDictTypeList(
      data.map((i: { dictType: string; dictName: string }) => {
        return {
          label: i.dictName,
          value: i.dictType,
        };
      })
    );
  };

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await dictList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const search = (val: any) => {
    setFormData({ ...formData, ...val, ypageNum: 1 });
  };
  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };

  const handleAdd = () => {
    setOpen(true);
  };

  const reset = () => {
    setFormData({ ...resetForm(formData), dictType: params.id });
    setSelectKeys([]);
  };
  const confirmData = async (val: any) => {
    try {
      if (val.dictCode) {
        await dictEdit(val);
        message.success("编辑成功");
      } else {
        await dictAdd({...val, dictType: formData.dictType});
        message.success("新增成功");
      }
      modalReset();
      getPageData();
    } catch (error) {}
  };
  const modalReset = () => {
    setModalFormData({ status: "0" });
    setOpen(false);
  };
  const selectionChange = (keys: any[]) => {
    setSelectKeys(keys);
  };

  const handleEdit = async (val: any) => {
    try {
      setModalFormData(val);
      setOpen(true);
    } catch (error) {}
  };

  const handleDelete = async (val: string | any[]) => {
    try {
      const data = await showModal({
        title: "提示",
        content: "确定要停用选中的字典吗？",
      });
      if (data === "ok") {
        await dictDelete(val);
        message.success("停用成功");
        modalReset();
        getPageData();
      }
    } catch (error) {}
  };

  const reverseCache = async () => {
    try {
      await refreshCache();
      message.success("刷新成功");
    } catch (error) {}
  };

  return (
    <div>
      <Modal
        destroyOnClose
        open={open}
        title={modalFormData.dictCode ? "编辑字典" : "新增字典"}
        width="24%"
        footer={<></>}
        onCancel={modalReset}
      >
        <PageTab
          subFoot
          search={confirmData}
          reset={modalReset}
          formTab={modalFormTab}
          initialValues={modalFormData}
        ></PageTab>
      </Modal>
      <PageTab
        reset={reset}
        search={search}
        formTab={formTab}
        footer={footer}
      ></PageTab>
      <PageTable
        selection
        selectionChange={selectionChange}
        selectedRowKeys={selectKeys}
        loading={loading}
        rowKey="dictCode"
        pageChange={pageChange}
        data={pageData}
        columns={columns}
        pagination={{
          total: total,
          current: formData.ypageNum,
        }}
      ></PageTable>
    </div>
  );
};

export default Dict;
