import React, { useEffect, useState } from "react";
import PageTab from "@/components/i-pageTab/i-pageTab";
import PageTable from "@/components/i-pageTable/i-pageTable";
import {
  listCashOrder,
  addCashOrder,
  operationBaseList,
} from "@/api/operation";
import { Button, message, Modal, Space, Image } from "antd";
import { customRequest, normFile, setIcons, showModal } from "@/utils/common";
import { useSelector, useDispatch } from "react-redux";
import { dictInfoAsync } from "@/stores/dictInfo";

const IncomeFoot = () => {
  const dispatch = useDispatch();
  const {
    state: { app_store_type },
  } = useSelector((state: { dictInfo: any }) => state.dictInfo);
  const [baseList, setBaseList] = useState<any>([]);
  const [uploadProgress, setUploadProgress] = useState(false);

  const [pageData, setPageData] = useState<ListCashOrderRes["rows"]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalFormData, setModalFormData] = useState({
    listVoucherUrl: [],
    income:0,
    totalIncome: 0
  });

  const [formData, setFormData] = useState<ListCashOrderParams>({
    ypageNum: 1,
    ypageSize: 10,
  });
  const modalFormTab = [
    {
      label: "运营商",
      name: "operatorId",
      type: "select",
      labelCol: { span: 0, offset: 1 },
      rules: [{ required: true, message: "请选择运营商" }],
      options: (baseList || []).map(
        (d: { operatorId: string; operatorName: string }) => ({
          value: d.operatorId,
          label: d.operatorName,
        })
      ),
      params: {
        defaultActiveFirstOption: false,
        showSearch: true,
        filterOption: false,
        onSearch: (e: string) => {
          getBaseList(e);
        },
        onSelect: (e: string)=> {
          const data = baseList?.find((i: {operatorId: string})=> i.operatorId === e)
          setModalFormData({
            ...modalFormData,
            income:data.income,
            totalIncome: data.totalIncome,
          })
          console.log(baseList, 'baseListbaseList');
          
          console.log(e, 'eee');
          
        }
      },
    },
    {
      label: "总收益",
      name: "amount",
      type: 'text',
      labelCol: { span: 0, offset: 2 },
      params: {
        text: modalFormData.totalIncome + '',
      },
    },
    {
      label: "可提现金额",
      name: "amount",
      type: "text",
      params: {
        text: modalFormData.income + '',
      },
    },
    {
      label: "",
      name: "",
      // labelCol: { span: 0, offset: 4 },
      type: "text",
      params: {
        style: {marginLeft: '20px', width: '400px', display: 'flex', color: 'red'},
        text: "请对完账收到运营中心发票后，打款至运营中心对公账户后，在此处填写打款金额及上传打款凭证。填写打款金额并确定后，运营商的收益余额将扣除填写的打款金额，请仔细核对后再进行操作",
      },
    },

    {
      label: "提现金额",
      name: "amount",
      type: "input",
      params: {
        type: "number",
      },
      rules: [{ required: true, message: "请输入提现金额" }],
    },
    {
      label: "打款凭证",
      name: "listVoucherUrl",
      type: "upload",
      width: "580px",
      rules: [{ required: true, message: "请上传打款凭证" }],
      getValueFromEvent: normFile,
      params: {
        maxCount: 1,
        fileList: modalFormData.listVoucherUrl,
        beforeUpload: () => {
          setUploadProgress(true);
        },
        uploadMessage: uploadProgress ? (
          <div>
            {setIcons("LoadingOutlined")}
            <div>上传中...</div>
          </div>
        ) : (
          <div>
            {setIcons("PlusOutlined")}
            <div style={{ marginTop: 8 }}>上传</div>
          </div>
        ),
        customRequest: async (e: any) => {
          try {
            const data = await customRequest(
              e,
              modalFormData.listVoucherUrl as any[]
            );
            setModalFormData({
              ...modalFormData,
              listVoucherUrl: data as any,
            });
          } catch (error) {
          } finally {
            setUploadProgress(false);
          }
        },
        onRemove: (e: any) => {
          setModalFormData({
            ...modalFormData,
            listVoucherUrl: modalFormData.listVoucherUrl?.filter(
              (i: any) => i.uid !== e.uid
            ),
          });
        },
      },
    },
  ];
  const formTab = [
    {
      label: "结算单号",
      name: "cashOrderId",
      type: "input",
    },
    {
      label: "手机号",
      name: "mobile",
      type: "input",
    },
    {
      label: "运营商名称",
      name: "operatorName",
      type: "input",
    },
  ];

  const footer = [
    {
      title: "新增收益结算",
      icon: setIcons("PlusOutlined"),
      onClick: () => setOpen(true),
    },
  ];

  const columns = [
    {
      title: "结算单号",
      dataIndex: "cashOrderId",
      key: "cashOrderId",
      width: "220px",
    },
    {
      title: "结算金额",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "运营商名称",
      dataIndex: "operatorName",
      key: "operatorName",
    },
    {
      title: "法人手机号",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "开户银行支行",
      dataIndex: "bankName",
      key: "bankName",
    },
    {
      title: "收款账号",
      dataIndex: "bankcardNum",
      key: "bankcardNum",
    },
    {
      title: "收款户名",
      dataIndex: "payeeName",
      key: "payeeName",
    },
    {
      title: "对公账户开户证明",
      dataIndex: "corporatePic",
      key: "corporatePic",
      render: (val: string) => <Image src={val} height="100px"></Image>,
    },
    {
      title: "	打款凭证图片",
      dataIndex: "voucherImgList",
      key: "voucherImgList",
      render: (val: Array<string>) => (
        <div>
          {val.map((i: string, k: number) => (
            <Image src={i} height="100px" key={k}></Image>
          ))}
        </div>
      ),
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    // {
    //   title: "操作",
    //   dataIndex: "id",
    //   key: "id",
    //   fixed: "right",
    //   width: "120px",
    //   render: (val: string, rows: any) => (
    //     <Space>
    //       <Button
    //         size="small"
    //         icon={setIcons("EditOutlined")}
    //         type="text"
    //         onClick={(e) => {
    //           setModalFormData({
    //             ...rows,
    //             url: [{ url: rows.url, uid: rows.url, name: "" }],
    //           });
    //           setOpen(true);
    //         }}
    //       >
    //         修改
    //       </Button>
    //     </Space>
    //   ),
    // },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  useEffect(() => {
    !app_store_type && dispatch(dictInfoAsync("app_store_type") as any);
  }, []);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await listCashOrder(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const search = (val: any) => {
    setFormData({
      ...formData,
      ...val,
      ypageNum: 1,
    });
  };

  const reset = () => {
    setFormData({
      ypageNum: 1,
      ypageSize: 10,
    });
  };
  const resetModal = () => {
    setModalFormData({
      income:0,
      totalIncome: 0,
      listVoucherUrl: [],
    });
    setBaseList([])
    setOpen(false);
  };

  const confirmSend = async (val: any) => {
    try {
      const res =  await showModal({
        title: "提示",
        content: "请仔细核对结算金额，一旦成功提交，运营中心收益余额将扣除填写的结算金额，并留存记录。",
      })
      if (res !== 'ok') {
        return
      }
      const params = {
        ...val,
        listVoucherUrl: val.listVoucherUrl?.map((i: { url: string }) => i.url),
      };
      await addCashOrder(params);
      message.success("新增结算记录成功");
      resetModal();
      getPageData();
    } catch (error) {}
  };

  const getBaseList = async (name: string) => {
    try {
      const { data } = await operationBaseList({
        operatorName: name,
      });
      setBaseList(data);
    } catch (error) {}
  };

  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };
  return (
    <div>
      <Modal
        destroyOnClose
        open={open}
        title="新增结算记录"
        width="32%"
        footer={<></>}
        onCancel={resetModal}
      >
        <PageTab
          subFoot
          search={confirmSend}
          reset={resetModal}
          formTab={modalFormTab}
          initialValues={modalFormData}
        ></PageTab>
      </Modal>
      <PageTab
        search={search}
        reset={reset}
        formTab={formTab}
        footer={footer}
      ></PageTab>
      <PageTable
        loading={loading}
        pageChange={pageChange}
        size="small"
        rowKey="cashOrderId"
        columns={columns}
        data={pageData}
        pagination={{
          current: formData.ypageNum!,
          pageSize: formData.ypageSize!,
          total: total,
        }}
      ></PageTable>
    </div>
  );
};

export default IncomeFoot;
