import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Radio,
  DatePicker,
  Space,
  TreeSelect,
  Tree,
  Upload,
  Cascader,
} from "antd";
import styles from "../../static/css/index.module.css";
import {
  getFirstDay,
  getStorage,
  getTimeFormat,
  setIcons,
} from "../../utils/common";
import type { FormItemProps } from "antd";
import "dayjs/locale/zh-cn";
import locale from "antd/es/date-picker/locale/zh_CN";
import dayjs, { Dayjs } from "dayjs";
import { FormLayout } from "antd/es/form/Form";
import { SpaceSize } from "antd/es/space";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FormInstance } from "antd/lib";

const { RangePicker } = DatePicker;
const { TextArea } = Input;


const quillModules = {
  toolbar: {
    container: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      [{ size: ['small', false, 'large', 'huge'] }], //字体设置
      [
        {
          color: [],
        },
      ],
      [
        {
          background: [],
        },
      ],
      [{ font: [] }],
      [{ align: [] }],
      ['link', 'image'], // a链接和图片的显示
    ],
  },
}
interface FormTabItem extends FormItemProps {
  type: string;
  width?: string;
  options?: any[];
  params?: Record<string, any>;
  notShow?: Boolean;
}

const IFormTab = (props: {
  content?: { data: string | number; type: string };
  formTab: FormTabItem[];
  subFoot?: boolean;
  search?: Function | undefined;
  reset?: React.FormEventHandler<HTMLFormElement> | undefined;
  footer?: any[];
  initialValues?: Record<string, any> | undefined;
  layout?: FormLayout;
  size?: SpaceSize | [SpaceSize, SpaceSize];
  gap?: string;
  defaultTimer?: any;
  form?: FormInstance;
}) => {

  const {
    formTab,
    subFoot,
    search,
    reset,
    footer,
    initialValues,
    layout = "inline",
    size = [8, 16],
    gap = "12px 10px",
    defaultTimer = [
      dayjs(getFirstDay(new Date())),
      dayjs(getTimeFormat(new Date()), "YYYY-MM-DD"),
    ],
    form: formProps,
  } = props;

  const [form] = Form.useForm();

  const finalForm = formProps || form;

  useEffect(() => {
    if (initialValues) {
      finalForm.setFieldsValue(initialValues);
      console.log(initialValues, "initialValuesinitialValues");
    }
  }, [finalForm, initialValues]);

  const onFinish = (values: any) => {
    // finalForm.setFieldsValue(initialValues);
    const formData = {
      ...initialValues,
      ...values,
    };
    if (values?.datePicker) {
      values.datePicker = dayjs(values.datePicker);
    }
    if (values?.rangePicker && values?.rangePicker[0]) {
      const startTime = dayjs(values.rangePicker[0]).format(
        "YYYY-MM-DD 00:00:00"
      );
      const endTime = dayjs(values.rangePicker[1]).format(
        "YYYY-MM-DD 23:59:59"
      );
      delete formData.rangePicker;
      formData.startTime = startTime;
      formData.endTime = endTime;
    }
    console.log(values, "valuesvalues");
    search && search(formData);
  };

  const setType = (item: FormTabItem) => {
    switch (item.type) {
      case "text":
        return <span {...item.params}>{item.params?.text || ""}</span>;
      case "input":
        return (
          <Input placeholder={"请输入" + item.label} {...item.params}></Input>
        );
      case "select":
        return (
          <Select
            placeholder="请选择"
            options={item.options?.length ? item.options : undefined}
            allowClear
            {...item.params}
          ></Select>
        );
      case "radio":
        return (
          <Radio.Group options={item.options} {...item.params}></Radio.Group>
        );
      case "button":
        return <Button  {...item.params}>{item.name}</Button>;
      case "datePicker":
        return (
          <DatePicker
            locale={locale}
            format="YYYY-MM-DD"
            {...item.params}
          ></DatePicker>
        );
      case "rangePicker":
        return (
          <RangePicker
            defaultValue={defaultTimer}
            locale={locale}
            format="YYYY-MM-DD"
            {...item.params}
            allowClear={false}
          ></RangePicker>
        );
      case "textArea":
        return <TextArea placeholder="请输入" {...item.params}></TextArea>;
      case "treeSelect":
        return <TreeSelect {...item.params}></TreeSelect>;
      case "cascader":
        return <Cascader placeholder="请选择" {...item.params}></Cascader>;
      case "quill":
        return <ReactQuill modules={quillModules}  placeholder="请输入" style={{width: item?.params?.width || '800px', minHeight: '300px'}} {...item.params}></ReactQuill>;
      case "tree":
        return (
          <div
            style={{
              border: "1px solid #efefef",
              borderRadius: "4px",
              padding: "6px",
            }}
          >
            <Tree {...item.params}></Tree>
          </div>
        );
      case "upload":
        return (
          <Upload listType="picture-card" accept="image/*" {...item.params}>
            {initialValues?.[item.name as any]?.length <
            (item?.params?.maxCount as number)
              ? item.params?.uploadMessage
              : null}
          </Upload>
        );
      default:
        break;
    }
  };

  return (
    <>
      <Form
        form={finalForm}
        onFinish={onFinish}
        onReset={reset}
        layout={layout}
        // wrapperCol={{ span: 0, offset: 0 }}
        style={{ gap: gap }}
      >
        {formTab
          ?.filter((i) => !i?.notShow)
          ?.map((item, index) => (
            <Form.Item
            
        labelCol={{ span: 8, offset: 0 }}
              style={{ width: item.width || "260px" }}
              className={styles.formItem}
              {...item}
              key={index}
            >
              {setType(item)}
            </Form.Item>
          ))}
        {!subFoot && (
          <div>
            <Space size={size}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  搜索
                </Button>
              </Form.Item>
              <Form.Item>
                <Button htmlType="reset" type="default">
                  重置
                </Button>
              </Form.Item>
            </Space>
          </div>
        )}
        {subFoot && (
          <div className={styles.modalForm}>
            <Space>
              <Form.Item>
                <Button htmlType="reset" type="default">
                  取消
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  确认
                </Button>
              </Form.Item>
            </Space>
          </div>
        )}
      </Form>
      <Space align="start" className={styles.pageFooter}>
        {footer?.map((item, index) => {
          return item.render ? (
            item.render
          ) : (
            <Button {...item} key={index}>
              {item.title}
            </Button>
          );
        })}
      </Space>
    </>
  );
};
export default IFormTab;
