import { Button, message, Modal, Space, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import PageTab from "@/components/i-pageTab/i-pageTab";
import PageTable from "@/components/i-pageTable/i-pageTable";
import IForm from "@/components/i-form/i-form";

import { useSelector, useDispatch, useStore } from "react-redux";
import { dictInfoAsync } from "@/stores/dictInfo";
import {
  downloadFile,
  getFirstDay,
  getTimeFormat,
  setIcons,
  showModal,
} from "@/utils/common";
import {
  merOrderList,
  merOrderCmsUse,
  cmsConfirmRefund,
  getOrderReward,
  merOrderExport,
} from "@/api/merchant";
import { AnyAction } from "@reduxjs/toolkit";
import { isUse_enum } from "@/utils/enums";

const MerchantOrderManager = () => {
  const dispatch = useDispatch();
  const {
    state: { business_type, rechange_status_type, is_use },
  } = useSelector((state: { dictInfo: any }) => state.dictInfo);

  const [pageData, setPageData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [expandedData, setExpandedData] = useState<any[]>([]);
  const [orderId, setOrderId] = useState("");

  const [formData, setFormData] = useState<SelectCmsMerOrderPageParams>({
    businessType: "",
    startDate: getTimeFormat(getFirstDay(new Date()), "yyyy-MM-dd HH:mm:ss"),
    endDate: getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss"),
    dateType: 0,
    goodsName: "",
    isComment: "",
    isUse: "",
    merMobile: "",
    merOrderId: "",
    mobile: "",
    rechangeStatus: "",
    storeMobile: "",
    storeName: "",
    ypageNum: 1,
    ypageSize: 10,
  });

  const modalFormTab = [
    {
      label: "退款金额",
      name: "refundAmount",
      type: "input",
      rules: [{ required: true, message: "请填写退款金额" }],
      params: {
        type: "number",
      },
    },
    {
      label: "退款原因",
      name: "refundReason",
      type: "textArea",
      rules: [{ required: true, message: "请填写退款原因" }],
      params: {
        placeholder: "请输入退款原因！",
        rows: 5,
        maxLength: 100,
        showCount: true,
      },
    },
  ];

  const footer = [
    {
      title: "导出",
      icon: setIcons("DownloadOutlined"),
      onClick: () => exportExcel(),
      loading: exportLoading,
    },
  ];

  const formTab = [
    {
      label: "订单号",
      name: "merOrderId",
      type: "input",
      width: "440px",
    },
    {
      label: "门店名称",
      name: "storeName",
      type: "input",
    },
    {
      label: "门店手机号",
      name: "storeMobile",
      type: "input",
    },
    {
      label: "商户手机号",
      name: "merMobile",
      type: "input",
    },
    {
      label: "用户手机号",
      name: "mobile",
      type: "input",
    },

    {
      label: "业务类型",
      name: "businessType",
      type: "select",
      options: business_type,
    },
    {
      label: "使用状态",
      name: "isUse",
      type: "select",
      options: is_use,
    },
    {
      label: "交易状态",
      name: "rechangeStatus",
      type: "select",
      options: rechange_status_type,
    },
    {
      label: "时间方式",
      name: "dateType",
      type: "select",
      params: {
        defaultValue: 0,
        allowClear: false,
      },
      options: [
        {
          label: "创建时间",
          value: 0,
        },
        {
          label: "核销时间",
          value: 1,
        },
      ],
    },

    {
      label: "时间范围",
      name: "rangePicker",
      type: "rangePicker",
      width: "380px",
    },
  ];

  const columns = [
    {
      title: "订单号",
      dataIndex: "merOrderId",
      key: "merOrderId",
      width: 180,
    },

    {
      title: "门店名称",
      dataIndex: "storeName",
      key: "storeName",
    },
    {
      title: "门店手机号",
      dataIndex: "storeMobile",
      key: "storeMobile",
    },
    {
      title: "用户名称",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "用户手机号",
      dataIndex: "userMobile",
      key: "userMobile",
    },
    {
      title: "业务类型",
      dataIndex: "businessTypeLabel",
      key: "businessTypeLabel",
      render: (val: string) => <Tag color="blue">{val}</Tag>,
    },
    {
      title: "商品名称",
      dataIndex: "serviceName",
      width: 180,
      key: "serviceName",
    },
    {
      title: "商品数量",
      dataIndex: "goodsNum",
      key: "goodsNum",
    },
    {
      title: "门店挂牌价",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "实际支付金额",
      dataIndex: "realAmount",
      key: "realAmount",
    },
    {
      title: "合作结算价",
      dataIndex: "finalPrice",
      key: "finalPrice",
    },
    {
      title: "券码",
      dataIndex: "qrNo",
      key: "qrNo",
    },
    {
      title: "交易状态",
      dataIndex: "rechangeStatusLabel",
      key: "rechangeStatusLabel",
      render: (val: string) => val && <Tag color="blue">{val}</Tag>,
    },
    {
      title: "核销方式",
      dataIndex: "useMethod",
      key: "useMethod",
      render: (text: string) => {
        return text && (text === "0" ? "扫码核销" : "后台核销");
      },
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "支付时间",
      dataIndex: "payTime",
      key: "payTime",
    },
    {
      title: "核销时间",
      dataIndex: "useTime",
      key: "useTime",
    },
    {
      title: "使用状态",
      dataIndex: "isUse",
      key: "isUse",
      render: (val: string) => val && <Tag color="blue">{isUse_enum[val]}</Tag>,
    },
    {
      title: "操作",
      dataIndex: "merOrderId",
      key: "merOrderId",
      fixed: "right",
      render: (val: string, rows: any) => (
        <Space>
          <Button
            size="small"
            icon={setIcons("SnippetsOutlined")}
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              outDetail(rows);
            }}
          >
            详情
          </Button>
          {rows.isUse === "1" ? (
            <Button
              size="small"
              icon={setIcons("TransactionOutlined")}
              type="text"
              onClick={(e) => {
                e.stopPropagation();
                handleHx(val);
              }}
            >
              核销
            </Button>
          ) : (
            <></>
          )}
          {(rows.rechangeStatus === "10006002" && rows.isUse === "1") ||
          rows.rechangeStatus === "10006006" || (rows.isOnlinePay === 0 &&  rows.rechangeStatus === "10006002" ) ? (
            <Button
              size="small"
              icon={setIcons("RotateLeftOutlined")}
              type="text"
              onClick={(e) => {
                e.stopPropagation();
                refund(val);
              }}
            >
              退款
            </Button>
          ) : (
            <></>
          )}
          {rows.isUse === "0" && rows.businessType === "10003007" ? (
            <Button
              size="small"
              icon={setIcons("DollarOutlined")}
              type="text"
              onClick={(e) => {
                setOrderDollar(val);
              }}
            >
              分佣
            </Button>
          ) : (
            <></>
          )}
        </Space>
      ),
    },
  ];

  const expandedColumns = [
    {
      title: "订单号",
      dataIndex: "merOrderId",
      key: "merOrderId",
    },
    {
      title: "业务类型",
      dataIndex: "businessTypeLabel",
      key: "businessTypeLabel",
      render: (val: string) => <Tag color="blue">{val}</Tag>,
    },
    {
      title: "	商品名称",
      dataIndex: "serviceName",
      key: "serviceName",
    },

    {
      title: "	商品数量",
      dataIndex: "goodsNum",
      key: "goodsNum",
    },
    {
      title: "门店挂牌价",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "用户实际付金额",
      dataIndex: "realAmount",
      key: "realAmount",
    },
    {
      title: "合作结算价",
      dataIndex: "finalPrice",
      key: "finalPrice",
    },
    {
      title: "交易状态",
      dataIndex: "rechangeStatusLabel",
      key: "rechangeStatusLabel",
      render: (val: string) => <Tag color="blue">{val}</Tag>,
    },
  ];

  const onExpand = (expanded: any, record: any) => {
    setExpandedData(record.cmsChildOrderPageVos);
    expandedRowKeys[0] === record.merOrderId
      ? setExpandedRowKeys([])
      : setExpandedRowKeys([record.merOrderId]);
  };
  useEffect(() => {
    !business_type &&
      dispatch(dictInfoAsync("business_type") as unknown as AnyAction);
    !rechange_status_type &&
      dispatch(dictInfoAsync("rechange_status_type") as unknown as AnyAction);
    !is_use && dispatch(dictInfoAsync("is_use") as unknown as AnyAction);
  }, []);

  useEffect(() => {
    getPageData();
  }, [formData]);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await merOrderList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const exportExcel = async () => {
    setExportLoading(true);
    try {
      const data = await merOrderExport(formData);
      downloadFile(
        data as any,
        "门店订单管理导出" + getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss")
      );
    } catch (error) {
    } finally {
      setExportLoading(false);
    }
  };

  const handleHx = async (merOrderId: string) => {
    const data = await showModal({
      title: "确认",
      content: <div>完成核销后，商家及直推人将收到钱款，是否确认?</div>,
    });
    if (data === "ok") {
      try {
        await merOrderCmsUse(merOrderId);
        message.success("核销成功!");
        getPageData();
      } catch (error) {}
    }
  };

  const confirmData = async ({
    refundAmount,
    refundReason,
  }: {
    refundAmount: string;
    refundReason: string;
  }) => {
    try {
      await cmsConfirmRefund({
        orderId,
        refundAmount: Number(refundAmount),
        refundReason,
      });
      getPageData();
      message.success("退款成功！");
      setOpen(false);
    } catch (error) {}
  };
  const refund = (id: string) => {
    setOpen(true);
    setOrderId(id);
  };
  const outDetail = (val: any) => {
    Modal.info({
      width: "36%",
      okText: "好的",
      icon: null,
      content: (
        <IForm
          formTab={[
            {
              label: "订单号",
              name: val.merOrderId,
            },
            {
              label: "门店名称",
              name: val.storeName,
            },
            {
              label: "商户手机号",
              name: val.merMobile,
            },
            {
              label: "门店手机号",
              name: val.storeMobile,
            },
            {
              label: "退款订单ID",
              name: val.refundOrderId,
            },
            {
              label: "退款金额",
              name: val.refundAmount,
            },
            {
              label: "退款原因",
              name: val.refundReason,
            },
            {
              label: "退款时间",
              name: val.refundTime,
            },
            {
              label: "门店地址",
              name: val.storeAddress,
            },
          ]}
        ></IForm>
      ),
    });
  };

  const search = (val: any) => {
    setFormData({
      ...formData,
      ...val,
      ypageNum: 1,
      dateType: val.dateType || 0,
    });
  };

  const reset = () => {
    setFormData({
      startDate: getTimeFormat(getFirstDay(new Date()), "yyyy-MM-dd HH:mm:ss"),
      endDate: getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss"),
      mobile: "",
      merMobile: "",
      ypageNum: 1,
      ypageSize: 10,
      dateType: 0,
    });
  };

  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };

  const setOrderDollar = async (val: string) => {
    try {
      const { data } = await getOrderReward(val);
      Modal.info({
        title: "分佣详情",
        width: "48%",
        okText: "好的",
        icon: null,
        content: (
          <PageTable
            columns={[
              {
                title: "收入类型",
                dataIndex: "rewardType",
                key: "rewardType",
              },
              {
                title: "收入金额",
                dataIndex: "amount",
                key: "amount",
              },
              {
                title: "分佣名称",
                dataIndex: "rewardName",
                key: "rewardName",
              },
              {
                title: "分佣角色",
                dataIndex: "rewardRole",
                key: "rewardRole",
              },
              {
                title: "分佣电话",
                dataIndex: "rewardMobile",
                key: "rewardMobile",
              },
              {
                title: "分佣时间",
                dataIndex: "rewardTime",
                key: "rewardTime",
              },
            ]}
            data={data}
          ></PageTable>
        ),
      });
    } catch (error) {}
  };

  return (
    <div>
      <Modal
        destroyOnClose
        open={open}
        title="退款"
        width="20%"
        footer={<></>}
        onCancel={(_) => setOpen(false)}
      >
        <PageTab
          subFoot
          search={confirmData}
          reset={(_) => setOpen(false)}
          formTab={modalFormTab}
        ></PageTab>
      </Modal>
      <PageTab
        search={search}
        reset={reset}
        formTab={formTab}
        footer={footer}
      ></PageTab>
      <PageTable
        expandable={{
          expandedRowRender: (record: any) =>
            record?.cmsChildOrderPageVos && (
              <Table
                columns={expandedColumns}
                dataSource={expandedData}
                pagination={false}
                rowKey="merOrderId"
              />
            ),
          onExpand,
          expandedRowKeys,
        }}
        loading={loading}
        pageChange={pageChange}
        size="small"
        rowKey="merOrderId"
        columns={columns}
        data={pageData}
        pagination={{
          current: formData.ypageNum!,
          pageSize: formData.ypageSize!,
          total: total,
        }}
      ></PageTable>
    </div>
  );
};

export default MerchantOrderManager;
