import React, { useEffect, useRef, useState } from "react";
import PageTab from "@/components/i-pageTab/i-pageTab";
import PageTable from "@/components/i-pageTable/i-pageTable";
import styles from "@/static/css/index.module.css";
import {
  getOperationMerOilIncomePageList,
  operationMerOilIncomeExport,
} from "@/api/operation";

import { downloadFile, getTimeFormat, setIcons } from "@/utils/common";
import { useSelector, useDispatch, useStore } from "react-redux";
import { dictInfoAsync } from "@/stores/dictInfo";
import dayjs from "dayjs";
import { Tag } from "antd";

const Oil_detail = () => {
  const dispatch = useDispatch();
  const {
    state: { account_trend_type },
  } = useSelector((state: { dictInfo: any }) => state.dictInfo);

  const [pageData, setPageData] = useState<
    GetOperationMerOilIncomePageListRes["rows"]
  >([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [formData, setFormData] =
    useState<GetOperationMerOilIncomePageListParams>({
      ypageNum: 1,
      ypageSize: 10,
    });

  const formTab = [
    {
      label: "订单号",
      name: "merOrderId",
      type: "input",
    },
    {
      label: "用户名称",
      name: "nickName",
      type: "input",
    },
    {
      label: "用户手机号",
      name: "mobile",
      type: "input",
    },
    {
      label: "资金走向类型",
      name: "accountTrend",
      type: "select",
      options: account_trend_type,
    },
    {
      label: "时间范围",
      name: "rangePicker",
      type: "rangePicker",
      width: "380px",
    },
  ];

  const footer = [
    {
      title: "导出",
      icon: setIcons("DownloadOutlined"),
      onClick: () => exportExcel(),
      loading: exportLoading,
    },
  ];

  const columns = [
    {
      title: "订单号",
      dataIndex: "merOrderId",
      key: "merOrderId",
      width: "220px",
    },
    {
      title: "用户名称",
      dataIndex: "nickName",
      key: "nickName",
    },
    {
      title: "用户手机号",
      dataIndex: "userMobile",
      key: "userMobile",
    },
    {
      title: "商品名称",
      dataIndex: "serviceName",
      key: "serviceName",
    },
    {
      title: "门店名称",
      dataIndex: "storeName",
      key: "storeName",
    },
    {
      title: "运营商名称",
      dataIndex: "operatorName",
      key: "operatorName",
    },
    {
      title: "资金走向类型",
      dataIndex: "accountTrendLabel",
      key: "accountTrendLabel",
      render: (val: string) => <Tag color="blue">{val}</Tag>,
    },
    {
      title: "收益金额",
      dataIndex: "incomeAmount",
      key: "incomeAmount",
    },
    {
      title: "实际付金额",
      dataIndex: "realAmount",
      key: "realAmount",
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);
  useEffect(() => {
    !account_trend_type && dispatch(dictInfoAsync("account_trend_type") as any);
  }, []);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await getOperationMerOilIncomePageList(formData);
      const page = data.rows?.map((i, v) => {
        return {
          ...i,
          key: v,
        };
      });
      setPageData(page);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const exportExcel = async () => {
    setExportLoading(true);
    try {
      const data = await operationMerOilIncomeExport(formData);
      downloadFile(
        data as any,
        "运营商机油收入明细" + getTimeFormat(new Date(), "yyyy-MM-dd")
      );
    } catch (error) {
    } finally {
      setExportLoading(false);
    }
  };

  const search = (val: any) => {
    setFormData({
      ...formData,
      ...val,
      ypageNum: 1,
    });
  };

  const reset = () => {
    setFormData({
      ypageNum: 1,
      ypageSize: 10,
    });
  };

  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };
  return (
    <div>
      <PageTab
        search={search}
        reset={reset}
        formTab={formTab}
        footer={footer}
        defaultTimer={[]}
      ></PageTab>
      <PageTable
        loading={loading}
        pageChange={pageChange}
        size="small"
        rowKey="key"
        columns={columns}
        data={pageData}
        pagination={{
          current: formData.ypageNum!,
          pageSize: formData.ypageSize!,
          total: total,
        }}
      ></PageTable>
    </div>
  );
};

export default Oil_detail;
