import React, { useEffect, useRef, useState } from "react";
import PageTab from "@/components/i-pageTab/i-pageTab";
import PageTable from "@/components/i-pageTable/i-pageTable";
import { apiListMerOrderComment, checkComment } from "@/api/operation";
import { Button, Space, Switch,message, Tag, Image } from "antd";
import { resetForm, setIcons, showModal } from "@/utils/common";
import { useSelector, useDispatch, useStore } from "react-redux";
import { dictInfoAsync } from "@/stores/dictInfo";

const status_type = [
  { label: "待审核", value: "0" },
  { label: "审核通过", value: "1" },
  { label: "驳回", value: "2" },
];

const RateProcess = () => {
  const dispatch = useDispatch();
  const {
    state: { vip_level, account_changes_business_type },
  } = useSelector((state: { dictInfo: any }) => state.dictInfo);
  const [pageData, setPageData] = useState<ApiListMerOrderCommentRes["rows"]>(
    []
  );

  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState<ApiListMerOrderCommentParams>({
    ypageNum: 1,
    ypageSize: 10,
  });

  const formTab = [
    {
      label: "订单号",
      name: "orderId",
      type: "input",
      width: "280px",
    },
    {
      label: "状态",
      name: "status",
      type: "select",
      options: status_type,
    },
  ];

  const columns = [
    {
      title: "订单号",
      dataIndex: "orderId",
      key: "orderId",
      width: "240px",
    },
    {
      title: "商品名称",
      dataIndex: "goodName",
      key: "goodName",
      width: "240px",
    },
    {
      title: "用户昵称",
      dataIndex: "nickName",
      key: "nickName",
    },
    {
      title: "星级",
      dataIndex: "star",
      key: "star",
    },
    {
      title: "评价",
      dataIndex: "comment",
      key: "comment",
      width: "240px",
    },
    {
      title: "评论图片",
      dataIndex: "picList",
      key: "picList",
      render: (val: Array<string>) => (
        <Space color="blue">
          {val?.map((i: string, v: number) => (
            <Image height={120} src={i} key={v}></Image>
          ))}
        </Space>
      ),
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (val: string) => (
        <Tag color="blue">
          {status_type.find((i) => i.value === val)?.label}
        </Tag>
      ),
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      render: (val: string, rows: any) => (
        <Space>
          {rows.status === "0" && (
            <>
              <Button
                size="small"
                icon={setIcons("CheckCircleOutlined")}
                type="text"
                onClick={() => statusModal(val)}
              >
                审核
              </Button>
              <Button
                size="small"
                icon={setIcons("StopOutlined")}
                type="text"
                onClick={() => offUser(val)}
              >
                驳回
              </Button>
            </>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  useEffect(() => {
    !vip_level && dispatch(dictInfoAsync("vip_level") as any);
    !account_changes_business_type &&
      dispatch(dictInfoAsync("account_changes_business_type") as any);
  }, []);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await apiListMerOrderComment(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const search = (val: any) => {
    setFormData({
      ...formData,
      ...val,
      ypageNum: 1,
    });
  };

  const reset = () => {
    setFormData({
      ...resetForm(formData),
    });
  };

  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };

  const statusModal = async (id: string) => {
    const data = await showModal({
      title: "系统提示",
      content: <div>是否通过当前评论？</div>,
    });
    if (data === "ok") {
      try {
        await checkComment({
          id,
          status: "1",
        });
        message.success("评论已通过");
        getPageData();
      } catch (error) {}
    }
  };

  const offUser = async (id: string) => {
    const data = await showModal({
      title: "系统提示",
      content: <div>是否驳回当前评论？</div>,
    });
    if (data === "ok") {
      try {
        await checkComment({
          id,
          status: "2",
        });
        message.success("驳回成功");
        getPageData();
      } catch (error) {}
    }
  };

  return (
    <div>
      <PageTab
        search={search}
        reset={reset}
        formTab={formTab}
        // footer={footer}
      ></PageTab>
      <PageTable
        loading={loading}
        pageChange={pageChange}
        size="small"
        rowKey="id"
        columns={columns}
        data={pageData}
        pagination={{
          current: formData.ypageNum!,
          pageSize: formData.ypageSize!,
          total: total,
        }}
      ></PageTable>
    </div>
  );
};

export default RateProcess;
