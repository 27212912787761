import React, { useEffect, useState } from "react";
import PageTable from "@/components/i-pageTable/i-pageTable";
import PageTab from "@/components/i-pageTab/i-pageTab";
import { cmsUserGoodsOrderList, cmsUserGoodsOrderExport } from "@/api/order";
import {Tag } from "antd";
import { downloadFile, getFirstDay, getTimeFormat, resetForm, setIcons } from "@/utils/common";
import { useSelector, useDispatch, useStore } from "react-redux";
import { dictInfoAsync } from "@/stores/dictInfo";

const OilOrder = () => {
  const dispatch = useDispatch();
  const {
    state: { other_platform_type ,goods_order_status},
  } = useSelector((state: { dictInfo: any }) => state.dictInfo);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const [pageData, setPageData] = useState<CmsUserGoodsOrderListRes["rows"]>([]);

  const [formData, setFormData] = useState<CmsIncomeOrderListParams>({
    startTime: getTimeFormat(getFirstDay(new Date()), "yyyy-MM-dd HH:mm:ss"),
    endTime: getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss"),
    ypageNum: 1,
    ypageSize: 10,
  });
  const formTab = [
    {
      label: "订单号",
      name: "orderId",
      type: "input",
    },
    {
      label: "手机号",
      name: "mobile",
      type: "input",
    },
    {
      label: "用户id",
      name: "userId",
      type: "input",
    },
    // {
    //   label: "渠道类型",
    //   name: "otherPlatformType",
    //   type: "select",
    //   options: other_platform_type,
    // },
    {
      label: "支付状态",
      name: "orderStatus",
      type: "select",
      options: goods_order_status,
    },
    {
      label: "创建时间",
      name: "rangePicker",
      type: "rangePicker",
      width: "350px",
      params: {},
    },
  ];
  const columns = [
    {
      title: "订单号",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "用户id",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "手机号",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "油号",
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: "加油量/升",
      dataIndex: "quantity",
      key: "quantity",
    },

    {
      title: "订单金额",
      dataIndex: "orderAmount",
      key: "orderAmount",
    },
    {
      title: "优惠金额",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "实付金额",
      dataIndex: "realityAmount",
      key: "realityAmount",
    },
    {
      title: "渠道类型",
      dataIndex: "fromTypeLabel",
      key: "fromTypeLabel",
      render: (val: string) => (
        <Tag color="processing">{val}</Tag>
      )
    },
    {
      title: "支付状态",
      dataIndex: "orderStatus",
      key: "orderStatus",
      render: (val: string) => (
        <Tag color="processing">{goods_order_status.find((i: {value: string})=> i.value === val)?.label}</Tag>
      )
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
  ];

  // const footer = [
  //   {
  //     title: "导出",
  //     icon: setIcons("DownloadOutlined"),
  //     onClick: () => exportExcel(),
  //     loading: exportLoading,
  //   },
  // ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  useEffect(() => {
    !other_platform_type && dispatch(dictInfoAsync("other_platform_type") as any);
    !goods_order_status && dispatch(dictInfoAsync("goods_order_status") as any);
    
    
  }, []);

  const search = (val: any) => {
    const params = {
      ...val,
    }
    if (val.startDate) {
      params.startTime = val.startDate
      params.endTime = val.endDate
    }
    setFormData({
      ...formData,
      ...params,
      ypageNum: 1,
    });
  };

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await cmsUserGoodsOrderList(formData);
      setPageData(data.rows.map((i: any, index: number)=> {
        return {
          ...i,
          id: index
        }
      }));
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };

  const exportExcel = async () => {
    setExportLoading(true);
    try {
      const data = await cmsUserGoodsOrderExport(formData);
      downloadFile(
        data as any,
        "用户加油订单导出" +
          getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss")
      );
    } catch (error) {
    } finally {
      setExportLoading(false);
    }
  };

  const reset = () => {
    setFormData({
      startTime: getTimeFormat(getFirstDay(new Date()), "yyyy-MM-dd HH:mm:ss"),
      endTime: getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss"),
      ypageNum: 1,
      ypageSize: 10,
    });
  };

  return (
    <div>
      <PageTab
        search={search}
        reset={reset}
        formTab={formTab}
        footer={[]}
      ></PageTab>
      <PageTable
        loading={loading}
        rowKey="id"
        pageChange={pageChange}
        data={pageData}
        columns={columns}
        pagination={{
          total: total,
          current: formData.ypageNum,
        }}
      ></PageTable>
    </div>
  );
};

export default OilOrder;
