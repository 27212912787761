import http from "@/utils/request";



// CMS使用--用户佣金订单
export const listReadPacket = (params: ListReadPacketParams): Promise<ListReadPacketRes> => {
  return http.post("/cms/auth/user/order/listReadPacket", params);
};
// CMS使用——商家收益订单列表
export const getincomeOrderList = (params: CmsIncomeOrderListParams): Promise<CmsIncomeOrderListRes> => {
  return http.post("/cms/auth/mer/order/incomeOrderList", params);
};
// CMS用户加油订单
export const cmsUserGoodsOrderList = (params: CmsUserGoodsOrderListParams): Promise<CmsUserGoodsOrderListRes> => {
  return http.post("/cms/auth/user/order/cmsUserGoodsOrderList", params);
};

// CMS用户加油订单导出
export const cmsUserGoodsOrderExport = (params: CmsUserGoodsOrderListParams): Promise<defaultRes> => {
  return http.post("/cms/auth/user/order/cmsUserGoodsOrderExport", params, {responseType: "blob"});
};

