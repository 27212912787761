import http from "@/utils/request";



// 用户维度-门店与推荐会员订单统计分页查询
export const merAndVipStatisticsList = (params: SelectCmsAppUserMerAndVipStatisticsPageParams): Promise<SelectCmsAppUserMerAndVipStatisticsPageRes> => {
  return http.post("/user/account/changeList", params);
};

// 用户维度-门店与推荐会员订单统计导出
export const merAndVipStatisticsExport = (params: Record<string, any>): Promise<defaultRes> => {
  return http.post("/cms/auth/statistics/merAndVipStatisticsExport", params, {responseType: "blob"})
};

// 门店与推荐会员订单统计分页查询
export const merOrderWithOutOilStatisticsList = (params: SelectCmsMerOrderWithOutOilStatisticsPageParams): Promise<SelectCmsMerOrderWithOutOilStatisticsPageRes> => {
  return http.post("/cms/auth/statistics/merOrderWithOutOilStatisticsList", params);
};

// 门店与推荐会员订单统计导出
export const merOrderWithOutOilStatisticsExport = (params: SelectCmsMerOrderWithOutOilStatisticsPageParams): Promise<defaultRes> => {
  return http.post("/cms/auth/statistics/merOrderWithOutOilStatisticsExport", params, {responseType: "blob"})
};



// 门店除机油订单统计分页查询
export const merOrderStatisticsList = (params: SelectCmsMerOrderStatisticsPageParams): Promise<SelectCmsMerOrderStatisticsPageRes> => {
  return http.post("/transfer/order/list", params);
};

// 门店与推荐会员订单统计导出
export const merOrderStatisticsExport = (params: SelectCmsMerOrderStatisticsPageParams): Promise<defaultRes> => {
  return http.post("/cms/auth/statistics/merOrderStatisticsExport", params, {responseType: "blob"})
};

// 用户维度-门店与推荐会员订单统计导出
// export const merAndVipStatisticsExport = (params: SelectCmsAppUserMerAndVipStatisticsPageParams): Promise<defaultRes> => {
//   return http.post("/cms/auth/statistics/merAndVipStatisticsExport", params, {responseType: "blob"})
// };