import React, { useEffect, useRef, useState } from "react";
import PageTab from "@/components/i-pageTab/i-pageTab";
import PageTable from "@/components/i-pageTable/i-pageTable";
import styles from "@/static/css/index.module.css";

import {
  getOperationFinanceMonthPageList,
  operationFinanceMonthExport,
  getOperationFinanceCashOrderPageList,
  getOperationFinanceData,
} from "@/api/operation";
import { Modal, Tag } from "antd";
import {
  downloadFile,
  getFirstDay,
  getTimeFormat,
  setIcons,
  showModal,
} from "@/utils/common";
import { useSelector, useDispatch, useStore } from "react-redux";
import { dictInfoAsync } from "@/stores/dictInfo";
import dayjs from "dayjs";
import { Image } from "antd/lib";

const FinanceData = () => {
  const dispatch = useDispatch();
  const {
    state: { operation_center_type },
  } = useSelector((state: { dictInfo: any }) => state.dictInfo);

  const [pageData, setPageData] = useState<
    GetOperationFinanceMonthPageListRes["rows"]
  >([]);
  const [opeaData, setOpeaData] = useState({
    income: 0,
    operatorId: "",
    operatorName: "",
    totalIncome: 0,
  });

  const [jsPageData, setJsPageData] = useState<
    GetOperationFinanceCashOrderPageListRes["rows"]
  >([]);
  const [total, setTotal] = useState(0);
  const [jsTotal, setJsTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const [modalFormData, setModalFormData] = useState<pageHomeAddParams>({
    status: "0",
  });

  const [formData, setFormData] =
    useState<GetOperationFinanceMonthPageListParams>({
      month: dayjs(new Date()).format("YYYY-MM"),
      ypageNum: 1,
      ypageSize: 10,
    });
  const [jsFormData, setJsFormData] = useState<{
    ypageNum: number;
    ypageSize: number;
  }>({
    ypageNum: 1,
    ypageSize: 10,
  });

  const jsColumns = [
    {
      title: "结算单号",
      dataIndex: "cashOrderId",
      key: "cashOrderId",
    },
    {
      title: "结算金额",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "打款凭证",
      dataIndex: "voucherImgList",
      key: "voucherImgList",
      render: (val: Array<string>) =>
        val.map((i, k) => <Image src={i} height="100px" key={k}></Image>),
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
  ];

  const formTab = [
    {
      label: "运营商名称",
      name: "operatorName",
      type: "input",
    },
    {
      label: "运营商类型",
      name: "operationCenterType",
      type: "select",
      options: operation_center_type,
    },
    {
      label: "月份",
      name: "month",
      type: "datePicker",
      params: {
        allowClear: false,
        picker: "month",
        format: "YYYY-MM",
        defaultValue: dayjs(dayjs(getFirstDay(new Date())), "YYYY-MM"),
      },
    },
  ];

  const footer = [
    {
      title: "导出",
      icon: setIcons("DownloadOutlined"),
      onClick: () => exportExcel(),
      loading: exportLoading,
    },
  ];

  const columns = [
    {
      title: "运营商名称",
      dataIndex: "operatorName",
      key: "operatorName",
    },
    {
      title: "法人手机号",
      dataIndex: "legalPersonMobile",
      key: "legalPersonMobile",
    },
    {
      title: "运营商类型",
      dataIndex: "operationCenterTypeLabel",
      key: "operationCenterTypeLabel",
      render: (val: string) => <Tag color="blue">{val}</Tag>,
    },
    {
      title: "新增收益",
      dataIndex: "income",
      key: "income",
    },
    {
      title: "支出",
      dataIndex: "expenditure",
      key: "expenditure",
    },
    {
      title: "实际收益",
      dataIndex: "actualAmount",
      key: "actualAmount",
    },
    {
      title: "统计日期",
      dataIndex: "statisticsDate",
      key: "statisticsDate",
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);
  useEffect(() => {
    getJsPageData();
  }, [jsFormData]);
  useEffect(() => {
    getData();
    !operation_center_type &&
      dispatch(dictInfoAsync("operation_center_type") as any);
  }, []);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await getOperationFinanceMonthPageList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getData = async () => {
    try {
      const { data } = await getOperationFinanceData();
      setOpeaData(data);
    } catch (error) {}
  };

  const getJsPageData = async () => {
    try {
      const data = await getOperationFinanceCashOrderPageList(jsFormData);
      const page = data.rows?.map((i, v) => {
        return {
          ...i,
          key: v,
        };
      });
      setJsPageData(page);
      setJsTotal(data.total);
    } catch (error) {}
  };

  const exportExcel = async () => {
    setExportLoading(true);
    try {
      const data = await operationFinanceMonthExport(formData);
      downloadFile(
        data as any,
        "运营商财务按月统计" + getTimeFormat(new Date(), "yyyy-MM")
      );
    } catch (error) {
    } finally {
      setExportLoading(false);
    }
  };

  const search = (val: any) => {
    console.log(val, "vvvvv");
    setFormData({
      ...formData,
      ...val,
      month:
        val.month?.format("YYYY-MM") || dayjs(new Date()).format("YYYY-MM"),
      ypageNum: 1,
    });
  };

  const reset = () => {
    setFormData({
      ypageNum: 1,
      ypageSize: 10,
      month: dayjs(new Date()).format("YYYY-MM"),
    });
  };

  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };

  const jsPageChange = (current: number, pageSize: number) => {
    setJsFormData({
      ypageNum: current,
      ypageSize: pageSize,
    });
  };
  return (
    <div>
      <div
        style={{
          boxShadow: "1px 1px 6px #efefef",
          padding: 10,
          borderRadius: 4,
          marginBottom: 18,
        }}
      >
        <div style={{ marginBottom: 12, fontSize: 16, fontWeight: 600 }}>{opeaData.operatorName}</div>
        <div
          style={{
            fontSize: "14px",
            marginBottom: "12px",
            color: " #f59a23",
            background: "rgba(245,154,35,.2)",
            borderRadius: 4,
            padding: " 5px 20px",
          }}
        >
          每月10号对帐，对帐无误后运营中心开票，平台收到发票后，在15号-20号完成结算，可在结算记录中查看记录。
        </div>
        <div style={{ display: "flex" }}>
          <div className={styles.financeData}>
            <span className={styles.financeDataPrice}>{opeaData.income}</span>
            <span>可提现收益余额（元）</span>
          </div>
          <div className={styles.financeData}>
            <span className={styles.financeDataPrice}>
              {opeaData.totalIncome}
            </span>
            <span>累计收益（元）</span>
          </div>
        </div>
        <div style={{ color: "#999", margin: "18px 0 10px 0" }}>
          今天产生的收益，将在明天 01:00 统计进账
        </div>
      </div>
      <div
        style={{
          boxShadow: "1px 1px 6px #efefef",
          padding: 10,
          borderRadius: 4,
          marginBottom: 18,
        }}
      >
        <div
          style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "12px" }}
        >
          结算记录
        </div>
        <PageTable
          pageChange={jsPageChange}
          size="small"
          rowKey="key"
          columns={jsColumns}
          data={jsPageData}
          pagination={{
            current: jsFormData.ypageNum!,
            pageSize: jsFormData.ypageSize!,
            total: jsTotal,
          }}
        ></PageTable>
      </div>
      <div
        style={{
          boxShadow: "1px 1px 6px #efefef",
          padding: 10,
          borderRadius: 4,
        }}
      >
        <div
          style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "12px" }}
        >
          月收益记录
        </div>
        <PageTab
          search={search}
          reset={reset}
          formTab={formTab}
          footer={footer}
        ></PageTab>
        <PageTable
          loading={loading}
          pageChange={pageChange}
          size="small"
          rowKey="index"
          columns={columns}
          data={pageData}
          pagination={{
            current: formData.ypageNum!,
            pageSize: formData.ypageSize!,
            total: total,
          }}
        ></PageTable>
      </div>
    </div>
  );
};

export default FinanceData;
