import React, { useEffect, useRef, useState } from "react";
import PageTable from "@/components/i-pageTable/i-pageTable";
import PageTab from "@/components/i-pageTab/i-pageTab";
import {
  dictTypeList,
  dictTypeAdd,
  dictTypeEdit,
  dictTypeDetail,
  dictTypeDelete,
  refreshCache
} from "@/api/setting";
import { Button, message, Modal, Space, Tag } from "antd";
import { resetForm, setIcons, showModal } from "@/utils/common";

const DictType = () => {
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectKeys, setSelectKeys] = useState<any[]>([]);
  const [pageData, setPageData] = useState<DictTypeListRes["rows"]>([]);
  const [formData, setFormData] = useState<any>({
    status: "",
    ypageNum: 1,
    ypageSize: 10,
  });
  const [modalFormData, setModalFormData] = useState<any>({
    status: "0",
  });

  const formTab = [
    {
      label: "字典类型",
      name: "dictType",
      type: "input",
    },
    {
      label: "字典名称",
      name: "dictName",
      type: "input",
    },
    // {
    //   label: "备注信息",
    //   name: "remark",
    //   type: "input",
    // },
    {
      label: "状态",
      name: "status",
      type: "select",
      options: [
        { value: "0", label: "正常" },
        { value: "1", label: "停用" },
      ],
    },
    // {
    //   label: "创建时间",
    //   name: "rangePicker",
    //   type: "rangePicker",
    //   width: "330px",
    // },
  ];

  const footer = [
    {
      title: "新增",
      icon: setIcons("PlusOutlined"),
      onClick: () => {
        handleAdd();
      },
    },
    // {
    //   title: "修改",
    //   icon: setIcons("EditOutlined"),
    //   disabled: selectKeys.length !== 1,
    //   onClick: () => {
    //     handleEdit(selectKeys[0]);
    //   },
    // },
    {
      title: "删除",
      icon: setIcons("DeleteOutlined"),
      disabled: !selectKeys.length,
      onClick: () => {
        handleDelete(selectKeys);
      },
    },
        {
      title: "缓存刷新",
      icon: setIcons("CloudSyncOutlined"),
      onClick: () => {
        reverseCache();
      },
    },
  ];

  const modalFormTab = [
    {
      label: "字典名称",
      name: "dictName",
      type: "input",
      width: "300px",
      rules: [{ required: true, message: "请填写字典名称" }],
    },
    {
      label: "字典类型",
      name: "dictType",
      type: "input",
      width: "300px",
      rules: [{ required: true, message: "请填写字典类型" }],
    },
    {
      label: "字典状态",
      name: "status",
      type: "radio",
      labelCol: { span: 0, offset: 1 },
      options: [
        { value: "0", label: "正常" },
        { value: "1", label: "停用" },
      ],
    },
    {
      label: "备注",
      name: "remark",
      type: "textArea",
      labelCol: { span: 0, offset: 3 },
      width: "300px",
      params: {
        placeholder: "请输入备注",
        rows: 3,
        maxLength: 100,
      },
    },
  ];

  const columns = [
    {
      title: "字典Id",
      dataIndex: "dictId",
      key: "dictId",
    },
    {
      title: "字典名称",
      dataIndex: "dictName",
      key: "dictName",
    },
    {
      title: "字典类型",
      dataIndex: "dictType",
      key: "dictType",
      render: (val: string) => (
        <a href={'#/system/dict/' + val} target="_self">{val}</a>
      )
    },
    {
      title: "备注",
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (val: string) => (
        <Tag color="blue">{val === "0" ? "正常" : "停用"}</Tag>
      ),
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "操作",
      dataIndex: "dictId",
      key: "dictId",
      render: (val: string, rows: any) => (
        <Space size="small">
          <Button
            size="small"
            icon={setIcons("EditOutlined")}
            type="text"
            onClick={() => {
              handleEdit(val);
            }}
          >
            修改
          </Button>
          <Button
            size="small"
            icon={setIcons("DisconnectOutlined")}
            type="text"
            onClick={() => {
              handleDelete(val);
            }}
          >
            删除
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  useEffect(() => {}, []);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await dictTypeList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  
  const reverseCache = async () => {
    try {
      await refreshCache();
      message.success("刷新成功");
      getPageData();
    } catch (error) {}
  };

  const search = (val: any) => {
    setFormData({ ...formData, ...val, ypageNum: 1 });
  };
  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };

  const handleAdd = () => {
    setOpen(true);
  };

  const reset = () => {
    setFormData({ ...resetForm(formData) });
    setSelectKeys([]);
  };
  const confirmData = async (val: any) => {
    try {
      if (val.dictId) {
        await dictTypeEdit(val);
        message.success("编辑成功");
      } else {
        await dictTypeAdd(val);
        message.success("新增成功");
      }
      modalReset();
      getPageData();
    } catch (error) {}
  };
  const modalReset = () => {
    setModalFormData({ status: "0" });
    setOpen(false);
  };
  const selectionChange = (keys: any[]) => {
    setSelectKeys(keys);
  };

  const handleEdit = async (val: string) => {
    try {
      const { data } = await dictTypeDetail(val);
      setModalFormData(data);
      setOpen(true);
    } catch (error) {}
  };

  const handleDelete = async (val: string | any[]) => {
    try {
      const data = await showModal({
        title: "提示",
        content: "确定要删除选中的字典类型吗？",
      });
      if (data === "ok") {
        await dictTypeDelete(val);
        message.success("删除成功");
        modalReset();
        getPageData();
      }
    } catch (error) {}
  };

  return (
    <div>
      <Modal
        destroyOnClose
        open={open}
        title={modalFormData.dictId ? "编辑字典类型" : "新增字典类型"}
        width="24%"
        footer={<></>}
        onCancel={modalReset}
      >
        <PageTab
          subFoot
          search={confirmData}
          reset={modalReset}
          formTab={modalFormTab}
          initialValues={modalFormData}
        ></PageTab>
      </Modal>
      <PageTab
        reset={reset}
        search={search}
        formTab={formTab}
        footer={footer}
        defaultTimer={[]}
      ></PageTab>
      <PageTable
        selection
        selectionChange={selectionChange}
        selectedRowKeys={selectKeys}
        loading={loading}
        rowKey="dictId"
        pageChange={pageChange}
        data={pageData}
        columns={columns}
        pagination={{
          total: total,
          current: formData.ypageNum,
        }}
      ></PageTable>
    </div>
  );
};

export default DictType;
