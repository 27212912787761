import { Button, message, Modal, Space, Tag } from "antd";
import React, { useEffect, useRef, useState } from "react";
import PageTab from "@/components/i-pageTab/i-pageTab";
import PageTable from "@/components/i-pageTable/i-pageTable";
import { setIcons, showModal } from "@/utils/common";
import {
  postList,
  postDelete,
  postEdit,
  postAdd,
  postDetail,
} from "@/api/setting";

const PostManager = () => {
  const [pageData, setPageData] = useState<any[]>([]);
  const [changeKeys, setChangeKeys] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [total, setTotal] = useState(0);

  const [modalStr, setModalStr] = useState("新增岗位");

  const [formData, setFormData] = useState({
    phoneNumber: "",
    userName: "",
    ypageNum: 1,
    ypageSize: 10,
  });
  const [modalFormData, setModalFormData] = useState<PostAddParams>({
    postSort: 0,
    status: "0",
  });

  const formTab = [
    {
      label: "岗位编码",
      name: "postCode",
      type: "input",
    },
    {
      label: "岗位名称",
      name: "postName",
      type: "input",
    },
    {
      label: "状态",
      name: "status",
      type: "select",
      options: [
        { value: "0", label: "正常" },
        { value: "1", label: "停用" },
      ],
    },
  ];

  const modalFormTab = [
    {
      label: "岗位名称",
      name: "postName",
      type: "input",
      width: "80%",
      rules: [{ required: true, message: "请输入岗位名称" }],
    },
    {
      label: "岗位编码",
      name: "postCode",
      type: "input",
      width: "80%",

      rules: [{ required: true, message: "请输入岗位编码" }],
    },
    {
      label: "岗位顺序",
      name: "postSort",
      type: "input",
      width: "80%",
      rules: [{ required: true, message: "请输入岗位顺序" }],
      params: {
        type: "number",
      },
    },
    {
      label: "岗位状态",
      name: "status",
      type: "radio",
      width: "80%",
      options: [
        { value: "0", label: "正常" },
        { value: "1", label: "停用" },
      ],
      labelCol: { span: 0, offset: 1 },
    },
  ];

  const footer = [
    {
      title: "新增",
      icon: setIcons("PlusOutlined"),
      onClick: () => {
        setModalStr("新增岗位");
        setOpen(true);
      },
    },
    {
      title: "修改",
      icon: setIcons("EditOutlined"),
      disabled: changeKeys.length !== 1,
      onClick: () => {
        editPost(changeKeys[0]);
      },
    },
    {
      title: "删除",
      icon: setIcons("DeleteOutlined"),
      disabled: !changeKeys.length,
      onClick: () => {
        delPost(changeKeys);
      },
    },
  ];

  const columns = [
    {
      title: "岗位编号",
      dataIndex: "postId",
      key: "postId",
    },
    {
      title: "岗位编码",
      dataIndex: "postCode",
      key: "postCode",
    },
    {
      title: "岗位名称",
      dataIndex: "postName",
      key: "postName",
    },
    {
      title: "岗位排序",
      dataIndex: "postSort",
      key: "postSort",
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (val: string) => (
        <Tag color="blue">{val === "0" ? "启用" : "停用"}</Tag>
      ),
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "操作",
      dataIndex: "postId",
      key: "postId",
      fixed: "right",
      width: "150px",
      render: (val: number) => (
        <Space>
          <Button
            onClick={() => editPost(val)}
            size="small"
            icon={setIcons("EditOutlined")}
            type="text"
          >
            修改
          </Button>
          <Button
            size="small"
            onClick={(_) => delPost(val)}
            icon={setIcons("DeleteOutlined")}
            type="text"
          >
            删除
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  const getPageData = async () => {
    try {
      const data = await postList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {}
  };
  const search = (val: any) => {
    setFormData({ ...formData, ...val, ypageNum: 1 });
  };
  const editPost = async (postId: number) => {
    setModalStr("编辑岗位");
    try {
      const { data } = await postDetail(postId);
      setModalFormData(data);
      setOpen(true);
    } catch (error) {}
  };
  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };
  const delPost = async (val: number | number[]) => {
    try {
      const data = await showModal({
        title: "系统提示",
        content: <div>确认要删除选中的岗位吗？</div>,
      });
      if (data === "ok") {
        await postDelete(val);
        message.success("删除成功");
        getPageData();
      }
    } catch (error) {}
  };

  const resetModal = () => {
    setModalFormData({
      postSort: 0,
      status: "0",
    });
    setOpen(false);
  };
  const selectionChange = (keys: any[]) => {
    setChangeKeys(keys);
  };
  const confirmData = async (val: any) => {
    try {
      if (modalStr === "新增岗位") {
        await postAdd(val);
        message.success("新增岗位成功");
      } else {
        await postEdit(val);
        message.success("编辑岗位成功");
      }
      getPageData();
      resetModal();
    } catch (error) {}
  };
  const reset = () => {
    setFormData({
      phoneNumber: "",
      userName: "",
      ypageNum: 1,
      ypageSize: 10,
    });
  };

  return (
    <div>
      <Modal
        destroyOnClose
        open={open}
        title={modalStr}
        width="26%"
        footer={<></>}
        onCancel={resetModal}
      >
        <PageTab
          subFoot
          search={confirmData}
          reset={resetModal}
          formTab={modalFormTab}
          initialValues={modalFormData}
        ></PageTab>
      </Modal>
      <PageTab
        search={search}
        reset={reset}
        formTab={formTab}
        footer={footer}
      ></PageTab>
      <PageTable
        selection
        pageChange={pageChange}
        selectionChange={selectionChange}
        rowKey="postId"
        columns={columns}
        data={pageData}
        pagination={{
          total: total,
          current: formData.ypageNum,
        }}
      ></PageTable>
    </div>
  );
};

export default PostManager;
