import React, { useEffect, useState } from "react";
import PageTable from "@/components/i-pageTable/i-pageTable";
import PageTab from "@/components/i-pageTab/i-pageTab";
import { payConfigList, payConfigAdd, payConfigEdit } from "@/api/setting";
import { Button, message, Modal, Space, Tag } from "antd";
import { resetForm, setIcons } from "@/utils/common";
import { useSelector, useDispatch, useStore } from "react-redux";
import { dictInfoAsync } from "@/stores/dictInfo";

const PayManager = () => {
  const dispatch = useDispatch();
  const {
    state: { pay_select_status },
  } = useSelector((state: { dictInfo: any }) => state.dictInfo);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [pageData, setPageData] = useState<GetPayCmsPageHomeRes["rows"]>([]);

  const [formData, setFormData] = useState<GetPayCmsPageHomeParams>({
    ypageNum: 1,
    ypageSize: 10,
  });

  const [modalFormData, setModalFormData] = useState<AddPayParams>({
    androidSort: 0,
    androidStatus: 0,
    content: "",
    descriptions: "",
    dictCode: "",
    id: "",
    iosSort: 0,
    iosStatus: 0,
    minSort: 0,
    minStatus: 0,
    otherContent: "",
    remark: "",
    status: "0",
  });

  const footer = [
    {
      title: "新增",
      icon: setIcons("PlusOutlined"),
      onClick: () => {
        setOpen(true);
      },
    },
  ];

  const formTab = [
    {
      label: "支付code",
      name: "dictCode",
      type: "input",
    },
    {
      label: "说明",
      name: "descriptions",
      type: "input",
    },
    {
      label: "安卓状态",
      name: "androidStatus",
      type: "select",
      options: [
        { value: 0, label: "正常" },
        { value: 1, label: "不可用" },
      ],
      labelCol: { span: 0, offset: 1 },
    },
    {
      label: "IOS状态",
      name: "iosStatus",
      type: "select",
      options: [
        { value: 0, label: "正常" },
        { value: 1, label: "不可用" },
      ],
      labelCol: { span: 0, offset: 1 },
    },
    {
      label: "小程序状态",
      name: "minStatus",
      type: "select",
      options: [
        { value: 0, label: "正常" },
        { value: 1, label: "不可用" },
      ],
      labelCol: { span: 0, offset: 1 },
    },
    {
      label: "状态",
      name: "status",
      type: "select",
      options: [
        { label: "正常", value: "0" },
        { label: "停用", value: "1" },
      ],
    },
    {
      label: "支付类型",
      name: "type",
      type: "select",
      options: pay_select_status,
    },
  ];

  const modalFormTab = [
    {
      label: "支付类型",
      name: "type",
      type: "select",
      rules: [{ required: true, message: "请选择支付类型" }],
      options: pay_select_status,
    },
    {
      label: "支付code",
      name: "dictCode",
      type: "input",
      rules: [{ required: true, message: "请选择支付code" }],
    },
    {
      label: "安卓状态",
      name: "androidStatus",
      type: "radio",
      options: [
        { value: 0, label: "正常" },
        { value: 1, label: "不可用" },
      ],
      labelCol: { span: 0, offset: 1 },
    },
    {
      label: "安卓排序",
      name: "androidSort",
      type: "input",
      labelCol: { span: 0, offset: 1 },
      params: {
        type: "number",
      },
    },
    {
      label: "ios状态",
      name: "iosStatus",
      type: "radio",
      options: [
        { value: 0, label: "正常" },
        { value: 1, label: "不可用" },
      ],
      labelCol: { span: 0, offset: 2 },
    },
    {
      label: "ios排序",
      name: "iosSort",
      type: "input",
      labelCol: { span: 0, offset: 2 },
      params: {
        type: "number",
      },
    },
    {
      label: "小程序状态",
      name: "minStatus",
      type: "radio",
      options: [
        { value: 0, label: "正常" },
        { value: 1, label: "不可用" },
      ],
    },
    {
      label: "小程序排序",
      name: "minSort",
      type: "input",
      params: {
        type: "number",
      },
    },
    {
      label: "状态",
      name: "status",
      type: "radio",
      options: [
        { value: "0", label: "正常" },
        { value: "1", label: "停用" },
      ],
      labelCol: { span: 0, offset: 4 },
    },
    {
      label: "说明",
      name: "descriptions",
      type: "textArea",
      labelCol: { span: 0, offset: 4 },
    },
    {
      label: "备注",
      name: "remark",
      type: "textArea",
      labelCol: { span: 0, offset: 4 },
    },
  ];

  const columns = [
    {
      title: "安卓排序序号",
      dataIndex: "androidSort",
      key: "androidSort",
    },
    {
      title: "安卓状态",
      dataIndex: "androidStatus",
      key: "androidStatus",
      render: (val: number) => (
        <Tag color="blue">{val ? "不可用" : "正常"}</Tag>
      ),
    },
    {
      title: "IOS排序序号",
      dataIndex: "iosSort",
      key: "iosSort",
    },
    {
      title: "IOS状态",
      dataIndex: "iosStatus",
      key: "iosStatus",
      render: (val: number) => (
        <Tag color="blue">{val ? "不可用" : "正常"}</Tag>
      ),
    },
    {
      title: "小程序排序序号",
      dataIndex: "minSort",
      key: "minSort",
    },
    {
      title: "小程序状态",
      dataIndex: "minStatus",
      key: "minStatus",
      render: (val: number) => (
        <Tag color="blue">{val ? "不可用" : "正常"}</Tag>
      ),
    },
    {
      title: "支付字典code",
      dataIndex: "dictCode",
      key: "dictCode",
    },
    {
      title: "备注",
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: "说明",
      dataIndex: "descriptions",
      key: "descriptions",
    },

    {
      title: "支付类型",
      dataIndex: "type",
      key: "type",
      render: (val: string) => (
        <Tag color="blue">
          {pay_select_status.find((i: {value: string}) => i.value === val)?.label}
        </Tag>
      ),
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "更新时间",
      dataIndex: "updateTime",
      key: "updateTime",
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (val: string) => (
        <Tag color="blue">{val === "0" ? "正常" : "停用"}</Tag>
      ),
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      width: "150px",
      render: (val: number, rows: any) => (
        <Space>
          <Button
            onClick={() => {
              setModalFormData(rows)
              setOpen(true)
            }}
            size="small"
            icon={setIcons("EditOutlined")}
            type="text"
          >
            修改
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  useEffect(() => {
    !pay_select_status && dispatch(dictInfoAsync("pay_select_status") as any);
  }, []);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await payConfigList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const search = (val: any) => {
    setFormData({ ...formData, ...val, ypageNum: 1 });
  };
  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };

  const reset = () => {
    setFormData({ ypageNum: 1, ypageSize: 10 });
  };
  const resetModal = () => {
    setModalFormData({
      androidSort: 0,
      androidStatus: 0,
      content: "",
      descriptions: "",
      dictCode: "",
      id: "",
      iosSort: 0,
      iosStatus: 0,
      minSort: 0,
      minStatus: 0,
      otherContent: "",
      remark: "",
      status: "0",
    });
    setOpen(false);
  };

  const confirmData = async (val: any) => {
    if (val.id) {
      try {
        await payConfigEdit(val);
        message.success("编辑成功！");
        resetModal();
      } catch (error) {}
    } else {
      try {
        await payConfigAdd(val);
        message.success("新增成功！");
        resetModal();
      } catch (error) {}
    }
    getPageData()
  };

  return (
    <div>
      <Modal
        destroyOnClose
        open={open}
        title={modalFormData.id ? "编辑支付方式" : "新增支付方式"}
        width="34%"
        footer={<></>}
        onCancel={resetModal}
      >
        <PageTab
          subFoot
          search={confirmData}
          reset={resetModal}
          formTab={modalFormTab}
          initialValues={modalFormData}
        ></PageTab>
      </Modal>
      <PageTab
        reset={reset}
        search={search}
        formTab={formTab}
        footer={footer}
        defaultTimer={[]}
      ></PageTab>
      <PageTable
        loading={loading}
        rowKey="id"
        pageChange={pageChange}
        data={pageData}
        columns={columns}
        pagination={{
          total: total,
          current: formData.ypageNum,
        }}
      ></PageTable>
    </div>
  );
};

export default PayManager;
