import React, { useEffect, useRef, useState } from "react";
import PageTable from "@/components/i-pageTable/i-pageTable";
import PageTab from "@/components/i-pageTab/i-pageTab";
import {
  configList,
  configAdd,
  configEdit,
  configDelete,
  configRefreshCache,
} from "@/api/setting";
import { Button, message, Modal, Space, Tag } from "antd";
import { resetForm, setIcons, showModal } from "@/utils/common";

const ConfigManager = () => {
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectKeys, setSelectKeys] = useState<any[]>([]);
  const [pageData, setPageData] = useState<GetCmsConfigPageRes["rows"]>([]);

  const [formData, setFormData] = useState<GetCmsConfigPageParams>({
    configKey: "",
    configName: "",
    configType: "",
    configValue: "",
    createBy: "",
    createTime: "",
    remark: "",
    ypageNum: 1,
    ypageSize: 10,
    updateBy: "",
    updateTime: "",
  });
  const [modalFormData, setModalFormData] = useState<AddConfigParams>({
    configType: 'Y'
  });

  const formTab = [
    {
      label: "参数键名",
      name: "configKey",
      type: "input",
    },
    {
      label: "参数名称",
      name: "configName",
      type: "input",
    },
    // {
    //   label: "参数键值",
    //   name: "configValue",
    //   type: "input",
    // },
    // {
    //   label: "创建者",
    //   name: "createBy",
    //   type: "input",
    // },
    // {
    //   label: "备注",
    //   name: "remark",
    //   type: "input",
    // },
    {
      label: "系统内置",
      name: "configType",
      type: "select",
      options: [
        { value: "Y", label: "是" },
        { value: "N", label: "否" },
      ],
    },
  ];

  const footer = [
    {
      title: "新增",
      icon: setIcons("PlusOutlined"),
      onClick: () => {
        handleAdd();
      },
    },
    {
      title: "删除",
      icon: setIcons("DeleteOutlined"),
      disabled: !selectKeys.length,
      onClick: () => {
        handleDelete(selectKeys);
      },
    },
    {
      title: "缓存刷新",
      icon: setIcons("CloudSyncOutlined"),
      onClick: () => {
        reverseCache();
      },
    },
  ];

  const modalFormTab = [
    {
      label: "参数键名",
      name: "configKey",
      type: "input",
      width: "300px",
      rules: [{ required: true, message: "请填写参数键名" }],
    },
    {
      label: "参数名称",
      name: "configName",
      type: "input",
      width: "300px",
      rules: [{ required: true, message: "请填写参数名称" }],
    },
    {
      label: "参数键值",
      name: "configValue",
      type: "input",
      width: "300px",
      rules: [{ required: true, message: "请填写参数键值" }],
    },
    {
      label: "系统内置",
      name: "configType",
      type: "radio",
      labelCol: { span: 0, offset: 1 },
      options: [
        { value: "Y", label: "是" },
        { value: "N", label: "否" },
      ],
    },
    {
      label: "备注",
      name: "remark",
      type: "textArea",
      labelCol: { span: 0, offset: 3 },
      width: "300px",
      // rules: [{ required: true, message: "请填写备注" }],
      params: {
        placeholder: "请输入备注",
        rows: 3,
      },
    },
  ];

  const columns = [
    {
      title: "参数Id",
      dataIndex: "configId",
      key: "configId",
    },
    {
      title: "参数键名",
      dataIndex: "configKey",
      key: "configKey",
    },
    {
      title: "参数名称",
      dataIndex: "configName",
      key: "configName",
    },
    {
      title: "参数键值",
      dataIndex: "configValue",
      key: "configValue",
      width: '380px'
    },
    {
      title: "创建者",
      dataIndex: "createBy",
      key: "createBy",
    },

    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },

    {
      title: "更新者",
      dataIndex: "updateBy",
      key: "updateBy",
    },

    {
      title: "更新时间",
      dataIndex: "updateTime",
      key: "updateTime",
    },
    {
      title: "备注",
      dataIndex: "remark",
      key: "remark",
      width: '380px'
    },
    {
      title: "系统内置",
      dataIndex: "configType",
      key: "configType",
      render: (val: string) => (
        <Tag color="blue">{val === "Y" ? "是" : "否"}</Tag>
      ),
    },
    {
      title: "操作",
      dataIndex: "configId",
      key: "configId",
      render: (val: string, rows: any) => (
        <Space size="small">
          <Button
            size="small"
            icon={setIcons("EditOutlined")}
            type="text"
            onClick={() => {
              handleEdit(rows);
            }}
          >
            修改
          </Button>
          <Button
            size="small"
            icon={setIcons("DeleteOutlined")}
            type="text"
            onClick={() => {
              handleDelete(val);
            }}
          >
            删除
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);


  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await configList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
      
    } finally {
      setLoading(false);
    }
  };

  const search = (val: any) => {
    setFormData({ ...formData, ...val, ypageNum: 1 });
  };
  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };

  const handleAdd = () => {
    setOpen(true);
  };

  const reset = () => {
    setFormData({ ...resetForm(formData) });
    setSelectKeys([]);
  };
  const confirmData = async (val: any) => {
    try {
      if (val.configId) {
        await configEdit(val);
        message.success("编辑成功");
      } else {
        await configAdd(val);
        message.success("新增成功");
      }
      modalReset();
      getPageData();
    } catch (error) {}
  };
  const modalReset = () => {
    setModalFormData({ configType: "Y" });
    setOpen(false);
  };
  const selectionChange = (keys: any[]) => {
    setSelectKeys(keys);
  };

  const handleEdit = async (val: any) => {
    try {
      setModalFormData(val);
      setOpen(true);
    } catch (error) {}
  };

  const handleDelete = async (val: string | any[]) => {
    try {
      const data = await showModal({
        title: "提示",
        content: "确定要删除选中的配置吗？",
      });
      if (data === "ok") {
        await configDelete(val);
        message.success("删除成功");
        modalReset();
        getPageData();
      }
    } catch (error) {}
  };

  const reverseCache = async () => {
    try {
      await configRefreshCache();
      message.success("刷新成功");
      getPageData();
    } catch (error) {}
  };

  return (
    <div>
      <Modal
        destroyOnClose
        open={open}
        title={modalFormData.configId ? "编辑系统配置" : "新增系统配置"}
        width="24%"
        footer={<></>}
        onCancel={modalReset}
      >
        <PageTab
          subFoot
          search={confirmData}
          reset={modalReset}
          formTab={modalFormTab}
          initialValues={modalFormData}
        ></PageTab>
      </Modal>
      <PageTab
        reset={reset}
        search={search}
        formTab={formTab}
        footer={footer}
      ></PageTab>
      <PageTable
        selection
        selectionChange={selectionChange}
        selectedRowKeys={selectKeys}
        loading={loading}
        rowKey="configId"
        pageChange={pageChange}
        data={pageData}
        columns={columns}
        pagination={{
          total: total,
          current: formData.ypageNum,
        }}
      ></PageTable>
    </div>
  );
};

export default ConfigManager;
