import React, { useState, useEffect, Children } from "react";
import { useNavigate, Link } from "react-router-dom";
import { updatePwd } from "@/api/index";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DownOutlined,
} from "@ant-design/icons";
import PageTab from "@/components/i-pageTab/i-pageTab";

import Routers from "../router/index";
import { MenuProps, message, Modal, Tooltip } from "antd";
import {
  Layout,
  Menu,
  theme,
  Button,
  Breadcrumb,
  Dropdown,
  Space,
  Tag,
} from "antd";
import { setIcons } from "../utils/common";
import styles from "@/static/css/index.module.css";
import { setStorage, getStorage, logout } from "../utils/common";
import logo_text from "../static/images/logo.png";
import logo_app from "../static/images/logo_app.png";
import { getUserRouters } from "@/api/index";
import { useSelector, useDispatch, useStore } from "react-redux";
import { userInfoAsync, getUserInfo } from "@/stores/userInfo";
import { setMenuList } from "@/stores/userTreeReducer";
import {
  setTagList,
  setKeyStr,
  setSubmenuKeys,
  setOpenKeys,
} from "@/stores/syncStore";

import { AnyAction } from "@reduxjs/toolkit";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { passwordReg } from "@/utils/regs";

const { Header, Content, Footer, Sider: Sided } = Layout;
type MenuItem = Required<MenuProps>["items"][number];

const getItem = (
  label: string,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  data?: Record<string, any>,
  type?: "group"
): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
    // : (
    //   <Tooltip placement="rightTop" title={label?.length > 6 ? label : ""}>
    //     <div
    //       style={{
    //         whiteSpace: "nowrap",
    //         textOverflow: "ellipsis",
    //         overflow: "hidden",
    //       }}
    //     >
    //       {label}
    //     </div>
    //   </Tooltip>
    // ),
    type,
    data,
  } as MenuItem;
};

const AppLayout: React.FC = () => {
  const dispatch = useDispatch();
  const {
    payload: {
      userInfo: { value: userInfo },
    },
  } = useSelector(getUserInfo) as any;  
  const openKeys = useSelector(
    (state: { store: { openKeys: any[] } }) => state.store.openKeys
  );
  const tagList = useSelector(
    (state: { store: { tagList: any[] } }) => state.store.tagList
  );
  const keyStr = useSelector(
    (state: { store: { keyStr: string } }) => state.store.keyStr
  );
  const rootSubmenuKeys = useSelector(
    (state: { store: { submenuKeys: any[] } }) => state.store.submenuKeys
  );
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);

  const [items, setItems] = useState<any>(
    [
    getItem("首页", "home", setIcons("AppstoreOutlined"), undefined, {
      path: "home",
      meta: { title: "首页" },
    }),
  ]
  );

  const toHome = () => {
    navigate("home");
  };
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(userInfoAsync() as unknown as AnyAction);
    getMenulist();
  }, []);

  const getMenulist = async () => {
    try {
      const { data } = await getUserRouters();
      const ls = deepTree(data);
      dispatch(
        setSubmenuKeys(
          data.map((item: { meta: { title: string }; path: string }) => {
            return { name: item.meta.title, path: item.path };
          })
        )
      );
      dispatch(setMenuList(data) as unknown as AnyAction);
      setItems([...items,...ls]);
    } catch (error) {}
  };

  const deepTree = (list: any[]): any[] => {
    return list?.map((item) => {
      if (item.children && item.children.length > 1 && !item.hidden) {
        deepTree(item.children);
      }
      return (
        !item.hidden &&
        getItem(
          item?.meta?.title,
          item.path,
          item?.meta?.icon ? setIcons(item?.meta?.icon) : <></>,
          deepTree(item.children),
          item
        )
      );
    });
  };

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.some((i) => latestOpenKey === i.path)) {
      
      dispatch(setOpenKeys(latestOpenKey ? [latestOpenKey] : []));
    } else {
      dispatch(setOpenKeys(keys));
    }
  };

  const onSelect = (e: {
    key: string;
    item: any;
    selectedKeys: any[];
    keyPath: any[];
  }) => {
    const {
      item: {
        props: { data },
      },
    } = e;
    navigate(data.path);
    if (tagList.some((i) => i.link === data.path)) return;
  };

  const tagClick = (item: {
    link: any;
    title?: string;
    breadcrumbData: any;
    selectedKeys: any[];
  }) => {
    navigate(item.link);
    dispatch(setKeyStr(item.link));
    dispatch(setOpenKeys(item.selectedKeys));
  };

  const tagClose = (item: {
    link: any;
    title?: string;
    breadcrumbData?: any[];
  }) => {
    const list = tagList.filter((i) => i.link !== item.link);
    dispatch(setTagList(list));
    if (item.link === keyStr) {
      navigate(list[list.length - 1].link);
      dispatch(setKeyStr(list[list.length - 1].link));
      dispatch(setOpenKeys(list[list.length - 1].selectedKeys));
    }
  };

  const collapsedChange = () => {
    setCollapsed(!collapsed);
    if (collapsed) {
      setTimeout(() => {
        dispatch(
          setOpenKeys(tagList.find((i) => i.link === keyStr)?.selectedKeys)
        );
      }, 200);
    }
  };

  const dropdownItem: MenuItem[] = [
    // {
    //   label: <div onClick={(_) => setOpen(true)}>修改账号密码</div>,
    //   key: "0",
    // },
    {
      type: "divider",
    },
    {
      label: <div onClick={logout}>退出登录</div>,
      key: "1",
    },
  ];

  const formTab = [
    {
      label: "旧密码",
      name: "oldPassword",
      type: "input",
      params: {
        type: "password",
      },
      rules: [{ required: true, message: "旧密码不能为空" }],
    },
    {
      label: "新密码",
      name: "newPassword",
      type: "input",

      params: {
        type: "password",
      },
      rules: [
        { required: true, message: "新密码不能为空" },
        {
          pattern: passwordReg,
          message: "密码长度8-16位由数字和字母组成",
        },
      ],
    },
    {
      label: "确认新密码",
      name: "reNewPassword",
      type: "input",
      params: {
        type: "password",
      },
      rules: [
        { required: true, message: "请再次输入新密码" },
        {
          pattern: passwordReg,
          message: "密码长度8-16位由数字和字母组成",
        },
      ],
    },
  ];
  const search = async (val: any) => {
    if (val.newPassword !== val.reNewPassword) {
      message.error("两次输入密码不一致");
      return;
    }
    try {
      await updatePwd({
        oldPassword: val.oldPassword,
        newPassword: val.newPassword,
      });
      message.success("重置密码成功");
      setOpen(false);
    } catch (error) {}
  };

  return (
    <Layout hasSider>
      <Modal
        destroyOnClose
        width='480px'
        title="重置密码"
        open={open}
        footer={null}
        onCancel={(_) => setOpen(false)}
      >
        <PageTab
          subFoot
          reset={(_) => setOpen(false)}
          search={search}
          formTab={formTab}
        ></PageTab>
      </Modal>
      <Sided
        width={240}
        style={{
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
          overflowY: "auto",
          backgroundColor: colorBgContainer,
        }}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div onClick={toHome} className={styles.logoVertical}>
          
          {collapsed ? <img style={{width: 52, height: 52}} src={logo_app} alt="" />: <img src={logo_text} style={{width: 120}} alt="" />}
        </div>

        <Menu
          theme="light"
          mode="inline"
          openKeys={openKeys}
          selectedKeys={[keyStr]}
          onOpenChange={onOpenChange}
          items={items}
          onSelect={onSelect}
        />
      </Sided>
      <Layout
        className="site-layout"
        style={{
          marginLeft: collapsed ? 80 : 240,
          transition: "all 0.3s ease-out",
        }}
      >
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            height: "54px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className={styles.header_left}>
            <Button
              type="link"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={collapsedChange}
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                width: 64,
                height: 64,
                color: "#000",
              }}
            />
            {/* <SwitchTransition>
              <CSSTransition
                key={keyStr}
                timeout={500}
                classNames="fade_breadcrumb"
              >
                <Space>
                  <Breadcrumb
                    items={
                      tagList?.find((i) => i.link === keyStr)?.breadcrumbData
                    }
                  ></Breadcrumb>
                </Space>
              </CSSTransition>
            </SwitchTransition> */}
            <Space>
              <Breadcrumb
                items={tagList?.find((i) => i.link === keyStr)?.breadcrumbData}
              ></Breadcrumb>
            </Space>
          </div>
          <div className="userName" style={{ marginRight: "20px", cursor: "pointer" }}>
            <Dropdown menu={{ items: dropdownItem }}>
              <div onClick={(e) => {}}>
                <Space>
                  {setIcons("UserOutlined", { color: "#1677FF", fontSize: 18 })}
                  {userInfo?.user?.nickName}
                  <DownOutlined style={{ fontSize: "10px" }} />
                </Space>
              </div>
            </Dropdown>
          </div>
        </Header>
        <div className={styles.tag_list}>
          <Space size={[0, 12]}>
            {tagList.map((item, index) => (
              <Tag
                key={item.link}
                color={item.link === keyStr ? "#108ee9" : "default"}
                onClick={(_) => tagClick(item)}
                onClose={() => tagClose(item)}
                closable={item.link === "home" ? false : true}
              >
                {item.title}
              </Tag>
            ))}
          </Space>
        </div>
        <Content
          style={{
            margin: "0 16px",
            overflowY: "auto",
            overflowX: "hidden",
            height: "calc(100vh - 116px)",
            background: "#ffffff",
            borderRadius: "8px",
            boxShadow: "0 2px 12px 0 rgba(0, 0, 0, 0.1)",
            padding: "16px 16px 32px 16px",
          }}
        >
          <Routers></Routers>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
