import React, { useEffect, useRef, useState } from "react";
import PageTable from "@/components/i-pageTable/i-pageTable";
import PageTab from "@/components/i-pageTab/i-pageTab";

import {
  vipList,
  vipAdd,
  vipEdit,
  categoryList,
  vipDetail,
} from "@/api/shopping";
import { Button, message, Modal, Space, Tag, Image } from "antd";
import { customRequest, normFile, resetForm, setIcons } from "@/utils/common";

import { useSelector, useDispatch, useStore } from "react-redux";
import { dictInfoAsync } from "@/stores/dictInfo";
import IForm from "@/components/i-form/i-form";
import { decimalReg, integerReg } from "@/utils/regs";

const status = [
  {
    label: "正常",
    value: "0",
  },
  {
    label: "下架",
    value: "1",
  },
];

const Goods = () => {
  const dispatch = useDispatch();
  const {
    state: { express_type, vip_level },
  } = useSelector((state: { dictInfo: any }) => state.dictInfo);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [mainPicUploadProgress, setMainPicUploadProgress] = useState(false);
  const [detailPicListUploadProgress, setDetailPicListUploadProgress] =
    useState(false);
  const [bannerPicListUploadProgress, setBannerPicListUploadProgress] =
    useState(false);

  const [pageData, setPageData] = useState<SelectCmsVipGoodsPageRes["rows"]>(
    []
  );
  const [formData, setFormData] = useState<any>({
    endTime: "",
    productName: "",
    startTime: "",
    ypageNum: 1,
    ypageSize: 10,
  });
  const [modalFormData, setModalFormData] = useState<AddVipGoodsParams>({
    status: "0",
    productMainPic: [],
    bannerPicList: [],
  });

  const [categoryListData, setCategoryListData] = useState<any>([]);

  const formTab = [
    {
      label: "商品名称",
      name: "productName",
      type: "input",
      width: "340px",
    },
    {
      label: "分类",
      name: "categoryId",
      type: "select",
      options: categoryListData,
      params: {
        fieldNames: {
          label: "categoryName",
          value: "categoryId",
        },
      },
    },
    {
      label: "状态",
      name: "status",
      type: "select",
      options: status,
    },
    {
      label: "时间范围",
      name: "rangePicker",
      type: "rangePicker",
      width: "380px",
    },
  ];

  const modalFormTab = [
    {
      label: "商品名称",
      name: "productName",
      type: "input",
      labelCol: { span: 7, offset: 0 },
      rules: [{ required: true, message: "请输入商品名称" }],
    },
    {
      label: "分类",
      name: "categoryId",
      type: "select",
      labelCol: { span: 6, offset: 0 },
      options: categoryListData,
      rules: [{ required: true, message: "请选择分类" }],
      params: {
        fieldNames: {
          label: "categoryName",
          value: "categoryId",
        },
      },
    },
    {
      label: "原价",
      name: "oriPrice",
      type: "input",
      labelCol: { span: 6, offset: 0 },
      rules: [
        { required: true, message: "请输入原价" },
        {
          pattern: decimalReg,
          message: "请输入正确原价",
        },
      ],
      params: {
        type: "number",
      },
    },
    {
      label: "折扣价",
      name: "discountPrice",
      type: "input",
      labelCol: { span: 6, offset: 0 },
      rules: [
        { required: true, message: "请输入折扣价" },
        {
          pattern: decimalReg,
          message: "请输入正确折扣价",
        },
      ],
      params: {
        type: "number",
      },
    },
    {
      label: "设备台数",
      name: "deviceNum",
      type: "input",
      labelCol: { span: 8, offset: 0 },
      rules: [
        { required: true, message: "请输入设备台数" },
        {
          pattern: decimalReg,
          message: "请输入正确设备台数",
        },
      ],
      params: {
        type: "number",
      },
    },
    {
      label: "排序",
      name: "sort",
      type: "input",
      labelCol: { span: 6, offset: 0 },
      params: {
        type: "number",
      },
    },
    {
      label: "主图",
      name: "productMainPic",
      type: "upload",
      labelCol: { span: 6, offset: 0 },
      width: "320px",
      rules: [{ required: true, message: "请上传主图" }],
      getValueFromEvent: normFile,
      params: {
        maxCount: 1,
        fileList: modalFormData.productMainPic,
        beforeUpload: () => {
          setMainPicUploadProgress(true);
        },
        uploadMessage: mainPicUploadProgress ? (
          <div>
            {setIcons("LoadingOutlined")}
            <div>上传中...</div>
          </div>
        ) : (
          <div>
            {setIcons("PlusOutlined")}
            <div style={{ marginTop: 8 }}>上传</div>
          </div>
        ),
        customRequest: async (e: any) => {
          try {
            const data = await customRequest(
              e,
              modalFormData.productMainPic as any[]
            );
            setModalFormData({
              ...modalFormData,
              productMainPic: data as any,
            });
          } catch (error) {
          } finally {
            setMainPicUploadProgress(false);
          }
        },
        onRemove: () => {
          setModalFormData({
            ...modalFormData,
            productMainPic: [],
          });
        },
      },
    },
    {
      label: "详情",
      name: "details",
      type: "quill",
      labelCol: { span: 2, offset: 0 },
      width: "420px",
      params: {
        style: { width: "420px" },
      },
    },
  ];

  const columns = [
    {
      title: "序号",
      dataIndex: "sort",
      key: "sort",
    },
    {
      title: "分类ID",
      dataIndex: "categoryId",
      key: "categoryId",
    },
    {
      title: "分类名称",
      dataIndex: "categoryName",
      key: "categoryName",
    },
    {
      title: "产品名称",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "主图",
      dataIndex: "productMainPic",
      key: "productMainPic",
      render: (val: string) =>(<Image src={val} height="100px"/>),
      
    },
    {
      title: "折扣价",
      dataIndex: "discountPrice",
      key: "discountPrice",
    },
    {
      title: "原价",
      dataIndex: "oriPrice",
      key: "oriPrice",
    },
    {
      title: "设备台数",
      dataIndex: "deviceNum",
      key: "deviceNum",
    },
    // {
    //   title: "结算价",
    //   dataIndex: "settlePrice",
    //   key: "settlePrice",
    // },

    // {
    //   title: "发货方式",
    //   dataIndex: "expressTypeLabel",
    //   key: "expressTypeLabel",
    //   render: (val: string) => <Tag color="green">{val}</Tag>,
    // },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (text: string) => (
        <Tag color="green">{text === "0" ? "正常" : "下架"}</Tag>
      ),
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      render: (val: string, rows: SelectCmsVipGoodsPageRes["rows"][0]) => (
        <Space size="small">
          {/* <Button
            size="small"
            icon={setIcons("SnippetsOutlined")}
            type="text"
            onClick={() => {
              outDetail(rows);
            }}
          >
            详情
          </Button> */}
          <Button
            size="small"
            icon={setIcons("EditOutlined")}
            type="text"
            onClick={() => {
              getDetail(val);
            }}
          >
            编辑
          </Button>
        </Space>
      ),
    },
  ];

  const footer = [
    {
      title: "新增",
      icon: setIcons("PlusOutlined"),
      onClick: () => setOpen(true),
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  useEffect(() => {
    !express_type && dispatch(dictInfoAsync("express_type") as any);
    !vip_level && dispatch(dictInfoAsync("vip_level") as any);
    getCategoryListData();
  }, []);

  const getDetail = async (id: string) => {
    try {
      const { data } = (await vipDetail(id)) as any;
      console.log(data, "data");

      setModalFormData({
        ...data,
        productMainPic: [
          {
            url: data.productMainPic,
            uid: data.productMainPic,
            name: data.productMainPic,
          },
        ],
      });
      setOpen(true);
    } catch (error) {}
  };

  const getCategoryListData = async () => {
    setLoading(true);
    try {
      const data = await categoryList({ ypageNum: 1, ypageSize: 50 });
      setCategoryListData(data.rows);
      // setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await vipList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const search = (val: any) => {
    setFormData({ ...formData, ...val, ypageNum: 1 });
  };

  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };

  const confirmData = async (val: any) => {
    try {
      if (val.benefitGivingStatus === 1 && !val.benefitName) {
        message.error("请输入权益包名称");
        return;
      }
      const params = {
        ...val,
        productMainPic: val.productMainPic?.[0]?.url,
      };
      if (params.id) {
        await vipEdit(params);
        message.success("编辑成功");
      } else {
        await vipAdd(params);
        message.success("新增成功");
      }
      resetModal();
      getPageData();
    } catch (error) {}
  };

  const outDetail = (val: any) => {
    Modal.info({
      width: "48%",
      okText: "好的",
      icon: null,
      content: (
        <IForm
          formTab={[
            {
              label: "缩略图",
              type: "image",
              name: val.productMainPic,
              span: 24,
              style: {
                height: "120px",
                width: "120px",
              },
            },
            // {
            //   label: "详情",
            //   type: "image",
            //   name: val.productMainPic,
            //   span: 24,
            //   style: {
            //     height: "120px",
            //     width: "120px",
            //   },
            // },
          ]}
        ></IForm>
      ),
    });
  };

  const reset = () => {
    setFormData({
      ...resetForm(formData),
    });
  };

  const resetModal = () => {
    setModalFormData({
      status: "0",
      productMainPic: [],
      bannerPicList: [],
    });
    setOpen(false);
  };

  return (
    <div>
      <Modal
        destroyOnClose
        open={open}
        title={modalFormData.id ? "编辑商品" : "新增商品"}
        width="520px"
        footer={<></>}
        onCancel={resetModal}
      >
        <PageTab
          subFoot
          search={confirmData}
          reset={resetModal}
          formTab={modalFormTab}
          initialValues={modalFormData}
        ></PageTab>
      </Modal>
      <PageTab
        defaultTimer={[]}
        reset={reset}
        search={search}
        formTab={formTab}
        footer={footer}
      ></PageTab>
      <PageTable
        loading={loading}
        rowKey="id"
        pageChange={pageChange}
        data={pageData}
        columns={columns}
        pagination={{
          total: total,
          current: formData.ypageNum,
        }}
      ></PageTable>
    </div>
  );
};

export default Goods;
