import React, { useEffect, useRef, useState } from "react";
import PageTable from "@/components/i-pageTable/i-pageTable";
import PageTab from "@/components/i-pageTab/i-pageTab";

import {
  shoppingOrderList,
  cmsRefund,
  doDeliver,
  shoppingOrderExport,
  benefitUse,
  getRewardDetail,
} from "@/api/shopping";
import { storeInfoBaseList } from "@/api/index";

import { Button, message, Modal, Space, Tag ,Image} from "antd";
import {
  downloadFile,
  getTimeFormat,
  resetForm,
  setIcons,
} from "@/utils/common";

import { useSelector, useDispatch, useStore } from "react-redux";
import { dictInfoAsync } from "@/stores/dictInfo";
import IForm from "@/components/i-form/i-form";

const notRefund = ["10007012", "10007011", "10007014", "10007016", "10007008"];

const OrderManager = () => {
  const dispatch = useDispatch();
  const {
    state: { goods_order_status, express_status, express_company_code },
  } = useSelector((state: { dictInfo: any }) => state.dictInfo);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [sendGoodsOpen, setSendGoodsOpen] = useState(false);
  const [offqybOpen, setOffqybOpen] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const [orderId, setOrderId] = useState("");
  const [orderAmount, setOrderAmount] = useState(0);
  const [storeInfoList, setStoreInfoList] = useState<
    {
      merId: string;
      merMobile: string;
      merName: string;
      userId: string;
    }[]
  >([]);

  const [pageData, setPageData] = useState<
    SelectCmsShoppingOrderPageRes["rows"]
  >([]);
  const [formData, setFormData] = useState<any>({
    categoryName: "",
    endTime: "",
    startTime: "",
    ypageNum: 1,
    ypageSize: 10,
  });

  const footer = [
    {
      title: "导出",
      icon: setIcons("DownloadOutlined"),
      onClick: () => exportExcel(),
      loading: exportLoading,
    },
  ];

  const formTab = [
    {
      label: "订单号",
      name: "orderId",
      type: "input",
      // width: "340px",
    },
    {
      label: "用户账号",
      name: "mobile",
      type: "input",
    },
    {
      label: "用户Id",
      name: "userId",
      type: "input",
    },
    {
      label: "时间范围",
      name: "rangePicker",
      type: "rangePicker",
      width: "380px",
    },
  ];

  const modalFormTab = [
    {
      label: "退款金额",
      name: "refundAmount",
      type: "input",
      rules: [{ required: true, message: "请填写退款金额" }],
      params: {
        type: "number",
      },
    },
    {
      label: "退款原因",
      name: "refundReason",
      type: "textArea",
      rules: [{ required: true, message: "请填写退款原因" }],
      params: {
        placeholder: "请输入退款原因！",
        rows: 5,
        maxLength: 100,
        showCount: true,
      },
    },
  ];

  const sendGoodsModalFormTab = [
    {
      label: "快递名称",
      name: "expressCompanyCode",
      type: "select",
      width: "400px",
      labelCol: { span: 0, offset: 2 },
      rules: [{ required: true, message: "请选择快递" }],
      options: express_company_code,
    },
    {
      label: "快递单号",
      name: "expressNo",
      type: "input",
      labelCol: { span: 0, offset: 2 },
      rules: [{ required: true, message: "请填写快递单号" }],
      width: "400px",
    },
    {
      label: "发货经手人姓名",
      name: "deliverUserName",
      type: "input",
      width: "400px",
    },
    {
      labelCol: { span: 0, offset: 3 },
      label: "发货备注",
      name: "deliverRemark",
      type: "textArea",
      width: "400px",
      params: {
        placeholder: "请输入发货备注",
        rows: 5,
        maxLength: 100,
        showCount: true,
      },
    },
  ];

  const columns = [
    {
      title: "订单号",
      dataIndex: "orderId",
      key: "orderId",
      // width: "220px",
    },
    {
      title: "用户Id",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "用户账号",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "产品名称",
      dataIndex: "productName",
      key: "productName",
      width: "200px",
    },
    {
      title: "数量",
      dataIndex: "deviceNum",
      key: "deviceNum",
    },
    {
      title: "产品ID",
      dataIndex: "productId",
      key: "productId",
    },
    {
      title: "订单金额",
      dataIndex: "payPrice",
      key: "payPrice",
    },
    {
      title: "订单状态",
      dataIndex: "orderStatus",
      key: "orderStatus",
      render: (val: string) => <Tag color="green">{val == "0" ? "已支付" : "未支付"}</Tag>,
    },
    {
      title: "是否分佣",
      dataIndex: "hasRewardStr",
      key: "hasRewardStr",
      render: (val: string) => <Tag color={val == "是" ? "green" : "red"}>{val}</Tag>,
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "操作",
      dataIndex: "orderId",
      key: "orderId",
      fixed: "right",
      render: (val: string, rows: any) => (
        <Space size="small">
          <Button
            size="small"
            icon={setIcons("SnippetsOutlined")}
            type="text"
            onClick={() => {
              outDetail(rows);
            }}
          >
            详情
          </Button>
          {/* {rows.goodsExpressStatus === "10007005" ? (
            <Button
              size="small"
              icon={setIcons("SendOutlined")}
              type="text"
              onClick={() => {
                sendGoods(val);
              }}
            >
              发货
            </Button>
          ) : (
            <></>
          )} */}
          {/* {!notRefund.includes(rows.goodsExpressStatus) &&
          rows.goodsOrderStatus !== "10007001" ? (
            <Button
              size="small"
              icon={setIcons("TransactionOutlined")}
              type="text"
              onClick={(e) => {
                setOpen(true);
                setOrderId(val);
                setOrderAmount(rows.orderPayPrice);
              }}
            >
              退款
            </Button>
          ) : (
            <></>
          )} */}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  useEffect(() => {
    !goods_order_status && dispatch(dictInfoAsync("goods_order_status") as any);
    !express_status && dispatch(dictInfoAsync("express_status") as any);
    !express_company_code &&
      dispatch(dictInfoAsync("express_company_code") as any);
  }, []);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await shoppingOrderList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const search = (val: any) => {
    setFormData({ ...formData, ...val, ypageNum: 1 });
  };
  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };

  const confirmData = async (val: {
    refundAmount: number;
    refundReason: string;
  }) => {
    if (orderAmount < val.refundAmount) {
      message.error(`退款金额不能大于实际支付金额${orderAmount}元`);
      return;
    }
    try {
      await cmsRefund({
        orderId,
        refundAmount: Number(val.refundAmount),
        refundReason: val.refundReason,
      });
      message.success("退款成功！");
      setOpen(false);
      getPageData();
    } catch (error) {}
  };
  const sendGoods = (val: string) => {
    setSendGoodsOpen(true);
    setOrderId(val);
  };
  const confirmSendGoods = async (val: any) => {
    try {
      await doDeliver({ ...val, orderId });
      message.success("发货成功");
      getPageData();
      setSendGoodsOpen(false);
    } catch (error) {}
  };

  const confirmOffQyb = async (val: { merId: string }) => {
    try {
      await benefitUse({
        merId: val.merId,
        orderId: orderId,
      });
      message.success("核销成功");
      setOffqybOpen(false);
      getPageData();
    } catch (error) {}
    console.log(val, "vvvvv");
  };

  const exportExcel = async () => {
    setExportLoading(true);
    try {
      const data = await shoppingOrderExport(formData);
      downloadFile(
        data as any,
        "商城订单统计" + getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss")
      );
    } catch (error) {
    } finally {
      setExportLoading(false);
    }
  };

  const outDetail = (val: any) => {
    try {
    const data = JSON.parse(val.productDetail);
    console.log(data, 'datadatadata');
    
    Modal.info({
      width: "680px",
      okText: "好的",
      icon: null,
      content: (
        <div>
        <div style={{ fontWeight: "bold" }}>产品分类：</div>
        <div style={{marginBottom: 6}}>{data.categoryName}</div>
        <div style={{ fontWeight: "bold" }}>产品名称：</div>
        <div style={{marginBottom: 6}}>{data.productName}</div>
        <div style={{ fontWeight: "bold" }}>产品详情：</div>
        <div dangerouslySetInnerHTML={{ __html: data.details }} />
        </div>

      ),
    });
    } catch (error) {
      Modal.info({
        width: "64%",
        okText: "好的",
        icon: null,
        content: (
          <div>
          {/* <div >产品详情：</div> */}
          {/* <div/> */}
          </div>
  
        ),
      });
    }

  };

  const offQyb = (val: string) => {
    setOffqybOpen(true);
    setOrderId(val);
  };

  const outQyb = (val: any) => {
    Modal.info({
      width: "40%",
      okText: "好的",
      icon: null,
      content: (
        <IForm
          formTab={[
            {
              label: "订单号",
              name: val.orderId,
            },
            {
              label: "权益包名称",
              name: val.benefitName,
            },
            {
              label: "核销商家",
              name: val.merId,
            },
            {
              label: "核销商家电话",
              name: val.merMobile,
            },
            {
              label: "核销商家名称",
              name: val.merName,
            },
            {
              label: "失效时间",
              name: val.benefitExpireTime,
            },
            {
              label: "核销时间",
              name: val.benefitUseTime,
            },
          ]}
        ></IForm>
      ),
    });
  };

  const setOrderDollar = async (val: string) => {
    try {
      const { data } = await getRewardDetail(val);
      Modal.info({
        title: "分佣详情",
        width: "48%",
        okText: "好的",
        icon: null,
        content: (
          <PageTable
            columns={[
              {
                title: "资金走向类型",
                dataIndex: "accountTrend",
                key: "accountTrend",
                render: (val: string) => <Tag color="blue">{val === '10004001' ? '存入' : '支出'}</Tag>
              },
              {
                title: "金额",
                dataIndex: "amount",
                key: "amount",
              },
              {
                title: "手机号",
                dataIndex: "mobile",
                key: "mobile",
              },
              {
                title: "收入类型",
                dataIndex: "rewardType",
                key: "rewardType",
              },
              {
                title: "类型",
                dataIndex: "roleTypeName",
                key: "roleTypeName",
              },
              {
                title: "用户名/运营中心名称",
                dataIndex: "userName",
                key: "userName",
              },
              {
                title: "创建时间",
                dataIndex: "createTime",
                key: "createTime",
              },
            ]}
            data={data}
          ></PageTable>
        ),
      });
    } catch (error) {}
  };

  const reset = () => {
    setFormData({ ...resetForm(formData), dateType: 0 });
  };

  const getMerList = async (keyword: string) => {
    console.log(keyword, "keywordkeyword");
    try {
      const { data } = await storeInfoBaseList(keyword);
      setStoreInfoList(data);
    } catch (error) {}
  };

  return (
    <div>
      <Modal
        destroyOnClose
        open={offqybOpen}
        title="权益包核销"
        width="28%"
        footer={<></>}
        onCancel={(_) => setOffqybOpen(false)}
      >
        <PageTab
          subFoot
          search={confirmOffQyb}
          reset={(_) => setOffqybOpen(false)}
          formTab={[
            {
              label: "商家名称",
              name: "merId",
              type: "select",
              width: "400px",
              rules: [{ required: true, message: "请选择商家" }],
              options: (storeInfoList || []).map(
                (d: { merId: string; merName: string }) => ({
                  value: d.merId,
                  label: d.merName,
                })
              ),
              params: {
                defaultActiveFirstOption: false,
                showSearch: true,
                filterOption: false,
                onSearch: (e: string) => {
                  getMerList(e);
                },
              },
            },
          ]}
        ></PageTab>
      </Modal>
      <Modal
        destroyOnClose
        open={sendGoodsOpen}
        title="发货"
        width="28%"
        footer={<></>}
        onCancel={(_) => setSendGoodsOpen(false)}
      >
        <PageTab
          subFoot
          search={confirmSendGoods}
          reset={(_) => setSendGoodsOpen(false)}
          formTab={sendGoodsModalFormTab}
        ></PageTab>
      </Modal>
      <Modal
        destroyOnClose
        open={open}
        title="退款"
        width="20%"
        footer={<></>}
        onCancel={(_) => setOpen(false)}
      >
        <PageTab
          subFoot
          search={confirmData}
          reset={(_) => setOpen(false)}
          formTab={modalFormTab}
        ></PageTab>
      </Modal>
      <PageTab
        defaultTimer={[]}
        reset={reset}
        search={search}
        formTab={formTab}
        footer={footer}
      ></PageTab>
      <PageTable
        loading={loading}
        rowKey="orderId"
        pageChange={pageChange}
        data={pageData}
        columns={columns}
        pagination={{
          total: total,
          current: formData.ypageNum,
        }}
      ></PageTable>
    </div>
  );
};

export default OrderManager;
