import React, { useEffect, useRef, useState } from "react";
import PageTable from "@/components/i-pageTable/i-pageTable";
import PageTab from "@/components/i-pageTab/i-pageTab";
import {
  areaList,
  areaAdd,
  areaEdit,
  areaDelete,
  areaRefreshCache,
} from "@/api/setting";
import { Button, message, Modal, Space, Tag } from "antd";
import { resetForm, setIcons, showModal } from "@/utils/common";

const AreaManager = () => {
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectKeys, setSelectKeys] = useState<any[]>([]);
  const [pageData, setPageData] = useState<GetCmsAreaPageRes["rows"]>([]);

  const [formData, setFormData] = useState<GetCmsAreaPageParams>({
    areaName: "",
    ypageNum: 1,
    ypageSize: 10,
  });
  const [modalFormData, setModalFormData] = useState<AreaAddParams>({
  });

  const formTab = [
    {
      label: "城市名称",
      name: "areaName",
      type: "input",
    },
    // {
    //   label: "上级编码",
    //   name: "parentId",
    //   type: "input",
    // },
    {
      label: "层级",
      name: "level",
      type: "select",
      options: [
        {
          label: "省",
          value: 1,
        },
        {
          label: "市",
          value: 2,
        },
        {
          label: "区县",
          value: 3,
        },
      ],
    },
  ];

  const footer = [
    {
      title: "新增",
      icon: setIcons("PlusOutlined"),
      onClick: () => {
        handleAdd();
      },
    },
    {
      title: "删除",
      icon: setIcons("DeleteOutlined"),
      disabled: !selectKeys.length,
      onClick: () => {
        handleDelete(selectKeys);
      },
    },
    {
      title: "缓存刷新",
      icon: setIcons("CloudSyncOutlined"),
      onClick: () => {
        reverseCache();
      },
    },
  ];

  const modalFormTab = [
    {
      label: "层级",
      name: "level",
      type: "select",
      rules: [{ required: true, message: "请选择层级" }],
      labelCol: { span: 0, offset: 3 },
      options: [
        {
          label: "省",
          value: 1,
        },
        {
          label: "市",
          value: 2,
        },
        {
          label: "区县",
          value: 3,
        },
      ],
    },
    {
      label: "上级编码",
      name: "parentId",
      type: "input",
      rules: [{ required: true, message: "请填写上级编码" }],
    },
    {
      label: "城市名称",
      name: "areaName",
      type: "input",
      rules: [{ required: true, message: "请填写城市名称" }],
    },
    {
      label: "城市编码",
      name: "areaCode",
      type: "input",
      rules: [{ required: true, message: "请填写城市编码" }],
    },
  ];

  const columns = [
    {
      title: "城市名称",
      dataIndex: "areaName",
      key: "areaName",
    },
    {
      title: "城市编码",
      dataIndex: "areaCode",
      key: "areaCode",
    },
    {
      title: "上级编码",
      dataIndex: "parentId",
      key: "parentId",
    },
    {
      title: "层级",
      dataIndex: "level",
      key: "level",
      render: (val: number) => (
        <Tag color="blue">{val === 1 ? "省" : val === 2 ? "市" : "区县"}</Tag>
      ),
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      width: "240px",
      render: (val: string, rows: any) => (
        <Space size="small">
          <Button
            size="small"
            icon={setIcons("EditOutlined")}
            type="text"
            onClick={() => {
              handleEdit(rows);
            }}
          >
            修改
          </Button>
          <Button
            size="small"
            icon={setIcons("DeleteOutlined")}
            type="text"
            onClick={() => {
              handleDelete(val);
            }}
          >
            删除
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await areaList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const search = (val: any) => {
    setFormData({ ...formData, ...val, ypageNum: 1 });
  };
  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };

  const handleAdd = () => {
    setOpen(true);
  };

  const reset = () => {
    setFormData({ ...resetForm(formData) });
    setSelectKeys([]);
  };
  const confirmData = async (val: any) => {
    try {
      if (val.id) {
        await areaEdit(val);
        message.success("编辑成功");
      } else {
        await areaAdd(val);
        message.success("新增成功");
      }
      modalReset();
      getPageData();
    } catch (error) {}
  };
  const modalReset = () => {
    setModalFormData({});
    setOpen(false);
  };
  const selectionChange = (keys: any[]) => {
    setSelectKeys(keys);
  };

  const handleEdit = async (val: any) => {
    try {
      setModalFormData(val);
      setOpen(true);
    } catch (error) {}
  };

  const handleDelete = async (val: string | any[]) => {
    try {
      const data = await showModal({
        title: "提示",
        content: "确定要删除选中的地区吗？",
      });
      if (data === "ok") {
        await areaDelete(val);
        message.success("删除成功");
        modalReset();
        getPageData();
      }
    } catch (error) {}
  };

  const reverseCache = async () => {
    try {
      await areaRefreshCache();
      message.success("刷新成功");
    } catch (error) {}
  };

  return (
    <div>
      <Modal
        destroyOnClose
        open={open}
        title={modalFormData.id ? "编辑地区" : "新增地区"}
        width="24%"
        footer={<></>}
        onCancel={modalReset}
      >
        <PageTab
          subFoot
          search={confirmData}
          reset={modalReset}
          formTab={modalFormTab}
          initialValues={modalFormData}
        ></PageTab>
      </Modal>
      <PageTab
        reset={reset}
        search={search}
        formTab={formTab}
        footer={footer}
      ></PageTab>
      <PageTable
        selection
        selectionChange={selectionChange}
        selectedRowKeys={selectKeys}
        loading={loading}
        rowKey="id"
        pageChange={pageChange}
        data={pageData}
        columns={columns}
        pagination={{
          total: total,
          current: formData.ypageNum,
        }}
      ></PageTable>
    </div>
  );
};

export default AreaManager;
