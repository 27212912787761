import React, { useEffect, useRef, useState } from "react";
import PageTable from "@/components/i-pageTable/i-pageTable";
import PageTab from "@/components/i-pageTab/i-pageTab";
import Maps from "@/components/Maps/index";
import { Form } from "antd";

import {
  deviceList,
  doDeliver,
  deviceDetail,
} from "@/api/shopping";
import { storeInfoBaseList } from "@/api/index";

import { Button, message, Modal, Space, Tag, Image } from "antd";
import {
  downloadFile,
  getTimeFormat,
  resetForm,
  setIcons,
} from "@/utils/common";

import { useSelector, useDispatch, useStore } from "react-redux";
import { dictInfoAsync } from "@/stores/dictInfo";
import IForm from "@/components/i-form/i-form";

const notRefund = ["10007012", "10007011", "10007014", "10007016", "10007008"];

const Equipment = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    state: { goods_order_status, express_status, express_company_code },
  } = useSelector((state: { dictInfo: any }) => state.dictInfo);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [sendGoodsOpen, setSendGoodsOpen] = useState(false);
  const [offqybOpen, setOffqybOpen] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const [orderData, setOrderData] = useState({});
  const [orderAmount, setOrderAmount] = useState(0);
  const [storeInfoList, setStoreInfoList] = useState<
    {
      merId: string;
      merMobile: string;
      merName: string;
      userId: string;
    }[]
  >([]);

  const [pageData, setPageData] = useState<
    SelectCmsShoppingOrderPageRes["rows"]
  >([]);
  const [formData, setFormData] = useState<any>({
    categoryName: "",
    endTime: "",
    startTime: "",
    ypageNum: 1,
    ypageSize: 10,
  });

  const footer = [
    // {
    //   title: "导出",
    //   icon: setIcons("DownloadOutlined"),
    //   onClick: () => exportExcel(),
    //   loading: exportLoading,
    // },
  ];

  const formTab = [
    {
      label: "用户账号",
      name: "mobile",
      type: "input",
      width: "340px",
    },
    {
      label: "时间范围",
      name: "rangePicker",
      type: "rangePicker",
      width: "380px",
    },
  ];

  const modalFormTab = [
    {
      label: "退款金额",
      name: "refundAmount",
      type: "input",
      rules: [{ required: true, message: "请填写退款金额" }],
      params: {
        type: "number",
      },
    },
    {
      label: "退款原因",
      name: "refundReason",
      type: "textArea",
      rules: [{ required: true, message: "请填写退款原因" }],
      params: {
        placeholder: "请输入退款原因！",
        rows: 5,
        maxLength: 100,
        showCount: true,
      },
    },
  ];

  const sendGoodsModalFormTab = [
    {
      label: "产品名称",
      name: "productName",
      type: "input",
      width: "400px",
      params: {
        disabled: true,
      },
    },
    {
      label: "充电单价",
      name: "price",
      type: "input",
      params: {
        type: "number",
        placeholder: "请填写充电单价，元/半小时",
      },
      rules: [{ required: true, message: "请填写充电单价" }, {
        validator: (_: any, value: number) => {
          if (value < 0.1) {
            return Promise.reject(new Error("充电单价不能小于0.1元/半小时"));
          }
          return Promise.resolve();
        },
      }],
      width: "400px",
    },
    {
      label: "详细地址",
      name: "address",
      type: "input",
      width: "400px",
      params: {
        placeholder: "请选择详细地址",
        readOnly: true,
        onClick: () => {
          setOffqybOpen(true);
        },
      },
    },
    {
      label: "状态",
      name: "status",
      type: "radio",
      width: "400px",
      options: [
        { label: "正常", value: "0" },
        { label: "停用", value: "1" },
      ],
    },
  ];

  const columns = [
    {
      title: "用户ID",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "用户名",
      dataIndex: "nickName",
      key: "nickName",
    },
    {
      title: "用户账号",
      dataIndex: "mobile",
      key: "mobile",
    },
    // {
    //   title: "设备数量",
    //   dataIndex: "num",
    //   key: "num",
    // },
    {
      title: "充电单价，元/半小时",
      dataIndex: "price",
      key: "price",
    },
    // {
    //   title: "产品ID",
    //   dataIndex: "productId",
    //   key: "productId",
    // },
    {
      title: "产品名称",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "投放地点",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "购买时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "设备状态",
      dataIndex: "status",
      key: "status",
      render: (val: number) => (
        <Tag color={val == 0 ? "blue" : "red"}>{val == 0 ? "正常" : "停用"}</Tag>
      ),
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      render: (val: string, rows: any) => (
        <Space size="small">
          <Button
            size="small"
            icon={setIcons("SnippetsOutlined")}
            type="text"
            onClick={() => {
              outDetail(val);
            }}
          >
            详情
          </Button>

          <Button
            size="small"
            icon={setIcons("EditOutlined")}
            type="text"
            onClick={() => {
              sendGoods(rows);
            }}
          >
            编辑
          </Button>

          {/* {!notRefund.includes(rows.goodsExpressStatus) &&
          rows.goodsOrderStatus !== "10007001" ? (
            <Button
              size="small"
              icon={setIcons("TransactionOutlined")}
              type="text"
              onClick={(e) => {
                setOpen(true);
                setOrderId(val);
                setOrderAmount(rows.orderPayPrice);
              }}
            >
              退款
            </Button>
          ) : (
            <></>
          )} */}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  useEffect(() => {
    !goods_order_status && dispatch(dictInfoAsync("goods_order_status") as any);
    !express_status && dispatch(dictInfoAsync("express_status") as any);
    !express_company_code &&
      dispatch(dictInfoAsync("express_company_code") as any);
  }, []);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await deviceList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const search = (val: any) => {
    setFormData({ ...formData, ...val, ypageNum: 1 });
  };
  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };

  const sendGoods = (val: any) => {
    setSendGoodsOpen(true);
    setOrderData(val);
    form.setFieldsValue(val);
  };
  const confirmSendGoods = async (val: any) => {
    console.log(val, "val");
    try {
      await doDeliver({...orderData , ...val, });
      message.success("修改成功");
      getPageData();
      setSendGoodsOpen(false);
    } catch (error) {}
  };


  const outDetail = async (val: any) => {
    try {
      const { data } = await deviceDetail(val);
      Modal.info({
        width: "680px",
        okText: "好的",
        icon: null,
        content: (
          <div>
            {/* <div style={{ fontWeight: "bold" }}>产品分类：</div>
            <div style={{ marginBottom: 6 }}>{data.categoryName}</div> */}
            <div style={{ fontWeight: "bold" }}>产品名称：</div>
            <div style={{ marginBottom: 6 }}>{data.productName}</div>
            <div style={{ fontWeight: "bold" }}>更新时间：</div>
            <div style={{ marginBottom: 6 }}>{data.updateTime}</div>
            <div style={{ fontWeight: "bold" }}>产品详情：</div>
            <img style={{ width: "100%" }} src={data.productMainPic} />
          </div>
        ),
      });
    } catch (error) {
      Modal.info({
        width: "680px",
        okText: "好的",
        icon: null,
        content: (
          <div>
            {/* <div >产品详情：</div> */}
            {/* <div/> */}
          </div>
        ),
      });
    }
  };

  const reset = () => {
    setFormData({ ...resetForm(formData) });
  };
  const handleMapSelect = (
    address: string,
    location: { lng: number; lat: number }
  ) => {
    form.setFieldsValue({ address });
    setOrderData({ ...orderData, lat: location.lat, lng: location.lng });
    console.log("选择的地址:", address);
    console.log("经纬度:", location);
    setOffqybOpen(false);
  };

  return (
    <div>
      {offqybOpen && (
        <Maps
          visible={offqybOpen}
          onCancel={() => setOffqybOpen(false)}
          onOk={handleMapSelect}
        />
      )}
      <Modal
        destroyOnClose
        open={sendGoodsOpen}
        title="编辑"
        width="600px"
        footer={<></>}
        onCancel={(_) => setSendGoodsOpen(false)}
      >
        <PageTab
          subFoot
          search={confirmSendGoods}
          reset={(_) => setSendGoodsOpen(false)}
          formTab={sendGoodsModalFormTab}
          form={form}
        ></PageTab>
      </Modal>
      <PageTab
        defaultTimer={[]}
        reset={reset}
        search={search}
        formTab={formTab}
      ></PageTab>
      <PageTable
        loading={loading}
        rowKey="id"
        pageChange={pageChange}
        data={pageData}
        columns={columns}
        pagination={{
          total: total,
          current: formData.ypageNum,
        }}
      ></PageTable>
    </div>
  );
};

export default Equipment;
