import { createSlice } from "@reduxjs/toolkit";
import styles from "@/static/css/index.module.css";

const initialState = {
  tagList: [
    {
      link: "home",
      title: "首页",
      breadcrumbData: [
        {
          title: "首页",
          className: 'selectBreadcrumb',
          path: ''
        },
      ],
      selectedKeys: ["home"],
    },
  ],
  keyStr: "home",
  submenuKeys: [],
  openKeys:[]
};

export const storeReducer = createSlice({
  name: "store",
  initialState,
  reducers: {
    setKeyStr: (state, action) => {
      state.keyStr = action.payload;
    },
    setTagList: (state, action) => {
      state.tagList = action.payload;
    },
    setSubmenuKeys: (state, action) => {
      state.submenuKeys = action.payload;
    },
    setOpenKeys: (state, action) => {
      state.openKeys = action.payload;
    },
  },
});

export const { setTagList, setKeyStr, setSubmenuKeys,setOpenKeys } = storeReducer.actions;
export default storeReducer.reducer;
