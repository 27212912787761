import {
  findNodesWithoutChildren,
  resetForm,
  setIcons,
  showModal,
} from "@/utils/common";
import { Button, message, Modal, Space, Switch, Tag } from "antd";
import React, { useEffect, useState } from "react";
import {
  roleList,
  roleChangeStatus,
  treeSelect,
  roleAdd,
  roleDetail,
  roleMenuTreeSelect,
  roleEdit,
  roleDelete,
  roleDeptTree,
  dataScope,
} from "@/api/setting";
import PageTab from "@/components/i-pageTab/i-pageTab";
import PageTable from "@/components/i-pageTable/i-pageTable";
import { useNavigate } from "react-router-dom";

const RoleManager = () => {
  const navigate = useNavigate();
  const [pageData, setPageData] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [checkedKeys, setCheckedKeys] = useState<number[]>([]);
  const [halfCheckedKeys, setHalfCheckedKeys] = useState<number[]>([]);

  const [selectKeys, setSelectKeys] = useState<number[]>([]);
  const [authOpen, setAuthOpen] = useState(false);

  const [formData, setFormData] = useState({
    roleName: "",
    roleKey: "",
    ypageNum: 1,
    ypageSize: 10,
    list: [],
  });
  const [modalFormData, setModalFormData] = useState<RoleAddParams>({
    roleKey: "",
    roleName: "",
    status: "0",
    menuIds: [],
  });

  const [treeData, setTreeData] = useState<any>([]);
  const [notChildrenTree, setNotChildrenTree] = useState<any>([]);
  const [deptTreeData, setDeptTreeData] = useState<any>([]);
  const [dataScopeKey, setDataScopeKey] = useState("1");

  const formTab = [
    {
      label: "角色名称",
      name: "roleName",
      type: "input",
    },
    {
      label: "权限字符",
      name: "roleKey",
      type: "input",
    },
    {
      label: "状态",
      name: "status",
      type: "select",
      options: [
        { value: "0", label: "正常" },
        { value: "1", label: "停用" },
      ],
    },
    // {
    //   label: "创建时间",
    //   name: "rangePicker",
    //   type: "rangePicker",
    //   width: "330px",
    // },
  ];
  const columns = [
    {
      title: "角色编号",
      dataIndex: "roleId",
      key: "roleId",
    },
    {
      title: "角色名称",
      dataIndex: "roleName",
      key: "roleName",
    },
    {
      title: "权限字符",
      dataIndex: "roleKey",
      key: "roleKey",
    },
    {
      title: "显示顺序",
      dataIndex: "roleSort",
      key: "roleSort",
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (val: string, data: any) => (
        <Switch
          defaultChecked={val === "0"}
          checked={val === "0"}
          onClick={(_) => statusModal(data)}
        ></Switch>
      ),
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "操作",
      dataIndex: "roleId",
      key: "roleId",
      fixed: "right",
      width: "150px",
      render: (val: number, rows: any) => (
        <Space>
          <Button
            size="small"
            icon={setIcons("EditOutlined")}
            type="text"
            onClick={() => handleEdit(val)}
          >
            修改
          </Button>
          <Button
            size="small"
            onClick={() => handleDelete(val)}
            icon={setIcons("DeleteOutlined")}
            type="text"
          >
            删除
          </Button>
          <Button
            size="small"
            icon={setIcons("SolutionOutlined")}
            type="text"
            onClick={() => shareAuth(val)}
          >
            数据权限
          </Button>
          <Button
            size="small"
            onClick={() => navigate("/roleManager/scopeRole/" + val)}
            icon={setIcons("UserSwitchOutlined")}
            type="text"
          >
            分配用户
          </Button>
        </Space>
      ),
    },
  ];
  const authForm = [
    {
      label: "角色名称",
      name: "roleName",
      type: "input",
      params: {
        disabled: true,
      },
    },
    {
      label: "权限字符",
      name: "roleKey",
      type: "input",

      params: {
        disabled: true,
      },
    },
    {
      label: "权限范围",
      name: "dataScope",
      type: "select",

      options: [
        { value: "1", label: "全部数据权限" },
        { value: "2", label: "自定数据权限" },
        { value: "3", label: "本部门数据权限" },
        { value: "4", label: "本部门及以下数据权限" },
      ],
      params: {
        onSelect(val: any) {
          setDataScopeKey(val);
        },
      },
    },
    {
      label: "数据权限",
      name: "tree",
      type: "tree",
      params: {
        treeData: deptTreeData,
        checkable: true,
        fieldNames: {
          title: "label",
          key: "id",
          children: "children",
        },
        checkedKeys: checkedKeys,
        // defaultExpandedKeys: checkedKeys,
        onCheck(keys: any) {
          setCheckedKeys(keys);
        },
      },
    },
  ];

  const modalForm = [
    {
      label: "角色名称",
      name: "roleName",
      type: "input",

      rules: [{ required: true, message: "请输入角色名称" }],
    },
    {
      label: "权限字符",
      name: "roleKey",
      type: "input",

      rules: [{ required: true, message: "请输入权限字符" }],
    },
    {
      label: "角色顺序",
      name: "roleSort",
      type: "input",

      params: {
        type: "number",
      },
      rules: [{ required: true, message: "请输入权限字符" }],
    },
    {
      label: "状态",
      name: "status",
      type: "radio",
      labelCol: { span: 0, offset: 4 },
      options: [
        { value: "0", label: "显示" },
        { value: "1", label: "隐藏" },
      ],
    },
    {
      label: "菜单权限",
      name: "tree",
      type: "tree",
      labelCol: { span: 7, offset: 1 },
      params: {
        treeData: treeData,
        checkable: true,
        style: { width: "220px" },
        fieldNames: {
          title: "label",
          key: "id",
          children: "children",
        },
        multiple: true,
        checkedKeys: checkedKeys,
        onCheck(keys: any[], data: { halfCheckedKeys: number[] }) {
          setCheckedKeys(keys);
          setHalfCheckedKeys(data.halfCheckedKeys);
        },
      },
    },
  ];

  const footer = [
    {
      title: "新增",
      icon: setIcons("PlusOutlined"),
      onClick: () => {
        setOpen(true);
      },
    },
    {
      title: "修改",
      icon: setIcons("EditOutlined"),
      disabled: selectKeys.length !== 1,
      onClick: () => {
        handleEdit(selectKeys[0]);
      },
    },
    {
      title: "删除",
      icon: setIcons("DeleteOutlined"),
      disabled: selectKeys.length === 0,
      onClick: () => {
        handleDelete(selectKeys);
      },
    },
  ];

  useEffect(() => {
    getTreeSelect();
  }, []);

  useEffect(() => {
    getRoleList();
  }, [formData]);

  const search = (data: any) => {
    setFormData({
      ...formData,
      ...data,
      params: { beginTime: data.startDate, endTime: data.endDate },
      ypageNum: 1,
    });
  };
  const pageChange = (page: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: page,
      ypageSize: pageSize,
    });
  };

  const [open, setOpen] = useState(false);
  const handleOk = async (data: RoleAddParams) => {
    try {
      if (data.roleId) {
        await roleEdit({
          ...data,
          menuIds: [...checkedKeys, ...halfCheckedKeys],
        });
        message.success("修改成功");
      } else {
        await roleAdd({
          ...data,
          menuIds: [...checkedKeys, ...halfCheckedKeys],
        });
        message.success("添加成功");
      }
      setOpen(false);
      clearModalForm();
      getRoleList();
    } catch (error) {}
  };
  const getRoleList = async () => {
    try {
      const data = await roleList({ ...formData });
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {}
  };

  const getTreeSelect = async () => {
    try {
      const { data } = await treeSelect();
      setTreeData(data);
      setNotChildrenTree(findNodesWithoutChildren(data));
    } catch (error) {}
  };

  const statusModal = async (item: { status: string; roleId: number }) => {
    const data = await showModal({
      title: "系统提示",
      content: (
        <div>确认要{item.status === "0" ? "停用" : "启用"}选中的角色吗？</div>
      ),
    });
    if (data === "ok") {
      try {
        await roleChangeStatus({
          roleId: item.roleId,
          status: item.status === "0" ? "1" : "0",
        });
        message.success("用户状态修改成功");
        getRoleList();
      } catch (error) {}
    }
  };
  const handleEdit = async (val: number) => {
    try {
      const [
        { data },
        {checkedKeys },
      ] = await Promise.all([roleDetail(val), roleMenuTreeSelect(val)]);

      
      const keys = notChildrenTree.filter((i: number) =>
        checkedKeys.includes(i)
      );
      const halfKeys = checkedKeys.filter((i: number) => !keys.includes(i));
      setModalFormData(data);
      setCheckedKeys(keys);
      setHalfCheckedKeys(halfKeys);
      setOpen(true);
    } catch (error) {
      
    }
  };

  const handleDelete = async (val: number | number[]) => {
    try {
      const data = await showModal({
        title: "系统提示",
        content: <div>是否确认删除当前选中角色的数据项？</div>,
      });
      if (data === "ok") {
        await roleDelete(val);
        message.success("删除成功");
        getRoleList();
      }
    } catch (error) {}
  };

  const reset = () => {
    setFormData({
      roleName: "",
      roleKey: "",
      ypageNum: 1,
      ypageSize: 10,
      list: [],
    });
  };

  const clearModalForm = () => {
    setModalFormData({ ...resetForm(modalFormData), status: "0" });
    setOpen(false);
    setAuthOpen(false);
    setCheckedKeys([]);
    setHalfCheckedKeys([]);
    setDataScopeKey("1");
  };

  const selectionChange = (keys: any[]) => {
    setSelectKeys(keys);
  };

  const shareAuth = async (val: number) => {
    try {
      const [
        { data },
        
        { depts, checkedKeys },
      ] = await Promise.all([roleDetail(val), roleDeptTree(val)]);
      setModalFormData(data);
      setDeptTreeData(depts);

      const tree = findNodesWithoutChildren(depts);
      const keys = tree.filter((i: number) => checkedKeys.includes(i));
      const halfKeys = checkedKeys.filter((i: number) => !keys.includes(i));

      setCheckedKeys(keys);
      setHalfCheckedKeys(halfKeys);
      setDataScopeKey(data.dataScope);
      setAuthOpen(true);
    } catch (error) {}
  };

  const authChange = async (data: any) => {
    try {
      await dataScope({ ...data, deptIds: checkedKeys });
      message.success("权限保存成功");
      setAuthOpen(false);
      getRoleList();
      clearModalForm();
    } catch (error) {}
  };

  return (
    <div>
      <Modal
        destroyOnClose
        width="24%"
        title="分配数据权限"
        open={authOpen}
        onCancel={clearModalForm}
        footer={<></>}
      >
        <PageTab
          search={authChange}
          reset={clearModalForm}
          subFoot
          formTab={
            dataScopeKey === "2"
              ? authForm
              : authForm.slice(0, authForm.length - 1)
          }
          initialValues={modalFormData}
        ></PageTab>
      </Modal>
      <Modal
        destroyOnClose
        width="24%"
        title={modalFormData.roleId ? "编辑角色" : "添加角色"}
        open={open}
        onCancel={clearModalForm}
        footer={<></>}
      >
        <PageTab
          search={handleOk}
          reset={clearModalForm}
          subFoot
          formTab={modalForm}
          initialValues={modalFormData}
        ></PageTab>
      </Modal>
      <PageTab
        defaultTimer={[]}
        reset={reset}
        search={search}
        formTab={formTab}
        footer={footer}
      ></PageTab>
      <PageTable
        rowKey="roleId"
        selection
        selectionChange={selectionChange}
        pageChange={pageChange}
        data={pageData}
        columns={columns}
        pagination={{
          total: total,
          current: formData.ypageNum,
        }}
      ></PageTable>
    </div>
  );
};

export default RoleManager;
