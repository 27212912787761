import React, { useEffect, useState } from "react";
import styles from "@/static/css/index.module.css";
import { getDays, getFirstDay, getTimeFormat } from "@/utils/common";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Row,
  Space,
  Tabs,
  TabsProps,
} from "antd";
import * as echarts from "echarts";
import { getCmsIndexTendency, getCmsIndexVo, statistics } from "@/api";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const time = getTimeFormat(new Date(), "MM/dd") + " " + getDays(new Date());

const dateList = ["日", "周", "月"];

const HomeData: Record<string, any> = {
  cmsIndexGasOrderStatisticsVo: "车店交易概况",
  cmsIndexMerOrderStatisticsVo: "加油交易概况",
  cmsIndexVipOrderStatisticsVo: "会员交易概况",
};

const TimeData: Record<string, any> = {
  orderNum: "订单数",
  orderPayAmount: "订单金额",
  rewardAmount: "分润金额",
  deviceNum: "设备台数",
  subsidyAmount: "充电收益",
  applyWithdrawAmount: "申请提现金额",
  approveWithdrawAmount: "审批提现金额",
  cmsChargeAmount: "后台充值金额",
  teamTransferAmount: "团队转赠金额",
  teamTransferServiceAmount: "转赠手续费",
  transferAmount: "管理员转赠金额",
};

const TimeDataList = [
  "deviceNum",
  "orderNum",
  "orderPayAmount",
  "rewardAmount",
  "applyWithdrawAmount",
  "approveWithdrawAmount",
  "cmsChargeAmount",
  "subsidyAmount",
  "teamTransferAmount",
  "teamTransferServiceAmount",
  "transferAmount",
];

const Home = () => {
  const [merActive, setMerActive] = useState("0");
  const [vipActive, setVipActive] = useState("0");
  const [gasActive, setGasActive] = useState("0");
  const [merChart, setMerChart] = useState<any>();
  const [vipChart, setVipChart] = useState<any>();
  const [gasChart, setGasChart] = useState<any>();
  const [merData, setMerData] = useState<any>({});
  const [vipData, setVipData] = useState<any>({});
  const [gasData, setGasData] = useState<any>({});
  const [merFormData, setMerFormData] = useState({
    startDate: getTimeFormat(getFirstDay(new Date()), "yyyy-MM-dd HH:mm:ss"),
    endDate: getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss"),
    dateType: "0",
  });
  const [vipFormData, setVipFormData] = useState({
    startDate: getTimeFormat(getFirstDay(new Date()), "yyyy-MM-dd HH:mm:ss"),
    endDate: getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss"),
    dateType: "0",
  });
  const [gasFormData, setGasFormData] = useState({
    startDate: getTimeFormat(getFirstDay(new Date()), "yyyy-MM-dd HH:mm:ss"),
    endDate: getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss"),
    dateType: "0",
  });
  const [showData, setShowData] = useState(false);
  const [homePageData, setHomePageData] = useState<any>({});
  const [weekData, setWeekData] = useState<any>({});
  const [monthData, setMonthData] = useState<any>({});
  const items: TabsProps["items"] = [
    {
      key: "0",
      label: `交易金额`,
    },
    {
      key: "1",
      label: `交易笔数`,
    },
    {
      key: "2",
      label: `交易人数`,
    },
  ];
  useEffect(() => {
    if (showData) return;
    // var mChart = echarts.init(document.getElementById("merMain"));
    // var vChart = echarts.init(document.getElementById("vipMain"));
    // var gChart = echarts.init(document.getElementById("gasMain"));
    // setMerChart(mChart);
    // setVipChart(vChart);
    // setGasChart(gChart);
    getHomeData();
    // cmsIndexTendency("0", mChart, merFormData);
    // cmsIndexTendency("1", vChart, vipFormData);
    // cmsIndexTendency("2", gChart, gasFormData);
  }, []);

  const onChange = (index: string) => {
    setGasActive(index);
    gasChart.setOption({
      xAxis: {
        data: gasData.voList.map((i: { date: string }) => i.date),
      },
      yAxis: {},
      series: [
        {
          data: gasData.voList.map(
            (i: { amount: number; orderCount: number; userCount: number }) => {
              return index === "0"
                ? i.amount
                : index === "1"
                ? i.orderCount
                : i.userCount;
            }
          ),
          type: "line",
          areaStyle: {},
          smooth: true,
        },
      ],
    });
  };

  const onChangeMer = (index: string) => {
    setMerActive(index);
    merChart.setOption({
      xAxis: {
        data: merData.voList.map((i: { date: string }) => i.date),
      },
      yAxis: {},
      series: [
        {
          data: merData.voList.map(
            (i: { amount: number; orderCount: number; userCount: number }) => {
              return index === "0"
                ? i.amount
                : index === "1"
                ? i.orderCount
                : i.userCount;
            }
          ),
          type: "line",
          areaStyle: {},
          smooth: true,
        },
      ],
    });
  };
  const vipOnChange = (index: string) => {
    setVipActive(index);
    vipChart.setOption({
      xAxis: {
        data: merData.voList.map((i: { date: string }) => i.date),
      },
      yAxis: {},
      series: [
        {
          data: merData.voList.map(
            (i: { amount: number; orderCount: number; userCount: number }) => {
              return index === "0"
                ? i.amount
                : index === "1"
                ? i.orderCount
                : i.userCount;
            }
          ),
          type: "line",
          areaStyle: {},
          smooth: true,
        },
      ],
    });
  };

  const getHomeData = async () => {
    try {
      // const { data } = await getCmsIndexVo();
      const { data: statisticsData } = await statistics("0");
      const { data: weekData } = await statistics("1");
      const { data: monthData } = await statistics("2");

      console.log(statisticsData, 'datadata');
      
      setHomePageData(statisticsData);
      setWeekData(weekData);
      setMonthData(monthData);
    } catch (error: any) {
      if (error.status === 403) {
        setShowData(true);
      }
    }
  };

  const cmsIndexTendency = async (
    orderType: string,
    chat: any,
    params: any
  ) => {
    try {
      const { data } = await getCmsIndexTendency({
        orderType,
        ...params,
      });
      switch (orderType) {
        case "0":
          setMerData(data);
          break;
        case "1":
          setVipData(data);
          break;
        case "2":
          setGasData(data);
          break;
        default:
          break;
      }
      chat.setOption({
        xAxis: {
          data: data.voList.map((i) => i.date),
        },
        yAxis: {},
        series: [
          {
            data: data.voList.map((i) => i.amount),
            type: "line",
            areaStyle: {},
            smooth: true,
          },
        ],
      });
    } catch (error) {}
  };

  const merBlur = (values: any) => {
    const startDate = dayjs(values[0]).format("YYYY-MM-DD 00:00:00");
    const endDate = dayjs(values[1]).format("YYYY-MM-DD 23:59:59");
    setMerFormData({
      ...merFormData,
      startDate,
      endDate,
    });
    cmsIndexTendency("0", merChart, { ...merFormData, startDate, endDate });
  };
  const vipBlur = (values: any) => {
    const startDate = dayjs(values[0]).format("YYYY-MM-DD 00:00:00");
    const endDate = dayjs(values[1]).format("YYYY-MM-DD 23:59:59");
    setVipFormData({
      ...vipFormData,
      startDate,
      endDate,
    });
    cmsIndexTendency("1", vipChart, { ...vipFormData, startDate, endDate });
  };
  const gasBlur = (values: any) => {
    const startDate = dayjs(values[0]).format("YYYY-MM-DD 00:00:00");
    const endDate = dayjs(values[1]).format("YYYY-MM-DD 23:59:59");
    setGasFormData({
      ...gasFormData,
      startDate,
      endDate,
    });
    cmsIndexTendency("2", gasChart, { ...gasFormData, startDate, endDate });
  };

  const activeChange = (i: number, orderType: string) => {
    switch (orderType) {
      case "0":
        setMerFormData({
          ...merFormData,
          dateType: String(i),
        });
        cmsIndexTendency("0", merChart, {
          ...merFormData,
          dateType: String(i),
        });

        break;
      case "1":
        setVipFormData({
          ...vipFormData,
          dateType: String(i),
        });
        cmsIndexTendency("1", vipChart, {
          ...vipFormData,
          dateType: String(i),
        });

        break;
      case "2":
        setGasFormData({
          ...gasFormData,
          dateType: String(i),
        });
        cmsIndexTendency("2", gasChart, {
          ...gasFormData,
          dateType: String(i),
        });
        break;

      default:
        break;
    }
  };

  return showData ? (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 24,
      }}
    >
      欢迎使用友电之星管理后台
    </div>
  ) : (
    <div>
      <div className={styles.home}>
        <div className={styles.home_body}>
          <div className={styles.home_title}>
            当日数据 <span></span>
          </div>
          <Row gutter={[8, 16]}>
            {TimeDataList.map((v, i) => (
              <Col span={4} key={i}>
                <Card bordered={false}>
                  <div className={styles.home_body_col}>
                    <span>{TimeData[v]}</span>
                    <span className={styles.col_price}>
                      {homePageData?.[v] || 0}
                    </span>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
          <div className={styles.home_title} style={{marginTop: 20}}>
            本周数据 <span></span>
          </div>
          <Row gutter={[8, 16]}>
            {TimeDataList.map((v, i) => (
              <Col span={4} key={i}>
                <Card bordered={false}>
                  <div className={styles.home_body_col}>
                    <span>{TimeData[v]}</span>
                    <span className={styles.col_price}>
                      {weekData?.[v] || 0}
                    </span>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
          <div className={styles.home_title} style={{marginTop: 20}}>
            本月数据 <span></span>
          </div>
          <Row gutter={[8, 16]}>
            {TimeDataList.map((v, i) => (
              <Col span={4} key={i}>
                <Card bordered={false}>
                  <div className={styles.home_body_col}>
                    <span>{TimeData[v]}</span>
                    <span className={styles.col_price}>
                      {monthData?.[v] || 0}
                    </span>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
          {/* <div style={{ marginTop: 20 }} className={styles.home_title}>
            车店交易指标趋势
          </div> */}
          {/* <div className={styles.tabs_title}>
            <Tabs
              activeKey={merActive}
              defaultActiveKey="0"
              items={items}
              onChange={onChangeMer}
            ></Tabs>
            <div>
              <Space.Compact block>
                {dateList.map((i, v) => (
                  <Button
                    type={
                      merFormData.dateType === String(v) ? "primary" : "default"
                    }
                    onClick={ () => activeChange(v, "0")}
                    key={v}
                  >
                    {i}
                  </Button>
                ))}
              </Space.Compact>
            </div>
          </div> */}
          {/* <div className={styles.tabs_picker}>
            <RangePicker
              defaultValue={[
                dayjs(getFirstDay(new Date())),
                dayjs(getTimeFormat(new Date()), "YYYY-MM-DD"),
              ]}
              format="YYYY-MM-DD"
              allowClear={false}
              onChange={merBlur}
            />
            <span className={styles.span}>
              累计
              {merActive === "0"
                ? merData?.totalAmount
                : merActive === "1"
                ? merData?.totalOrderCount
                : merData?.totalUserCount}
              元
            </span>
            <span className={styles.span}>
              均值
              {merActive === "0"
                ? merData?.meanAmount
                : merActive === "1"
                ? merData?.meanOrderCount
                : merData?.meanUserCount}
              元
            </span>
          </div> */}
          {/* <div
            id="merMain"
            style={{
              width: "120%",
              height: 400,
              marginLeft: "-9%",
            }}
          ></div> */}
        </div>
      </div>

      {/* <div className={styles.home}>
        <div className={styles.home_body}>
          <div className={styles.home_title}>
            会员交易概况 <span>{time}</span>
          </div>
          <Row gutter={[8, 16]}>
            {TimeDataList.map((v, i) => (
              <Col span={4} key={i}>
                <div className={styles.home_body_col}>
                  <span>{TimeData[v]}</span>
                  <span className={styles.col_price}>
                    {homePageData?.["cmsIndexVipOrderStatisticsVo"]?.[
                      v
                    ].toLocaleString()}
                  </span>
                </div>
              </Col>
            ))}
          </Row>
          <div style={{ marginTop: 20 }} className={styles.home_title}>
            会员交易指标趋势
          </div>
          <div className={styles.tabs_title}>
            <Tabs
              defaultActiveKey="0"
              activeKey={vipActive}
              items={items}
              onChange={vipOnChange}
            ></Tabs>
            <div>
              <Space.Compact block>
                {dateList.map((i, v) => (
                  <Button
                    type={
                      vipFormData.dateType === String(v) ? "primary" : "default"
                    }
                    onClick={ () => activeChange(v, "1")}
                    key={v}
                  >
                    {i}
                  </Button>
                ))}
              </Space.Compact>
            </div>
          </div>
          <div className={styles.tabs_picker}>
            <RangePicker
              defaultValue={[
                dayjs(getFirstDay(new Date())),
                dayjs(getTimeFormat(new Date()), "YYYY-MM-DD"),
              ]}
              format="YYYY-MM-DD"
              allowClear={false}
              onChange={vipBlur}
            />
            <span className={styles.span}>
              累计
              {vipActive === "0"
                ? vipData?.totalAmount
                : vipActive === "1"
                ? vipData?.totalOrderCount
                : vipData?.totalUserCount}
              元
            </span>
            <span className={styles.span}>
              均值
              {vipActive === "0"
                ? vipData?.meanAmount
                : vipActive === "1"
                ? vipData?.meanOrderCount
                : vipData?.meanUserCount}
              元
            </span>
          </div>
          <div
            id="vipMain"
            style={{
              width: "120%",
              height: 400,
              marginLeft: "-9%",
            }}
          ></div>
        </div>
      </div>
      <div className={styles.home}>
        <div className={styles.home_body}>
          <div className={styles.home_title}>
            加油交易概况 <span>{time}</span>
          </div>
          <Row gutter={[8, 16]}>
            {TimeDataList.map((v, i) => (
              <Col span={4} key={i}>
                <div className={styles.home_body_col}>
                  <span>{TimeData[v]}</span>
                  <span className={styles.col_price}>
                    {homePageData?.["cmsIndexGasOrderStatisticsVo"]?.[
                      v
                    ].toLocaleString()}
                  </span>
                </div>
              </Col>
            ))}
          </Row>
          <div style={{ marginTop: 20 }} className={styles.home_title}>
            加油交易指标趋势
          </div>
          <div className={styles.tabs_title}>
            <Tabs
              defaultActiveKey="0"
              activeKey={gasActive}
              items={items}
              onChange={onChange}
            ></Tabs>
            <div>
              <Space.Compact block>
                {dateList.map((i, v) => (
                  <Button
                    type={
                      gasFormData.dateType === String(v) ? "primary" : "default"
                    }
                    onClick={() => activeChange(v, "2")}
                    key={v}
                  >
                    {i}
                  </Button>
                ))}
              </Space.Compact>
            </div>
          </div>
          <div className={styles.tabs_picker}>
            <RangePicker
              defaultValue={[
                dayjs(getFirstDay(new Date())),
                dayjs(getTimeFormat(new Date()), "YYYY-MM-DD"),
              ]}
              format="YYYY-MM-DD"
              allowClear={false}
              onChange={gasBlur}
            />
            <span className={styles.span}>
              累计
              {gasActive === "0"
                ? gasData?.totalAmount
                : gasActive === "1"
                ? gasData?.totalOrderCount
                : gasData?.totalUserCount}
              元
            </span>
            <span className={styles.span}>
              均值
              {gasActive === "0"
                ? gasData?.meanAmount
                : gasActive === "1"
                ? gasData?.meanOrderCount
                : gasData?.meanUserCount}
              元
            </span>
          </div>
          <div
            id="gasMain"
            style={{
              width: "120%",
              height: 400,
              marginLeft: "-9%",
            }}
          ></div>
        </div>
      </div> */}
    </div>
  );
};

export default Home;
