import React, { useEffect, useRef, useState } from "react";
import PageTab from "@/components/i-pageTab/i-pageTab";
import PageTable from "@/components/i-pageTable/i-pageTable";
import styles from "@/static/css/setting.module.css";
import {
  deptList,
  deptDelete,
  deptAdd,
  deptDetail,
  deptEdit,
} from "@/api/setting";
import { convertToTree, setIcons, showModal } from "@/utils/common";
import { Button, message, Modal, Space, Tag } from "antd";

const DivisionManager = () => {
  const [modalFormData, setModalFormData] = useState<DeptEditParams>({
    status: "0",
  });
  const [pageData, setPageData] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [titleStr, setTitleStr] = useState("添加部门");

  useEffect(() => {
    getMenuList();
  }, []);

  const getMenuList = async (deptName?: string, status?: string) => {
    try {
      const { data } = await deptList({ status, deptName });
      const res = convertToTree(data, {
        id: "deptId",
        name: "deptName",
        parentId: "parentId",
      });
      setPageData(res);
    } catch (error) {}
  };

  const handleEdit = async (val: number) => {
    try {
      const { data } = await deptDetail(val);
      setTitleStr("编辑部门");
      setOpen(true);
      setModalFormData(data);
    } catch (error) {}
  };

  const handleAdd = (val?: number) => {
    setOpen(true);
    setTitleStr("添加部门");
    setModalFormData({ deptId: val, status: "0" });
  };

  const resetForm = () => {
    setModalFormData({
      status: "0",
    });
    setOpen(false);
  };

  const handleDelete = async (val: number) => {
    const data = await showModal({
      title: "系统提示",
      content: <div>是否确认删除选中的数据项？</div>,
    });
    if (data === "ok") {
      try {
        await deptDelete(val);
        message.success("删除成功");
        getMenuList();
      } catch (error) {}
    }
  };

  const addUser = async (val: { deptId?: number; parentId?: number }) => {
    const data = val;
    try {
      if (titleStr === "编辑部门") {
        await deptEdit(data);
        message.success("编辑成功");
      } else {
        data["parentId"] = val.deptId;
        delete data.deptId;
        await deptAdd(data);
        message.success("新增成功");
      }
      resetForm();
      getMenuList();
    } catch (error) {}
  };

  const search = (val: { deptName?: string; status?: string }) => {
    getMenuList(val.deptName, val.status);
  };

  const reset = () => {
    getMenuList()
  };

  const formTab = [
    {
      label: "部门名称",
      name: "deptName",
      type: "input",
    },
    {
      label: "状态",
      name: "status",
      type: "select",
      options: [
        { value: "0", label: "正常" },
        { value: "1", label: "停用" },
      ],
    },
  ];

  const columns = [
    {
      title: "部门名称",
      dataIndex: "deptName",
      key: "deptName",
    },
    {
      title: "排序",
      dataIndex: "orderNum",
      key: "orderNum",
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (val: string) => (
        <Tag color="blue">{val === "0" ? "正常" : "停用"}</Tag>
      ),
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "操作",
      dataIndex: "deptId",
      key: "deptId",
      fixed: "right",
      width: "150px",
      render: (val: number, rows: any) => (
        <Space>
          <Button
            size="small"
            icon={setIcons("EditOutlined")}
            type="text"
            onClick={() => handleEdit(val)}
          >
            修改
          </Button>
          <Button
            size="small"
            icon={setIcons("PlusOutlined")}
            type="text"
            onClick={() => handleAdd(val)}
          >
            新增
          </Button>
          <Button
            size="small"
            onClick={() => handleDelete(val)}
            icon={setIcons("DeleteOutlined")}
            type="text"
          >
            删除
          </Button>
        </Space>
      ),
    },
  ];

  const footer = [
    {
      title: "新增",
      icon: setIcons("PlusOutlined"),
      onClick: () => {
        handleAdd();
      },
    },
  ];

  const modalForm = [
    {
      label: "上级部门",
      name: "deptId",
      type: "treeSelect",
      rules: [{ required: true, message: "请选择上级部门" }],
      params: {
        // defaultValue: 0,
        placeholder: "请选择",
        disabled: !!modalFormData.deptId,
        treeData: pageData,
        fieldNames: {
          label: "deptName",
          value: "deptId",
          children: "children",
        },
      },
      width: "100%",
      notShow: titleStr === "编辑部门",
    },
    {
      label: "部门名称",
      name: "deptName",
      type: "input",
      rules: [{ required: true, message: "请填写部门名称" }],
    },
    {
      label: "显示排序",
      name: "orderNum",
      type: "input",
      params: {
        type: "number",
      },
      rules: [{ required: true, message: "请填写排序" }],
    },
    {
      label: "负责人",
      name: "leader",
      type: "input",
    },
    {
      label: "联系电话",
      name: "phone",
      type: "input",
    },
    {
      label: "邮箱",
      name: "email",
      type: "input",
    },
    {
      label: "部门状态",
      name: "status",
      type: "radio",
      options: [
        { value: "0", label: "正常" },
        { value: "1", label: "停用" },
      ],
    },
  ];

  return (
    <div>
      <Modal
        open={open}
        title={titleStr}
        footer={<></>}
        width="32%"
        destroyOnClose
        onCancel={resetForm}
      >
        <PageTab
          subFoot
          search={addUser}
          reset={resetForm}
          formTab={modalForm}
          initialValues={modalFormData}
        ></PageTab>
      </Modal>
      <PageTab
        search={search}
        reset={reset}
        formTab={formTab}
        footer={footer}
      ></PageTab>
      <PageTable rowKey="deptId" columns={columns} data={pageData}></PageTable>
    </div>
  );
};

export default DivisionManager;
