import React, { useEffect, useRef, useState } from "react";
import PageTab from "@/components/i-pageTab/i-pageTab";
import PageTable from "@/components/i-pageTable/i-pageTable";
import { appMenuList, pageHomeAdd } from "@/api/operation";
import { Button, message, Modal, Space, Tag } from "antd";
import { customRequest, setIcons, normFile } from "@/utils/common";
import { useSelector, useDispatch, useStore } from "react-redux";
import { dictInfoAsync } from "@/stores/dictInfo";

const urlTypeList = [
  { label: "内部", value: "0" },
  { label: "h5", value: "1" },
  { label: "小程序", value: "2" },
];

const AppUserManager = () => {
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState<GetCmsUserCenterPageRes["rows"]>([]);
  const [mainPicUploadProgress, setMainPicUploadProgress] = useState(false);

  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalFormData, setModalFormData] = useState<pageHomeAddParams>({
    status: "0",
  });

  const [formData, setFormData] = useState<GetCmsUserCenterPageParams>({
    content: "",
    menuName: "",
    status: "",
    ypageNum: 1,
    ypageSize: 10,
    type: "",
    urlType: "",
  });
  const modalFormTab = [
    {
      label: "图标",
      name: "url",
      type: "upload",
      rules: [{ required: true, message: "请上传图标" }],
      labelCol: { span: 0, offset: 3 },
      getValueFromEvent: normFile,
      params: {
        maxCount: 1,
        beforeUpload: () => {
          setMainPicUploadProgress(true);
        },
        uploadMessage: mainPicUploadProgress ? (
          <div>
            {setIcons("LoadingOutlined")}
            <div>上传中...</div>
          </div>
        ) : (
          <div>
            {setIcons("PlusOutlined")}
            <div style={{ marginTop: 8 }}>上传</div>
          </div>
        ),
        customRequest: async (e: any) => {
          try {
            const data = await customRequest(e, modalFormData.url as any[]);
            setModalFormData({
              ...modalFormData,
              url: data as any,
            });
          } catch (error) {
          } finally {
            setMainPicUploadProgress(false);
          }
        },
        onRemove: () => {
          setModalFormData({
            ...modalFormData,
            url: [],
          });
        },
      },
    },
    {
      label: "标题",
      name: "title",
      type: "input",
      rules: [{ required: true, message: "请输入标题" }],
      labelCol: { span: 0, offset: 3 },
    },
    {
      label: "跳转地址",
      name: "turl",
      type: "input",
      rules: [{ required: true, message: "请输入跳转地址" }],
    },
    {
      label: "是否展示",
      name: "status",
      type: "radio",
      labelCol: { span: 0, offset: 1 },
      options: [
        {
          label: "展示",
          value: "0",
        },
        {
          label: "不展示",
          value: "1",
        },
      ],
    },
    {
      label: "链接类型",
      name: "systemType",
      type: "select",
      rules: [{ required: true, message: "请选择链接类型" }],
      options: urlTypeList,
    },
  ];
  const formTab = [
    {
      label: "菜单名",
      name: "menuName",
      type: "input",
    },
    {
      label: "菜单描述",
      name: "content",
      type: "input",
    },
    {
      label: "状态",
      name: "status",
      type: "select",
      options: [
        {
          label: "正常",
          value: "0",
        },
        {
          label: "禁用",
          value: "1",
        },
      ],
    },
    {
      label: "链接类型",
      name: "urlType",
      type: "select",
      options: urlTypeList,
    },
  ];

  const footer = [
    {
      title: "新增",
      icon: setIcons("PlusOutlined"),
      onClick: () => setOpen(true),
    },
  ];

  const columns = [
    {
      title: "序号",
      dataIndex: "sort",
      key: "sort",
      fixed: "left",
    },
    {
      title: "图标",
      dataIndex: "url",
      key: "url",
      fixed: "left",
      render: (data: string) => {
        return <img style={{ height: "32px" }} src={data} alt="" />;
      },
    },
    {
      title: "菜单名",
      dataIndex: "menuName",
      key: "menuName",
      fixed: "left",
    },
    {
      title: "菜单描述",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "链接",
      dataIndex: "menuUrl",
      key: "menuUrl",
    },
    {
      title: "小程序ID",
      dataIndex: "miniProgramId",
      key: "miniProgramId",
    },
    {
      title: "链接类型",
      dataIndex: "urlType",
      key: "urlType",
      render: (val: string) => (
        <span>{urlTypeList.find((i) => i.value === val)?.label}</span>
      ),
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (val: string) => (
        <Tag color="blue">{val === "0" ? "正常" : "禁用"}</Tag>
      ),
    },
    {
      title: "类型",
      dataIndex: "type",
      key: "type",
      // render: (val: string) =>
      //   val ? (
      //     <Tag color="blue">
      //       {
      //         app_store_type?.find(
      //           (i: { value: string }) => i.value === String(val)
      //         )?.label
      //       }
      //     </Tag>
      //   ) : (
      //     ""
      //   ),
    },
    {
      title: "权重",
      dataIndex: "level",
      key: "level",
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      width: "120px",
      render: (val: string, rows: any) => (
        <Space>
          <Button
            size="small"
            icon={setIcons("EditOutlined")}
            type="text"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            修改
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await appMenuList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const add = () => {};

  const search = (val: any) => {
    setFormData({
      ...formData,
      ...val,
      ypageNum: 1,
    });
  };

  const reset = () => {
    setFormData({
      content: "",
      menuName: "",
      status: "",
      ypageNum: 0,
      ypageSize: 0,
      type: "",
      urlType: "",
    });
  };
  const resetModal = () => {
    setModalFormData({
      status: "0",
    });
    setOpen(false);
  };

  const confirmSend = async (val: any) => {
    console.log(val, "valvalval");
    try {
      await pageHomeAdd({ ...val, url: val.fileList[0].url });
      message.success("新增成功");
      resetModal();
      getPageData();
    } catch (error) {}
  };

  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };
  return (
    <div>
      <Modal
        destroyOnClose
        open={open}
        title="新增图标"
        width="24%"
        footer={<></>}
        onCancel={resetModal}
      >
        <PageTab
          subFoot
          search={confirmSend}
          reset={resetModal}
          formTab={modalFormTab}
          initialValues={modalFormData}
        ></PageTab>
      </Modal>
      <PageTab
        search={search}
        reset={reset}
        formTab={formTab}
        footer={footer}
      ></PageTab>
      <PageTable
        loading={loading}
        pageChange={pageChange}
        size="small"
        rowKey="id"
        columns={columns}
        data={pageData}
        pagination={{
          current: formData.ypageNum!,
          pageSize: formData.ypageSize!,
          total: total,
        }}
      ></PageTable>
    </div>
  );
};

export default AppUserManager;
