import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getInfo } from "@/api/index";

const initialState = {
  value: {
    user: {}
  },
};

export const userIno = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    getUserInfo: (state: any) => {
      return state?.userIno.value;
    },
    setUserInfo: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setUserInfo, getUserInfo } = userIno.actions;
export const userInfoAsync = createAsyncThunk(
  "data/userInfoAsync",
  async (amount, action) => {
    try {
      const data = await getInfo();
      action.dispatch(setUserInfo(data));
    } catch (error) {}
  }
);
export default userIno.reducer;
