import React, { useEffect, useRef, useState } from "react";
import PageTable from "@/components/i-pageTable/i-pageTable";

import { Tabs, TabsProps, Tag } from "antd";
import { setIcons, showModal } from "@/utils/common";
import { useSelector, useDispatch, useStore } from "react-redux";
import {
  getOperationIncomeData,
  getOperationStoreCountData,
  getOperationUserCountData,
} from "@/api/operation";
import Store_detail from "./children/store_detail";
import Vip_detail from "./children/vip_detail";
import Oil_detail from "./children/oil_detail";

const BusinessData = () => {
  const dispatch = useDispatch();

  const [srPageData, setSrPageData] = useState<any>([]);
  const [mdPageData, setMdPageData] = useState<any>([]);
  const [yhPageData, setYhPageData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "门店收入明细",
      children: Store_detail(),
    },
    {
      key: "2",
      label: "VIP收入明细",
      children: Vip_detail(),
    },
    {
      key: "3",
      label: "机油收入明细",
      children: Oil_detail(),
    },
  ];

  const columns = [
    {
      title: "昨日新增",
      dataIndex: "lastDayIncrement",
      key: "lastDayIncrement",
    },
    {
      title: "本周新增",
      dataIndex: "currentWeekIncrement",
      key: "currentWeekIncrement",
    },
    {
      title: "本月新增",
      dataIndex: "currentMonthIncrement",
      key: "currentMonthIncrement",
    },
    {
      title: "上周新增",
      dataIndex: "lastWeekIncrement",
      key: "lastWeekIncrement",
    },
    {
      title: "上月新增",
      dataIndex: "lastMonthIncrement",
      key: "lastMonthIncrement",
    },
    {
      title: "累计总数",
      dataIndex: "totalIncrement",
      key: "totalIncrement",
    },
  ];

  useEffect(() => {
    getDataAll();
  }, []);

  const onChange = (key: string) => {
    console.log(key, "  children    ");
  };

  const getDataAll = async () => {
    setLoading(true);
    try {
      const [sr, md, yh] = await Promise.all([
        getOperationIncomeData(),
        getOperationStoreCountData(),
        getOperationUserCountData(),
      ]);
      setSrPageData([sr.data]);
      setMdPageData([md.data]);
      setYhPageData([yh.data]);
      // console.log(data, "dddddddd");
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div
        style={{
          fontSize: "14px",
          marginBottom: "12px",
          color: " #f59a23",
          background: "rgba(245,154,35,.2)",
          borderRadius: 4,
          padding: " 5px 20px",
        }}
      >
        所有数据统计均截止至昨日24:00
      </div>
      <div
        style={{
          boxShadow: "1px 1px 6px #efefef",
          padding: 10,
          borderRadius: 4,
          marginBottom: 18,
        }}
      >
        <div style={{ marginBottom: 12, fontSize: 16, fontWeight: 600 }}>
          收入统计数据
        </div>
        <PageTable
          loading={loading}
          size="small"
          rowKey="totalIncrement"
          columns={columns}
          data={srPageData}
        ></PageTable>
      </div>
      <div
        style={{
          boxShadow: "1px 1px 6px #efefef",
          padding: 10,
          borderRadius: 4,
          marginBottom: 18,
        }}
      >
        <div style={{ marginBottom: 12, fontSize: 16, fontWeight: 600 }}>
          门店统计数据
        </div>
        <PageTable
          loading={loading}
          size="small"
          rowKey="totalIncrement"
          columns={columns}
          data={mdPageData}
        ></PageTable>
      </div>
      <div
        style={{
          boxShadow: "1px 1px 6px #efefef",
          padding: 10,
          borderRadius: 4,
          marginBottom: 18,
        }}
      >
        <div style={{ marginBottom: 12, fontSize: 16, fontWeight: 600 }}>
          用户统计数据
        </div>
        <PageTable
          loading={loading}
          size="small"
          rowKey="totalIncrement"
          columns={columns}
          data={yhPageData}
        ></PageTable>
      </div>
      <div
        style={{
          boxShadow: "1px 1px 6px #efefef",
          padding: 10,
          borderRadius: 4,
          marginBottom: 18,
        }}
      >
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
    </div>
  );
};

export default BusinessData;
