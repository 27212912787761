export const phoneReg = /^1[3-9]\d{9}$/
export const passwordReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/
export const smsCodeReg = /^([0-9]){6}$/
export const idCardReg = /^([0-9]|X){15,18}$/
export const nameReg = /^[\u4E00-\u9FA5]+([\u00B7]?[\u4E00-\u9FA5]+)*$/
export const bankReg = /^\d{16,19}$/
export const addressReg = /^\S{3,50}$/
export const oneNumber = /^\d+(\.\d{1,2})?$/
export const spaceReg = /\s/
export const decimalReg = /^(\+?[1-9]\d*)+(\.[0-9]{1,2})?$/
export const integerReg = /^\+?[1-9]\d*$/

