import React from "react";
import { setIcons } from "./common";
const getIconList = [
  {
    label: React.createElement("div", {
      children: [setIcons("MenuFoldOutlined"), " MenuFold"],
    }),
    value: "MenuFoldOutlined",
    key: "MenuFoldOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("MenuUnfoldOutlined"), " MenuUnfold"],
    }),
    value: "MenuUnfoldOutlined",
    key: "MenuUnfoldOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("FundOutlined"), " Fund"],
    }),
    value: "FundOutlined",
    key: "FundOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("AppstoreOutlined"), " Appstore"],
    }),
    value: "AppstoreOutlined",
    key: "AppstoreOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("BarsOutlined"), " Bars"],
    }),
    value: "BarsOutlined",
    key: "BarsOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("InsertRowAboveOutlined"), " InsertRowAbove"],
    }),
    value: "InsertRowAboveOutlined",
    key: "InsertRowAboveOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("SettingOutlined"), " Setting"],
    }),
    value: "SettingOutlined",
    key: "SettingOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("UserOutlined"), " UserOut"],
    }),
    value: "UserOutlined",
    key: "UserOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("ClusterOutlined"), " Cluster"],
    }),
    value: "ClusterOutlined",
    key: "ClusterOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("TeamOutlined"), " Team"],
    }),
    value: "TeamOutlined",
    key: "TeamOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("PartitionOutlined"), " Partition"],
    }),
    value: "PartitionOutlined",
    key: "PartitionOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("ToolOutlined"), " Tool"],
    }),
    value: "ToolOutlined",
    key: "ToolOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("ShopOutlined"), " Shop"],
    }),
    value: "ShopOutlined",
    key: "ShopOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("ShoppingOutlined"), " Shopping"],
    }),
    value: "ShoppingOutlined",
    key: "ShoppingOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("ScheduleOutlined"), " Schedule"],
    }),
    value: "ScheduleOutlined",
    key: "ScheduleOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("InsertRowBelowOutlined"), " InsertRowBelow"],
    }),
    value: "InsertRowBelowOutlined",
    key: "InsertRowBelowOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("BlockOutlined"), " Block"],
    }),
    value: "BlockOutlined",
    key: "BlockOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("DollarOutlined"), " Dollar"],
    }),
    value: "DollarOutlined",
    key: "DollarOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("MergeCellsOutlined"), " MergeCells"],
    }),
    value: "MergeCellsOutlined",
    key: "MergeCellsOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("FileSearchOutlined"), " FileSearch"],
    }),
    value: "FileSearchOutlined",
    key: "FileSearchOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("MedicineBoxOutlined"), " MedicineBox"],
    }),
    value: "MedicineBoxOutlined",
    key: "MedicineBoxOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("ShoppingCartOutlined"), " ShoppingCart"],
    }),
    value: "ShoppingCartOutlined",
    key: "ShoppingCartOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("WalletOutlined"), " Wallet"],
    }),
    value: "WalletOutlined",
    key: "WalletOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("SecurityScanOutlined"), " SecurityScan"],
    }),
    value: "SecurityScanOutlined",
    key: "SecurityScanOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("ReadOutlined"), " Read"],
    }),
    value: "ReadOutlined",
    key: "ReadOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("AppstoreAddOutlined"), " AppstoreAdd"],
    }),
    value: "AppstoreAddOutlined",
    key: "AppstoreAddOutlined",
  },
  {
    label: React.createElement("div", {
      children: [setIcons("NodeIndexOutlined"), " NodeIndex"],
    }),
    value: "NodeIndexOutlined",
    key: "NodeIndexOutlined",
  },
];

export default getIconList;
