import React, { useState } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import axios from "./utils/request";
import AppLayout from "./layout/layout";
import { getStorage } from "./utils/common";
import Login from "./pages/views/login";

const location = window.location;

const App: React.FC = function App() {
  // console.log(location.hash, "locationlocation");

  return (
    <div className="App">
      <ConfigProvider locale={zhCN}>
        <Router>
          {/login/.test(location.hash) && !getStorage('Authorization') ? (
            <Routes>
              <Route path="/login" element={<Login />} />
            </Routes>
          ) : (
            <AppLayout></AppLayout>
          )}
        </Router>
      </ConfigProvider>
    </div>
  );
};

export default App;
