import React, { useEffect, useState } from "react";
import styles from "../../static/css/index.module.css";
import { loginIn, getCaptchaImage } from "../../api";
import {
  LockOutlined,
  UserOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Form, Input, message, Select, Space } from "antd";

import { Card } from "antd";
import { useNavigate } from "react-router-dom";
import { getStorage, setStorage } from "../../utils/common";

const Login = () => {
  const [codeImg, setCodeImg] = useState("");
  const [uuid, setUuid] = useState("");
  const [form] = Form.useForm();
  const [memberList, setMemberList] = useState([]);
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    getCode();
    const memberUserList = getStorage("memberUserList") || [] as any;
    if (memberUserList?.length) {
      setIsLogin(true);
      setMemberList(memberUserList);
      form.setFieldValue("userCode", memberUserList[0].value);
    }
  }, []);
  const navigate = useNavigate();
  const onFinish = async (values: {
    username: string;
    password: string;
    code: string;
    remember: boolean;
    userCode: string;
  }) => {
    try {
      const memberUserList = getStorage("memberUserList") || [] as any;

      if (!isLogin) {
        const { token } = await loginIn({ ...values, uuid });
        setStorage("Authorization", token);

        memberUserList &&
        memberUserList?.some((item: any) => item.value === token)
          ? setStorage("memberUserList", memberUserList)
          : setStorage("memberUserList", [
              ...memberUserList,
              { label: values.username, value: token },
            ]);

            console.log(memberUserList, 'memberUserListmemberUserList');
            
      } else {
        setStorage("Authorization", values.userCode);
      }
      message.success("登录成功");
      navigate("/");
      window.location.reload();
    } catch (error) {
    }
  };

  const getCode = async () => {
    try {
      const { img, uuid } = await getCaptchaImage();
      setCodeImg(img);
      setUuid(uuid);
    } catch (error) {}
  };

  return (
    <div className={styles.login}>
      <Card>
        <div className={styles.login_title}>友电之星管理后台</div>

        <Form
          layout="vertical"
          name="normal_login"
          style={{ width: 400 }}
          className={styles.loginForm}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          form={form}
        >
          {isLogin ? (
            <>
              <Form.Item
                name="userCode"
                label="选择账号"
                rules={[{ required: true, message: "请选择您的账号" }]}
              >
                <Select options={memberList} placeholder="选择账号" />
              </Form.Item>
              <div style={{ height: "16px" }}></div>
              <Form.Item>
                <Space>
                  {" "}
                  <Button
                    type="default"
                    style={{ width: 190 }}
                    className={styles.button}
                    onClick={() => setIsLogin(false)}
                  >
                    使用其他账号
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: 190 }}
                    className={styles.button}
                  >
                    登 录
                  </Button>
                </Space>
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                name="username"
                label="账号"
                rules={[{ required: true, message: "请输入您的账号" }]}
              >
                <Input
                  className={styles.loginInput}
                  prefix={<UserOutlined className={styles.loginFormIcon} />}
                  placeholder="账号"
                />
              </Form.Item>
              <div style={{ height: "16px" }}></div>
              <Form.Item
                name="password"
                label="密码"
                rules={[{ required: true, message: "请输入您的密码" }]}
              >
                <Input
                  className={styles.loginInput}
                  prefix={<LockOutlined className={styles.loginFormIcon} />}
                  type="password"
                  placeholder="密码"
                />
              </Form.Item>
              <div style={{ height: "16px" }}></div>

              <Form.Item
                name="code"
                label="验证码"
                rules={[{ required: true, message: "请输入验证码" }]}
              >
                <div className={styles.verificationCode}>
                  <Input
                    className={styles.loginInput}
                    prefix={
                      <SafetyCertificateOutlined
                        className={styles.loginFormIcon}
                      />
                    }
                    type="text"
                    placeholder="验证码"
                  />
                  <div onClick={getCode} className={styles.code_d}>
                    <img
                      className={styles.codeValideImg}
                      src={"data:image/gif;base64," + codeImg}
                      alt="图形验证码"
                    />
                  </div>
                </div>
              </Form.Item>
              <div style={{ height: "16px" }}></div>
              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>记住我</Checkbox>
                </Form.Item>
              </Form.Item>
              <div style={{ height: "16px" }}></div>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={styles.button}
                >
                  登 录
                </Button>
              </Form.Item>
            </>
          )}
        </Form>
      </Card>
    </div>
  );
};

export default Login;
