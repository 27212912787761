import React, { useEffect, useState } from "react";
import PageTable from "@/components/i-pageTable/i-pageTable";
import PageTab from "@/components/i-pageTab/i-pageTab";
import { listReadPacket } from "@/api/order";
import { Button, message, Modal, Space, Tag } from "antd";
import { resetForm, setIcons } from "@/utils/common";
import { useSelector, useDispatch, useStore } from "react-redux";
import { dictInfoAsync } from "@/stores/dictInfo";

const UserCommission = () => {
  const dispatch = useDispatch();
  const {
    state: { brokerage_type,random_red_packet_type },
  } = useSelector((state: { dictInfo: any }) => state.dictInfo);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const [pageData, setPageData] = useState<ListReadPacketRes["rows"]>([]);

  const [formData, setFormData] = useState<ListReadPacketParams>({
    ypageNum: 1,
    ypageSize: 10,
  });
  const formTab = [
    {
      label: "订单号",
      name: "businessId",
      type: "input",
    },
    {
      label: "手机号",
      name: "mobile",
      type: "input",
    },
    {
      label: "佣金类型",
      name: "brokerageType",
      type: "select",
      options: brokerage_type,
    },
    // {
    //   label: "领取类型",
    //   name: "redPacketType",
    //   type: "select",
    //   options: random_red_packet_type,
    // },
  ];
  const columns = [
    {
      title: "订单号",
      dataIndex: "businessId",
      key: "businessId",
    },
    {
      title: "佣金类型",
      dataIndex: "brokerageType",
      key: "brokerageType",
      render: (val: string) => (
        <Tag color="processing">{brokerage_type.find((i: {value: string})=> i.value === val)?.label}</Tag>
      )
    },
    // {
    //   title: "领取类型",
    //   dataIndex: "redPacketType",
    //   key: "redPacketType",
    //   render: (val: string) => (
    //     <Tag color="processing">{random_red_packet_type.find((i: {value: string})=> i.value === val)?.label}</Tag>
    //   )
    // },
    {
      title: "佣金交易",
      dataIndex: "brokerageAmount",
      key: "brokerageAmount",
    },
    // {
    //   title: "可领取时间",
    //   dataIndex: "updateTime",
    //   key: "updateTime",
    // },
    {
      title: "领取时间",
      dataIndex: "receiveTime",
      key: "receiveTime",
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  useEffect(() => {
    !brokerage_type && dispatch(dictInfoAsync("brokerage_type") as any);
    !random_red_packet_type && dispatch(dictInfoAsync("random_red_packet_type") as any);
    
  }, []);

  const search = (val: any) => {
    setFormData({
      ...formData,
      ...val,
      ypageNum: 1,
    });
  };

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await listReadPacket(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };

  const reset = () => {
    setFormData({
      ypageNum: 1,
      ypageSize: 10,
    });
  };

  return (
    <div>
      <PageTab
        search={search}
        reset={reset}
        formTab={formTab}
        footer={[]}
      ></PageTab>
      <PageTable
        loading={loading}
        rowKey="id"
        pageChange={pageChange}
        data={pageData}
        columns={columns}
        pagination={{
          total: total,
          current: formData.ypageNum,
        }}
      ></PageTable>
    </div>
  );
};

export default UserCommission;
