import { Tag } from "antd";
import React, { useEffect, useRef, useState } from "react";
import PageTab from "@/components/i-pageTab/i-pageTab";
import PageTable from "@/components/i-pageTable/i-pageTable";

import {
  setIcons,
  showModal,
  downloadFile,
  getTimeFormat,
  getFirstDay,
} from "@/utils/common";
import {
  merOrderStatisticsList,
  merOrderStatisticsExport,
} from "@/api/statistics";

const TransferOrderManager = () => {
  const [pageData, setPageData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const transferTypeList = [
    { label: "管理员转赠", value: 0 },
    { label: "团队内转赠", value: 1 },
  ];

  const [formData, setFormData] =
    useState<SelectCmsMerOrderStatisticsPageParams>({
      startTime: getTimeFormat(getFirstDay(new Date()), "yyyy-MM-dd HH:mm:ss"),
      endTime: getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss"),
      mobile: "",
      transferMobile: "",
      transferType: '',
      ypageNum: 1,
      ypageSize: 10,
    });

  const formTab = [
    {
      label: "赠送人账号",
      name: "mobile",
      type: "input",
      width: "240px", 
    },
    {
      label: "接收人账号",
      name: "transferMobile",
      type: "input",
      width: "240px",
    },
    {
      label: "转赠类型",
      name: "transferType",
      type: "select",
      options: transferTypeList,
    },
    {
      label: "创建时间",
      name: "rangePicker",
      type: "rangePicker",
      // labelCol: { span:4, offset: 0 },
      width: "380px",
    },
  ];

  const footer = [
    {
      title: "导出",
      icon: setIcons("DownloadOutlined"),
      onClick: () => exportExcel(),
      loading: exportLoading,
    },
  ];

  const columns = [
    {
      title: "赠送人账号",
      dataIndex: "mobile",
      key: "mobile",
    },

    {
      title: "赠送人昵称",
      dataIndex: "nickName",
      key: "nickName",
    },
    {
      title: "接收人账号",
      dataIndex: "transferMobile",
      key: "transferMobile",
    },
    {
      title: "接收人昵称",
      dataIndex: "transferNickName",
      key: "transferNickName",
    },
    {
      title: "转赠金额",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "转赠服务费-百分比",
      dataIndex: "serviceCharge",
      key: "serviceCharge",
    },
    {
      title: "转赠类型",
      dataIndex: "transferType",
      key: "transferType",
      render: (text: number) => transferTypeList.find(item => item.value === text)?.label,
    },

    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "描述",
      dataIndex: "remark",
      key: "remark",
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await merOrderStatisticsList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const exportExcel = async () => {
    setExportLoading(true);
    try {
      const data = await merOrderStatisticsExport(formData);
      downloadFile(
        data as any,
        "门店订单统计" + getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss")
      );
    } catch (error) {
    } finally {
      setExportLoading(false);
    }
  };
  const search = (val: any) => {
    setFormData({
      ...formData,
      ...val,
      ypageNum: 1,
    });
  };

  const reset = () => {
    setFormData({
      startTime: getTimeFormat(getFirstDay(new Date()), "yyyy-MM-dd HH:mm:ss"),
      endTime: getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss"),
      mobile: "",
      transferMobile: "",
      transferType: "",
      ypageNum: 1,
      ypageSize: 10,
    });
  };

  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };
  return (
    <div>
      <PageTab
        search={search}
        reset={reset}
        formTab={formTab}
        footer={[]}
      ></PageTab>
      <PageTable
        loading={loading}
        pageChange={pageChange}
        rowKey="id"
        columns={columns}
        data={pageData}
        pagination={{
          current: formData.ypageNum!,
          pageSize: formData.ypageSize!,
          total: total,
        }}
      ></PageTable>
    </div>
  );
};

export default TransferOrderManager;
