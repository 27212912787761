import { Button, Col, Row, Space, Image } from "antd";

const IForm = (props: { formTab: any[] }) => {
  const { formTab } = props;
  return (
    <Row wrap gutter={[12, 18]} justify="start">
      {formTab?.map((item) => (
        <Col span={item?.span || 12} key={item.label}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                textAlign: "right",
                fontWeight: "600",
                whiteSpace: "nowrap",
              }}
            >
              {item.label}：
            </div>
            {item?.type === "image" ? (
              <Image style={item.style} src={item.name}></Image>
            ) : item?.type === "imageList" ? (
              <Space wrap>
                {item.name.map((i: any, index: number) => (
                  <Image style={item.style} src={i} key={index}></Image>
                ))}
              </Space>
            ) : (
              <div style={{ width: "70%" }}>{item.name || "-"}</div>
            )}
          </div>
        </Col>
      ))}
    </Row>
  );
};
export default IForm;
