import http from "../utils/request";

// 用户分页查询
export const userList = (params: defaultParams): Promise<any> => {
  return http.post("/system/user/list", params);
};

// 获取部门树
export const deptTree = (): Promise<any> => {
  return http.post("/system/user/deptTree", {});
};
// 菜单Tree
export const treeSelect = (): Promise<Record<string, any>> => {
  return http.get("/system/menu/treeselect", {});
};
// 角色对应菜单Tree
export const roleMenuTreeSelect = (
  roleId: number
): Promise<Record<string, any>> => {
  return http.get(`/system/menu/roleMenuTreeselect/${roleId}`);
};
// 获取对应角色部门树列表
export const roleDeptTree = (roleId: number): Promise<Record<string, any>> => {
  return http.get(`/system/role/deptTree/${roleId}`);
};

// 新增用户

export const userAdd = (params: AddParams): Promise<defaultRes> => {
  return http.post(`/system/user`, params);
};
// 修改用户状态
export const changeUserStatus = (params: {
  userId: string | number;
  status: "0" | "1";
}): Promise<defaultRes> => {
  return http.post(`/system/user/changeStatus`, params);
};
// 删除用户
export const deleteUser = (userIds: number[] | number): Promise<defaultRes> => {
  return http.delete(`/system/user/${userIds}`);
};
// 用户详情
export const getUser = (
  userId: string | number
): Promise<Record<string, any>> => {
  return http.get(`/system/user/${userId}`);
};

// 修改用户
export const editUser = (params: AddParams): Promise<defaultRes> => {
  return http.put(`/system/user`, params);
};
// 获取授权角色
export const getAuthRole = (
  userId: string | number
): Promise<Record<string, any>> => {
  return http.get(`/system/user/authRole/${userId}`);
};
// 用户授权角色
export const authRole = (params: {
  roleIds: number[];
  userId: number;
}): Promise<defaultRes> => {
  return http.put(`/system/user/authRole`, params);
};
// 用户新增获取角色与部门下拉
export const getRolesAndPosts = (): Promise<Record<string, any>> => {
  return http.get(`/system/user/getRolesAndPosts`);
};

// 重置密码
export const resetPwd = (params: {
  password: string;
  userId: number;
}): Promise<defaultRes> => {
  return http.put(`/system/user/resetPwd`, params);
};

// 菜单列表
export const menuList = (params: {
  status?: string;
  menuName?: string;
}): Promise<Record<string, any>> => {
  return http.post(`/system/menu/list`, params);
};

// 菜单编辑
export const menuEdit = (params: EditParams): Promise<defaultRes> => {
  return http.put(`/system/menu`, params);
};
// 菜单详情
export const menuDetail = (menuId: number): Promise<{ data: EditParams }> => {
  return http.get(`/system/menu/${menuId}`);
};
// 添加菜单
export const menuAdd = (
  params: MenuAddParams
): Promise<{ data: defaultRes }> => {
  return http.post(`/system/menu`, params);
};
// 菜单删除
export const menuDelete = (menuId: number): Promise<{ data: defaultRes }> => {
  return http.delete(`/system/menu/delete/${menuId}`);
};
// 角色列表
export const roleList = (prams: any): Promise<ListRes> => {
  return http.post(`/system/role/list`, prams);
};

// 修改角色状态
export const roleChangeStatus = (prams: {
  roleId: number;
  status: string;
}): Promise<defaultRes> => {
  return http.post(`/system/role/changeStatus`, prams);
};

// 添加角色
export const roleAdd = (prams: RoleAddParams): Promise<defaultRes> => {
  return http.post(`/system/role`, prams);
};
// 修改角色
export const roleEdit = (prams: RoleAddParams): Promise<defaultRes> => {
  return http.put(`/system/role`, prams);
};

// 角色详情
export const roleDetail = (roleId: number): Promise<RoleGetInfoRes> => {
  return http.get(`/system/role/${roleId}`);
};
// 角色删除
export const roleDelete = (
  roleIds: number | number[]
): Promise<RoleGetInfoRes> => {
  return http.delete(`/system/role/${roleIds}`);
};
// 修改角色
export const dataScope = (params: DataScopeParams): Promise<defaultRes> => {
  return http.put(`/system/role/dataScope`, params);
};
// 取消授权用户
export const authUserCancel = (params: {
  roleId: number;
  userId: number;
}): Promise<defaultRes> => {
  return http.put(`/system/role/authUser/cancel`, params);
};

// 查询已分配用户角色列表--分页
export const allocatedList = (params: {
  phoneNumber?: string;
  userName?: string;
  roleId?: number;
  ypageNum?: number;
}): Promise<Record<string, any>> => {
  return http.post(`/system/role/authUser/allocatedList`, params);
};
// 查询未分配用户角色列表--分页
export const unallocatedList = (params: {
  phoneNumber?: string;
  userName?: string;
  roleId?: number;
  ypageNum?: number;
}): Promise<Record<string, any>> => {
  return http.post(`/system/role/authUser/unallocatedList`, params);
};
// 批量选择用户授权
export const selectAll = (params: {
  roleId?: number;
  userIds?: any;
}): Promise<defaultRes> => {
  return http.put(`/system/role/authUser/selectAll?roleId=${params.roleId}&userIds=${params.userIds}`);
};

// 部门列表查询
export const deptList = (prams: {
  status?: string;
  deptName?: string;
}): Promise<Record<string, any>> => {
  return http.post(`/system/dept/list`, prams);
};

// 部门删除
export const deptDelete = (
  deptId: number | number[]
): Promise<Record<string, any>> => {
  return http.delete(`/system/dept/${deptId}`);
};
// 部门添加
export const deptAdd = (params: DeptAddParams): Promise<defaultRes> => {
  return http.post(`/system/dept`, params);
};

// 部门详情
export const deptDetail = (deptId: number): Promise<Record<string, any>> => {
  return http.get(`/system/dept/${deptId}`);
};

// 部门修改
export const deptEdit = (params: DeptEditParams): Promise<defaultRes> => {
  return http.put(`/system/dept`, params);
};

// 岗位分页查询
export const postList = (prams: PostListParams): Promise<PostListRes> => {
  return http.post(`/system/post/list`, prams);
};
// 岗位新增
export const postAdd = (prams: PostAddParams): Promise<defaultRes> => {
  return http.post(`/system/post/add`, prams);
};

export const postEdit = (prams: PostAddParams): Promise<defaultRes> => {
  return http.post(`/system/post/edit`, prams);
};

// 岗位详情
export const postDetail = (postIds: number): Promise<PostGetInfoRes> => {
  return http.get(`/system/post/detail/${postIds}`);
};

// 岗位删除
export const postDelete = (
  postIds: number[] | number
): Promise<PostListRes> => {
  return http.delete(`/system/post/delete/${postIds}`);
};

// 字典分页查询
export const dictList = (
  prams: any
): Promise<any> => {
  return http.post(`system/dict/data/list`, prams);
};

// 新增字典
export const dictAdd = (prams: DictAddParams): Promise<defaultRes> => {
  return http.post(`/system/dict/data`, prams);
};

// 编辑字典
export const dictEdit = (prams: DictAddParams): Promise<defaultRes> => {
  return http.put(`/system/dict/data`, prams);
};

// 字典详情
export const dictDetail = (dictTypeId: string): Promise<DictDeclare> => {
  return http.get(`/system/dict/${dictTypeId}`);
};
// 刷新字典缓存
export const refreshCache = (): Promise<defaultRes> => {
  return http.delete(`/system/dict/type/refreshCache`);
};

// 删除字典
export const dictDelete = (
  dictTypeIds: string[] | string
): Promise<defaultRes> => {
  return http.delete(`/system/dict/data/${dictTypeIds}`);
};

// 分页查询字典类型
export const dictTypeList = (
  prams: DictAddParams
): Promise<DictTypeListRes> => {
  return http.post(`/system/dict/type/list`, prams);
};

// 新增字典类型
export const dictTypeAdd = (prams: DictTypeAddParams): Promise<defaultRes> => {
  return http.post(`/system/dict/type`, prams);
};

// 编辑字典类型
export const dictTypeEdit = (prams: DictTypeAddParams): Promise<defaultRes> => {
  return http.put(`/system/dict/type`, prams);
};

// 查询字典类型详情
export const dictTypeDetail = (
  dictTypeId: string
): Promise<DictTypeGetInfoRes> => {
  return http.get(`/system/dict/type/${dictTypeId}`);
};
// 删除字典类型
export const dictTypeDelete = (
  dictTypeIds: string | string[]
): Promise<defaultRes> => {
  return http.delete(`/system/dict/type/${dictTypeIds}`);
};

// 查询字典类型基础信息---->下拉之类使用
export const dictTypeOptionSelect = (
  dictTypeName: string
): Promise<GetBaseDictTypeVosRes> => {
  return http.get(`/system/dict/type/optionselect`);
};

// 分页查询地区配置
export const areaList = (
  prams: GetCmsAreaPageParams
): Promise<GetCmsAreaPageRes> => {
  return http.post(`/system/area/list`, prams);
};

// 新增地区
export const areaAdd = (prams: AreaAddParams): Promise<defaultRes> => {
  return http.post(`/system/area/add`, prams);
};
// 修改地区
export const areaEdit = (prams: AreaAddParams): Promise<defaultRes> => {
  return http.post(`/system/area/edit`, prams);
};

// 删除地区
export const areaDelete = (
  dictTypeIds: string | string[]
): Promise<GetCmsAreaPageRes> => {
  return http.delete(`/system/area/${dictTypeIds}`);
};
// 刷新地区缓存
export const areaRefreshCache = (): Promise<GetCmsAreaPageRes> => {
  return http.delete(`/system/area/refreshCache`);
};

// 分页查询操作日志
export const operlogList = (params: GetCmsSysOperLogPageParams): Promise<GetCmsSysOperLogPageRes> => {
  return http.post(`/cms/auth/operlog/info/list`, params);
};

// 分页查询系统配置
export const configList = (params: GetCmsConfigPageParams): Promise<GetCmsConfigPageRes> => {
  return http.post(`/system/config/list`, params);
};

// 新增系统配置 

export const configAdd= (params: any): Promise<defaultRes> => {
  return http.post(`/system/config`, params);
};

// 编辑系统配置 

export const configEdit= (params: AddConfigParams): Promise<defaultRes> => {
  return http.put(`/system/config`, params);
};

// 刷新系统配置缓存
export const configRefreshCache= (): Promise<GetCmsAreaPageRes> => {
  return http.delete(`/system/config/refreshCache`);
};

// 删除系统配置
export const configDelete = (
  dictTypeIds: string | string[]
): Promise<defaultRes> => {
  return http.delete(`/system/config/${dictTypeIds}`);
};

// 支付配置分页列表
export const payConfigList = (params: GetPayCmsPageHomeParams): Promise<GetPayCmsPageHomeRes> => {
  return http.post(`/cms/auth/pay/config/list`, params);
};
// 新增支付配置
export const payConfigAdd = (params: AddPayParams): Promise<GetPayCmsPageHomeRes> => {
  return http.post(`/cms/auth/pay/config/add`, params);
};

// 新增支付配置
export const payConfigEdit = (params: AddPayParams): Promise<defaultRes> => {
  return http.post(`/cms/auth/pay/config/edit`, params);
};


// 分页查询协议
export const agreementList = (params: defaultParams): Promise<GetCmsAgreementPageRes> => {
  return http.post(`/cms/auth/agreement/info/list`, params);
};

// 新增协议
export const agreementAdd = (params: {
  "agreementContext": string,
  "name": string,
}): Promise<defaultRes> => {
  return http.post(`/cms/auth/agreement/info/add`, params);
}

// 编辑协议 
export const agreementEdit = (params: {
  "agreementContext": string,
  "name": string,
}) : Promise<defaultRes> => {
  return http.post(`/cms/auth/agreement/info/edit`, params);
}


