import {
  Button,
  Empty,
  Input,
  message,
  Modal,
  Space,
  Switch,
  Tree,
  Dropdown,
  MenuProps,
} from "antd";
import React, { Children, useEffect, useState } from "react";
import {
  userList,
  deptTree,
  userAdd,
  changeUserStatus,
  deleteUser,
  getUser,
  editUser,
  resetPwd,
  getRolesAndPosts,
  deptList,
} from "@/api/setting";
import PageTab from "@/components/i-pageTab/i-pageTab";
import PageTable from "@/components/i-pageTable/i-pageTable";
import styles from "@/static/css/setting.module.css";
import { convertToTree, setIcons, showModal } from "@/utils/common";
import { formTab, ModalFormData } from "./managerForm";
import { useNavigate } from "react-router-dom";
import { passwordReg, phoneReg } from "@/utils/regs";
import { useSelector, useDispatch } from "react-redux";
import { getAuthButton } from "@/stores/userTreeReducer";
// userManager

const UserManager = () => {
  const dispatch = useDispatch();

  const authButton = useSelector(
    (state: { menuTree: { authButton: any[] } }) => state.menuTree.authButton
  );

  const [pageData, setPageData] = useState([]);
  const [total, setTotal] = useState(0);
  const [formData, setFormData] = useState({
    ypageNum: 1,
    ypageSize: 10,
    total: 0,
    userName: "",
    phoneNumber: "",
    status: "",
    deptId: "",
  });
  const navigate = useNavigate();
  const [modalFormData, setModalFormData] = useState<ModalFormData>({
    status: "0",
  });
  const [treeData, setTreeData] = useState<Record<string, any>[]>([]);
  const [defaultTree, setDefaultTree] = useState<Record<string, any>[]>([]);
  const [open, setOpen] = useState(false);
  const [passwordOpen, setPasswordOpen] = useState(false);

  const [modalStr, setModalStr] = useState("新增用户");
  const [selectKeys, setSelectKeys] = useState<number[]>([]);
  const [selectId, setSelectId] = useState<number>();
  const [selectedKeys, setSelectedKeys] = useState<any[]>([]);
  const [rolePostData, setRolePostData] = useState<any>({});

  const modalForm = [
    {
      label: "用户昵称",
      name: "nickName",
      type: "input",
      required: true,
      rules: [{ required: true, message: "用户昵称不能为空" }],
      params: {
        disabled: modalStr === "编辑用户",
      },
    },
    {
      label: "归属部门",
      name: "deptId",
      type: "treeSelect",
      labelCol1: { span: 0, offset: 1 },
      params: {
        placeholder: "请选择",
        treeData: defaultTree,
        fieldNames: {
          label: "deptName",
          value: "deptId",
          children: "children",
        },
      },
    },
    {
      label: "手机号码",
      name: "phonenumber",
      type: "input",
      rules: [
        { required: true, message: "手机号码不能为空" },
        { pattern: phoneReg, message: "请输入正确手机号" },
      ],
      params: {
        disabled: modalStr === "编辑用户",
      },
    },
    {
      label: "邮箱",
      name: "email",
      type: "input",
      labelCol1: { span: 0, offset: 4 },
    },
    {
      label: "用户名称",
      name: "userName",
      type: "input",
      rules: [{ required: true, message: "用户名称不能为空" }],
      params: {
        disabled: modalStr === "编辑用户",
      },
    },
    {
      label: "用户密码",
      name: "password",
      type: "input",
      params: {
        type: "password",
        disabled: modalStr === "编辑用户",
      },
      rules: [
        { required: true, message: "用户密码不能为空" },
        { pattern: passwordReg, message: "密码长度8-16位由数字和字母组成" },
      ],
      notShow: modalStr === "编辑用户",
    },
    {
      label: "用户性别",
      name: "sex",
      type: "select",
      labelCol1: { span: 0, offset: 1 },
      options: [
        { value: "0", label: "男" },
        { value: "1", label: "女" },
        { value: "2", label: "未知" },
      ],
    },
    {
      label: "状态",
      name: "status",
      type: "radio",
      options: [
        { value: "0", label: "正常" },
        { value: "1", label: "停用" },
      ],
      labelCol1: { span: 0, offset: 4 },
    },
    {
      label: "角色",
      name: "roleIds",
      type: "select",
      labelCol1: { span: 4, offset: 4 },
      params: {
        style: { width: "180px" },
        mode: "tags",
        fieldNames: {
          label: "roleName",
          value: "roleId",
        },
      },
      options: rolePostData.roles,
    },
    {
      label: "岗位",
      name: "postIds",
      type: "select",
      labelCol1: { span: 0, offset: 4 },
      params: {
        style: { width: "180px" },
        mode: "tags",
        fieldNames: {
          label: "postName",
          value: "postId",
        },
      },
      options: rolePostData.posts,
    },
  ];

  const footer = [
    {
      title: "新增",
      icon: setIcons("PlusOutlined"),
      onClick: () => {
        setModalStr("新增用户");
        setOpen(true);
      },
    },
    {
      title: "修改",
      icon: setIcons("EditOutlined"),
      disabled: selectKeys.length !== 1,
      onClick: () => {
        handleEdit(selectKeys[0]);
      },
    },
    {
      title: "删除",
      icon: setIcons("DeleteOutlined"),
      disabled: selectKeys.length === 0,
      onClick: () => {
        handleDelete(selectKeys);
      },
    },
  ];

  const columns = [
    {
      title: "用户编号",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "用户名称",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "用户昵称",
      dataIndex: "nickName",
      key: "nickName",
    },
    {
      title: "部门",
      dataIndex: "dept",
      key: "dept",
      render: (dept: { deptName: string }) => <span>{dept?.deptName}</span>,
    },
    {
      title: "手机号码",
      dataIndex: "phonenumber",
      key: "phonenumber",
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (val: string, data: any) => (
        <Switch
          defaultChecked={val === "0"}
          checked={val === "0"}
          onClick={(_) => statusModal(data)}
        ></Switch>
      ),
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "操作",
      dataIndex: "userId",
      key: "userId",
      fixed: "right",
      width: "150px",
      render: (val: number) => (
        <Space size={0}>
          <Button
            size="small"
            icon={setIcons("EditOutlined")}
            type="text"
            onClick={() => handleEdit(val)}
          >
            修改
          </Button>
          <Button
            size="small"
            onClick={() => handleDelete(val)}
            icon={setIcons("DeleteOutlined")}
            type="text"
          >
            删除
          </Button>
          <Dropdown menu={{ items: dropdownItems }} placement="bottomLeft">
            <Button
              size="small"
              icon={setIcons("DoubleRightOutlined")}
              type="text"
              onMouseOver={(_) => {
                setSelectId(val);
              }}
            >
              更多
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    console.log(authButton, "authButtonauthButton");

    getUserList();
  }, [formData]);

  useEffect(() => {
    getDeptTree();
    // rolePost();
    setTimeout(() => {
      dispatch(getAuthButton("asdasd"));
    }, 1700);
  }, []);

  const statusModal = async (item: {
    userId: string | number;
    status: "0" | "1";
  }) => {
    const data = await showModal({
      title: "系统提示",
      content: (
        <div>确认要{item.status === "0" ? "停用" : "启用"}选中的用户吗？</div>
      ),
    });
    if (data === "ok") {
      try {
        await changeUserStatus({
          userId: item.userId,
          status: item.status === "0" ? "1" : "0",
        });
        message.success("用户状态修改成功");
        getUserList();
      } catch (error) {}
    }
  };

  const getUserList = async () => {
    try {
      const data = await userList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {}
  };

  const getDeptTree = async (deptName?: string) => {
    try {
      const { data } = await deptList({ status: "0", deptName });
      const res = convertToTree(data, {
        id: "deptId",
        name: "deptName",
        parentId: "parentId",
      });
      !defaultTree.length && setDefaultTree(res);
      setTreeData(res);
    } catch (error) {}
  };

  const search = (data: any) => {
    const searchData = {
      ...formData,
      ...data,
      params: { beginTime: data.startDate, endTime: data.endDate },
      ypageNum: 1,
    };
    setFormData(searchData);
  };
  const reset = () => {
    setFormData({
      ypageNum: 1,
      ypageSize: 10,
      total: 0,
      userName: "",
      phoneNumber: "",
      status: "",
      deptId: "",
    });
    setSelectKeys([]);
    setSelectedKeys([]);
  };

  const pageChange = (page: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: page,
      ypageSize: pageSize,
    });
  };

  const onSelect = (e: any[]) => {
    setSelectedKeys(e);
    setFormData({ ...formData, deptId: e[0] });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    getDeptTree(value);
  };

  const handleEdit = async (userId: number) => {
    setModalStr("编辑用户");
    try {
      const { data, postIds, roleIds,roles, posts } = await getUser(userId);
      console.log(data, 'dadada');
      setRolePostData({
        roles,
        posts
      });
      
      setModalFormData({
        ...data,
        postIds: postIds,
        roleIds: roleIds,
      });
      setOpen(true);
    } catch (error) {}
  };

  const handleDelete = async (id: number | number[]) => {
    const data = await showModal({
      title: "系统提示",
      content: <div>是否确认删除当前选中用户的数据项？</div>,
    });
    if (data === "ok") {
      try {
        await deleteUser(id);
        message.success("删除成功");
        getUserList();
      } catch (error) {}
    }
  };

  const addUser = async (item: Record<string, any>) => {
    try {
      if (item.userId) {
        delete item.password;
        await editUser(item);
        message.success("编辑成功");
      } else {
        await userAdd(item);
        message.success("新增成功");
      }
      getUserList();
      setOpen(false);
    } catch (err) {}
  };

  const selectionChange = (keys: any[]) => {
    setSelectKeys(keys);
  };

  const resetModal = () => {
    setModalFormData({
      status: "0",
    });
    setOpen(false);
  };

  const resetPassword = async () => {
    setPasswordOpen(true);
  };
  const savePassWord = async (val: { password: string }) => {
    try {
      await resetPwd({
        password: val.password,
        userId: selectId!,
      });
      message.success("重置密码成功");
      setPasswordOpen(false);
      getUserList();
    } catch (error) {}
  };

  const rolePost = async () => {
    try {
      const { data } = await getRolesAndPosts();
      setRolePostData(data);
    } catch (error) {}
  };

  const dropdownItems: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div onClick={resetPassword}>{setIcons("KeyOutlined")} 重置密码</div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => navigate("/userManager/allocationRole/" + selectId)}
        >
          {setIcons("UserSwitchOutlined")} 分配角色
        </div>
      ),
    },
  ];
  return (
    <div className={styles.userManager}>
      <Modal
        destroyOnClose
        open={passwordOpen}
        title="提示"
        footer={<></>}
        width="480px"
        onCancel={(_) => setPasswordOpen(false)}
      >
        <PageTab
          subFoot
          layout="vertical"
          search={savePassWord}
          reset={(_) => setPasswordOpen(false)}
          formTab={[
            {
              label: "请输入新的密码",
              name: "password",
              type: "input",
              params: {
                type: "password",
              },
              rules: [
                { required: true, message: "密码不能为空" },
                {
                  pattern: passwordReg,
                  message: "密码长度8-16位由数字和字母组成",
                },
              ],
            },
          ]}
        ></PageTab>
      </Modal>
      <Modal
        destroyOnClose
        open={open}
        title={modalStr}
        footer={<></>}
        width="32%"
        onCancel={resetModal}
      >
        <PageTab
          subFoot
          search={addUser}
          reset={resetModal}
          formTab={modalForm}
          initialValues={modalFormData}
        ></PageTab>
      </Modal>
      <div className={styles.userManager_left}>
        <Input
          onChange={onChange}
          prefix={setIcons("SearchOutlined", { color: "#a0a0a0" })}
          placeholder="请输入部门名称"
        ></Input>
        <div style={{ marginTop: "20px" }}>
          {treeData && treeData.length ? (
            <Tree
              defaultExpandAll
              fieldNames={{
                title: "deptName",
                key: "deptId",
                children: "children",
              }}
              selectedKeys={selectedKeys}
              onSelect={onSelect}
              treeData={treeData}
            />
          ) : (
            <Empty></Empty>
          )}
        </div>
      </div>

      <div className={styles.userManager_right}>
        <PageTab
          defaultTimer={[]}
          footer={footer}
          search={search}
          reset={reset}
          formTab={formTab}
        ></PageTab>
        <PageTable
          rowKey="userId"
          selection
          selectionChange={selectionChange}
          pageChange={pageChange}
          selectedRowKeys={selectKeys}
          data={pageData}
          columns={columns}
          pagination={{
            total: total,
            current: formData.ypageNum,
          }}
        ></PageTable>
      </div>
    </div>
  );
};

export default UserManager;
