import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const initialState = {
  value: [] as any[],
  authButton: [] as any[],
};

export const userTreeReducer = createSlice({
  name: "userTree",
  initialState,
  reducers: {
    setMenuList: (state, action) => {
      state.value = flattenArrayTree(action.payload);
    },
    getAuthButton: (state, action) => {
      console.log(action.payload, state.value, initialState, "action.payload");
      // state.openKeys = action.payload
    },
  },
});

const flattenArrayTree = (arrList: any[]) => {
  const list: { path: any; menuName: string; menuType: string }[] = [];
  const deepTree = (arr: any) => {
    arr.map(
      (item: {
        hidden: boolean;
        menuType: string;
        meta: { title: string };
        path: any;
        children: string | any[];
      }) => {
        if (item.children && item.children.length && !item.hidden) {
          deepTree(item.children);
        }
        list.push({
          path: item.path,
          menuName: item.meta?.title,
          menuType: item.menuType,
        });
      }
    );
  };
  deepTree(arrList);
  return list;
};

export const { setMenuList, getAuthButton } = userTreeReducer.actions;
export default userTreeReducer.reducer;
