export const formTab = [
  {
    label: "用户名称",
    name: "userName",
    type: "input",
  },
  {
    label: "手机号码",
    name: "phoneNumber",
    type: "input",
  },
  {
    label: "状态",
    name: "status",
    type: "select",
    width: "200px",
    options: [
      { value: "0", label: "正常" },
      { value: "1", label: "禁用" },
    ],
  },
  // {
  //   label: "创建时间",
  //   name: "rangePicker",
  //   type: "rangePicker",
  //   width: "330px",
  // },
];

export interface ModalFormData {
  userName?: string;
  phoneNumber?: string;
  status?: string;
  sex?: string;
  userId?: string;
  nickName?: string;
  email?: string;
}
