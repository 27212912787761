import React, { useEffect, useRef, useState } from "react";
import PageTab from "@/components/i-pageTab/i-pageTab";
import PageTable from "@/components/i-pageTable/i-pageTable";
import {
  bookList,
  bookDetail,
  bookEdit,
  bookAdd,
  bookDelete,
  categorybaseList,
} from "@/api/other";
import { Button, message, Modal, Space, Switch, Tag } from "antd";
import {
  customRequest,
  setIcons,
  normFile,
  resetForm,
  getTimeFormat,
  getFirstDay,
  showModal,
} from "@/utils/common";
import { useSelector, useDispatch, useStore } from "react-redux";
import { dictInfoAsync } from "@/stores/dictInfo";
import IForm from "@/components/i-form/i-form";
import { decimalReg } from "@/utils/regs";

const BookManager = () => {
  const dispatch = useDispatch();
  const {
    state: { providers_level, account_changes_business_type },
  } = useSelector((state: { dictInfo: any }) => state.dictInfo);
  const [pageData, setPageData] = useState<SelectCmsAppUserPageRes["rows"]>([]);
  const [userOutPageData, setUserOutPageData] = useState<
    ListUserAccountChangesByIdRes["rows"]
  >([]);
  const [mainPicUploadProgress, setMainPicUploadProgress] = useState(false);

  const [total, setTotal] = useState(0);
  const [userOutTotal, setUserOutTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [categoryList, setCategoryList] = useState([]);

  const [modalFormData, setModalFormData] = useState<pageHomeAddParams>({});

  const statusList = [
    {
      value: "0",
      label: "正常",
    },
    {
      value: "2",
      label: "禁用",
    },
  ];

  const [formData, setFormData] = useState<any>({
    startDate: getTimeFormat(getFirstDay(new Date()), "yyyy-MM-dd HH:mm:ss"),
    endDate: getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss"),
    categoryName: "",
    ypageNum: 1,
    ypageSize: 10,
  });

  const [userOutFormData, setUserOutFormData] = useState({
    userId: "",
    ypageNum: 1,
    ypageSize: 10,
  });

  const [modalFormuserEdit, setModalFormuserEdit] = useState<any>({
    image: [],
  });

  const footer = [
    {
      title: "新增",
      icon: setIcons("PlusOutlined"),
      onClick: () => setOpen(true),
    },
  ];

  const userEditFormTab = [
    {
      label: "图片",
      name: "image",
      type: "upload",
      labelCol: { span: 5, offset: 0 },
      getValueFromEvent: normFile,
      params: {
        maxCount: 1,
        fileList: modalFormuserEdit.image,
        beforeUpload: () => {
          setMainPicUploadProgress(true);
        },
        uploadMessage: mainPicUploadProgress ? (
          <div>
            {setIcons("LoadingOutlined")}
            <div>上传中...</div>
          </div>
        ) : (
          <div>
            {setIcons("PlusOutlined")}
            <div style={{ marginTop: 8 }}>上传</div>
          </div>
        ),
        customRequest: async (e: any) => {
          try {
            const data = await customRequest(
              e,
              modalFormuserEdit.image as any[]
            );

            setModalFormuserEdit({
              ...modalFormuserEdit,
              image: data as any,
            });
          } catch (error) {
          } finally {
            setMainPicUploadProgress(false);
          }
        },
        onRemove: () => {
          setModalFormuserEdit({
            ...modalFormData,
            image: [],
          });
        },
      },
    },
    {
      label: "文章分类",
      name: "categoryId",
      type: "select",
      options: categoryList,
      rules: [{ required: true, message: "请选择文章分类" }],
    },
    {
      label: "标题",
      name: "title",
      type: "input",
      labelCol: { span: 5, offset: 0 },
      rules: [{ required: true, message: "请输入文章标题" }],
    },
    {
      label: "简介",
      name: "introduction",
      type: "input",
      labelCol: { span: 5, offset: 0 },

    },

    {
      label: "序号",
      name: "sortNum",
      type: "input",
      params: {
        type: "number",
      },
      labelCol: { span: 5, offset: 0 },

    },
    {
      label: "详情",
      name: "detail",
      type: "quill",
      labelCol: { span: 5, offset: 0 },
      params: {
        width: "480px",
      },
    },
    // {
    //   label: "状态",
    //   name: "delFlag",
    //   type: "radio",
    //   options: statusList,
    // },
  ];

  userEditFormTab.map((item: any) => {
    item.width = "380px";
  })

  const formTab = [
    {
      label: "文章分类",
      name: "categoryId",
      type: "select",
      options: categoryList,
    },
    {
      label: "文章标题",
      name: "title",
      type: "input",
    },
    {
      label: "时间范围",
      name: "rangePicker",
      type: "rangePicker",
      width: "380px",
    },
  ];

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },

    {
      title: "分类标题",
      dataIndex: "categoryTitle",
      key: "categoryTitle",
    },
    {
      title: "标题",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "分类名称",
      dataIndex: "categoryName",
      key: "categoryName",
    },
    {
      title: "图片",
      dataIndex: "image",
      key: "image",
      render: (data: string) =>
        data && <img style={{ height: "32px" }} src={data} alt="" />,
    },
    {
      title: "简介",
      dataIndex: "introduction",
      key: "introduction",
    },
    {
      title: "状态",
      dataIndex: "delFlag",
      key: "delFlag",
      render: (val: string) => (
        <Tag color="processing">{val === "0" ? "启用" : "停用"}</Tag>
      ),
    },

    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      render: (val: string, rows: any) =>
        rows.delFlag !== "2" && (
          <Space>
            <Button
              size="small"
              icon={setIcons("EditOutlined")}
              type="text"
              onClick={() => {
                outDetail(val);
              }}
            >
              修改
            </Button>
            <Button
              size="small"
              icon={setIcons("SnippetsOutlined")}
              type="text"
              onClick={() => {
                deteletDetail(val);
              }}
            >
              停用
            </Button>
          </Space>
        ),
    },
  ];

  useEffect(() => {
    getPageData();
    console.log(providers_level, "providers_level");
  }, [formData]);

  useEffect(() => {
    userOutFormData.userId && getUserOutList();
  }, [userOutFormData]);

  useEffect(() => {
    getcategorybaseList();
    !providers_level && dispatch(dictInfoAsync("providers_level") as any);

    !account_changes_business_type &&
      dispatch(dictInfoAsync("account_changes_business_type") as any);
  }, []);

  const getcategorybaseList = async () => {
    const { data } = await categorybaseList({ categoryName: "" });
    setCategoryList(
      data.map((item: any) => {
        return { label: item.categoryName, value: item.id };
      })
    );
    console.log(data, "data");
  };
  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await bookList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const search = (val: any) => {
    setFormData({
      ...formData,
      ...val,
      ypageNum: 1,
    });
  };

  const deteletDetail = async (val: string) => {
    await bookDelete(val);
    message.success("删除成功");
    getPageData();
  };
  const reset = () => {
    setFormData({
      ...resetForm(formData),
      startDate: getTimeFormat(getFirstDay(new Date()), "yyyy-MM-dd HH:mm:ss"),
      endDate: getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss"),
    });
  };
  const resetModal = () => {
    setModalFormData({});
    setOpen(false);
  };

  const getUserOutList = async () => {
    try {
      // const data = await listUserAccountChangesById({
      //   ypageNum: userOutFormData.ypageNum,
      //   ypageSize: userOutFormData.ypageSize,
      //   userId: userOutFormData.userId,
      // });
      // setUserOutPageData(data.rows);
      // setUserOutTotal(data.total);
    } catch (error) {}
  };

  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };

  const outDetail = async (val: string) => {
    try {
      const { data } = await bookDetail(val);
      setModalFormuserEdit({
        ...data,
        image: data.image
          ? [{ url: data.image, uid: data.image, name: "" }]
          : [],
      });
      setOpen(true);
    } catch (error) {}
  };

  const confirmUser = async (val: any) => {
    try {
      let data;
      if (!modalFormuserEdit?.id) {
        data = await bookAdd({ ...val, image: val?.image?.[0]?.url });
      } else {
        data = await bookEdit({ ...val, image: val?.image?.[0]?.url });
      }
      message.success(data.msg);
      setOpen(false);
      getPageData();
      setModalFormuserEdit({
        image: [],
      })
    } catch (error) {}
  };

  const resetEditUser = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        destroyOnClose={true}
        open={open}
        title="新增分类"
        width="62%"
        footer={<></>}
        onCancel={resetEditUser}
      >
        <PageTab
          subFoot
          search={confirmUser}
          reset={resetEditUser}
          formTab={userEditFormTab}
          initialValues={modalFormuserEdit}
        ></PageTab>
      </Modal>
      <PageTab
        search={search}
        reset={reset}
        formTab={formTab}
        footer={footer}
      ></PageTab>
      <PageTable
        loading={loading}
        pageChange={pageChange}
        size="small"
        rowKey="userId"
        columns={columns}
        data={pageData}
        pagination={{
          current: formData.ypageNum!,
          pageSize: formData.ypageSize!,
          total: total,
        }}
      ></PageTable>
    </div>
  );
};

export default BookManager;
