import React, { useEffect, useRef, useState } from "react";
import PageTab from "@/components/i-pageTab/i-pageTab";
import PageTable from "@/components/i-pageTable/i-pageTable";
import { appBannerList, appBannerAdd, appBannerEdit } from "@/api/operation";
import { Button, message, Modal, Space, Tag } from "antd";
import { customRequest, normFile, setIcons } from "@/utils/common";
import { useSelector, useDispatch, useStore } from "react-redux";
import { dictInfoAsync } from "@/stores/dictInfo";

const systemTypeList = [
  {
    label: "android",
    value: "0",
  },
  {
    label: "ios",
    value: "1",
  },
  {
    label: "小程序",
    value: "2",
  },
];
const AppBannerManager = () => {
  const dispatch = useDispatch();
  const {
    state: { app_store_type },
  } = useSelector((state: { dictInfo: any }) => state.dictInfo);

  const [pageData, setPageData] = useState<GetCmsPageHomeRes["rows"]>([]);
  const [total, setTotal] = useState(0);
  const [mainPicUploadProgress, setMainPicUploadProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalFormData, setModalFormData] = useState<pageHomeAddParams>({
    status: "0",
    url: []
  });

  const [formData, setFormData] = useState<GetCmsPageHomeParams>({
    ypageNum: 1,
    ypageSize: 10,
  });
  const modalFormTab = [
    {
      label: "banner",
      name: "url",
      type: "upload",
      rules: [{ required: true, message: "请上传banner" }],
      labelCol: { span: 0, offset: 3 },
      getValueFromEvent: normFile,
      params: {
        maxCount: 1,
        fileList: modalFormData.url,
        beforeUpload: () => {
          setMainPicUploadProgress(true);
        },
        uploadMessage: mainPicUploadProgress ? (
          <div>
            {setIcons("LoadingOutlined")}
            <div>上传中...</div>
          </div>
        ) : (
          <div>
            {setIcons("PlusOutlined")}
            <div style={{ marginTop: 8 }}>上传</div>
          </div>
        ),
        customRequest: async (e: any) => {
          try {
            const data = await customRequest(e, modalFormData.url as any[]);
            setModalFormData({
              ...modalFormData,
              url: data as any,
            });
          } catch (error) {
          } finally {
            setMainPicUploadProgress(false);
          }
        },
        onRemove: () => {
          setModalFormData({
            ...modalFormData,
            url: [],
          });
        },
      },
    },
    {
      label: "跳转地址",
      name: "linksUrl",
      type: "input",
    },
    {
      label: "排序",
      name: "sort",
      type: "input",
      params: {
        type: "number",
      },
      labelCol: { span: 0, offset: 3 },
      rules: [{ required: true, message: "请输入排序" }],
    },
    
    {
      label: "状态",
      name: "status",
      type: "radio",
      options: [
        {
          label: "正常",
          value: "0",
        },
        {
          label: "禁用",
          value: "1",
        }
      ],
      labelCol: { span: 0, offset: 3 },
      rules: [{ required: true, message: "请选择类型" }],
    },
    {
      label: "类型",
      name: "type",
      type: "select",
      options: [
        {
          label: "banner",
          value: "0",
        },
        {
          label: "导航",
          value: "1",
        }
      ],
      labelCol: { span: 0, offset: 3 },
      rules: [{ required: true, message: "请选择类型" }],
    },
  ];
  const formTab = [
    {
      label: "类型",
      name: "type",
      type: "select",
      options: [
        {
          label: "banner",
          value: "0",
        },
        {
          label: "导航",
          value: "1",
        },
      ],
    },
    {
      label: "状态",
      name: "status",
      type: "select",
      options: [
        {
          label: "正常",
          value: "0",
        },
        {
          label: "禁用",
          value: "1",
        },
      ],
    },
  ];

  const footer = [
    {
      title: "新增",
      icon: setIcons("PlusOutlined"),
      onClick: () => setOpen(true),
    },
  ];

  const columns = [
    {
      title: "序号",
      dataIndex: "sort",
      key: "sort",
    },
    {
      title: "banner",
      dataIndex: "imgUrl",
      key: "imgUrl",
      render: (data: string) => {
        return <img style={{ height: "64px" }} src={data} alt="" />;
      },
    },
    {
      title: "跳转地址",
      dataIndex: "linksUrl",
      key: "linksUrl",
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (val: string) => (
        <Tag color="blue">{val === "0" ? "正常" : "禁用"}</Tag>
      ),
    },
    {
      title: "类型",
      dataIndex: "type",
      key: "type",
      render: (val: string) =>(
          <Tag color="blue">
            {
             val === '0' ? 'banner' : '导航'
            }
          </Tag>
        ) 
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      width: "120px",
      render: (val: string, rows: any) => (
        <Space>
          <Button
            size="small"
            icon={setIcons("EditOutlined")}
            type="text"
            onClick={(e) => {
              setModalFormData({
                ...rows,
                url: [{ url: rows.imgUrl, uid: rows.imgUrl, name: "" }],
              });
              setOpen(true);
            }}
          >
            修改
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  useEffect(() => {
    !app_store_type && dispatch(dictInfoAsync("app_store_type") as any);
  }, []);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await appBannerList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const search = (val: any) => {
    setFormData({
      ...formData,
      ...val,
      ypageNum: 1,
    });
  };

  const reset = () => {
    setFormData({
      ypageNum: 1,
      ypageSize: 10,
    });
  };
  const resetModal = () => {
    setModalFormData({
      status: "0",
      url: []
    });
    setOpen(false);
  };

  const confirmSend = async (val: any) => {
    console.log(val, "valvalval");
    try {
      if (modalFormData.id) {
        await appBannerEdit({ ...val, imgUrl: val.url[0].url });
        message.success("编辑成功");
      } else {
        await appBannerAdd({ ...val, imgUrl: val.url[0].url, versionCode: 0 });
        message.success("新增成功");
      }
      resetModal();
      getPageData();
    } catch (error) {}
  };

  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };
  return (
    <div>
      <Modal
        destroyOnClose
        open={open}
        title={modalFormData.id ? "编辑图标" : "新增图标"}
        width="24%"
        footer={<></>}
        onCancel={resetModal}
      >
        <PageTab
          subFoot
          search={confirmSend}
          reset={resetModal}
          formTab={modalFormTab}
          initialValues={modalFormData}
        ></PageTab>
      </Modal>
      <PageTab
        search={search}
        reset={reset}
        formTab={formTab}
        footer={footer}
      ></PageTab>
      <PageTable
        loading={loading}
        pageChange={pageChange}
        size="small"
        rowKey="id"
        columns={columns}
        data={pageData}
        pagination={{
          current: formData.ypageNum!,
          pageSize: formData.ypageSize!,
          total: total,
        }}
      ></PageTable>
    </div>
  );
};

export default AppBannerManager;
