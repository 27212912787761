import React, { useEffect, useRef, useState } from "react";
import PageTab from "@/components/i-pageTab/i-pageTab";
import PageTable from "@/components/i-pageTable/i-pageTable";
import { getVersionList, editAppVersion, addAppVersion } from "@/api/operation";
import { Button, message, Modal, Space, Tag } from "antd";
import { customRequest, normFile, setIcons } from "@/utils/common";
import { useSelector, useDispatch, useStore } from "react-redux";
import { dictInfoAsync } from "@/stores/dictInfo";

const systemTypeList = [
  {
    label: "android",
    value: "0",
  },
  {
    label: "ios",
    value: "1",
  },
  {
    label: "小程序",
    value: "2",
  },
];
const AppVersionManager = () => {
  const dispatch = useDispatch();
  const {
    state: { app_store_type },
  } = useSelector((state: { dictInfo: any }) => state.dictInfo);

  const [pageData, setPageData] = useState<GetCmsPageHomeRes["rows"]>([]);
  const [total, setTotal] = useState(0);
  const [mainPicUploadProgress, setMainPicUploadProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalFormData, setModalFormData] = useState<pageHomeAddParams>({
    forcedUpdate: "0",
    isNew: "0",
    url: [],

  });

  const [formData, setFormData] = useState<GetCmsPageHomeParams>({
    ypageNum: 1,
    ypageSize: 10,
  });
  const modalFormTab = [
    {
      label: "更新包",
      name: "url",
      type: "upload",
      // rules: [{ required: true, message: "请上传更新包" }],
      // labelCol: { span: 0, offset: 3 },
      getValueFromEvent: normFile,
      params: {
        accept: '*/*',
        maxCount: 1,
        fileList: modalFormData.url,
        beforeUpload: () => {
          setMainPicUploadProgress(true);
        },
        uploadMessage: mainPicUploadProgress ? (
          <div>
            {setIcons("LoadingOutlined")}
            <div>上传中...</div>
          </div>
        ) : (
          <div>
            {setIcons("PlusOutlined")}
            <div style={{ marginTop: 8 }}>上传</div>
          </div>
        ),
        customRequest: async (e: any) => {
          try {
            const data = await customRequest(e, modalFormData.url as any[]);
            setModalFormData({
              ...modalFormData,
              url: data as any,
            });
          } catch (error) {
          } finally {
            setMainPicUploadProgress(false);
          }
        },
        onRemove: () => {
          setModalFormData({
            ...modalFormData,
            url: [],
          });
        },
      },
    },
    {
      label: "远端地址",
      name: "urlStr",
      type: "input",
      // rules: [{ required: true, message: "请输入远端地址" }],
    },
    {
      label: "版本号",
      name: "appVersion",
      type: "input",
      rules: [{ required: true, message: "请输入版本号" }],
    },
    {
      label: "强制更新",
      name: "forcedUpdate",
      type: "radio",
      options: [
        {
          label: "是",
          value: 0,
        },
        {
          label: "否",
          value: 1,
        }
      ],
      rules: [{ required: true, message: "请选择强制更新" }],
    },
    {
      label: "最新版本",
      name: "isNew",
      type: "radio",
      options: [
        {
          label: "是",
          value: 0,
        },
        {
          label: "否",
          value: 1,
        }
      ],
      params: {
        allowClear: false,
      },
      rules: [{ required: true, message: "请选择类型" }],
    },
    {
      label: "更新详情",
      name: "content",
      type: "textArea",
      rules: [{ required: true, message: "请输入更新详情" }],
    },
    {
      label: "备注",
      name: "remark",
      type: "textArea",
    },
  ];
;

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "版本号",
      dataIndex: "appVersion",
      key: "appVersion",
    },
    
    {
      title: "安装包远端地址",
      dataIndex: "url",
      key: "url",
      width: "300px",
    },

    {
      title: "更新详情",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "备注",
      dataIndex: "remark",
      key: "remark",
    },
    
    {
      title: "强制更新",
      dataIndex: "forcedUpdateStr",
      key: "forcedUpdateStr",
      render: (val: string) => (
        <Tag color="blue">{val}</Tag>
      ),
    },
    {
      title: "最新版本",
      dataIndex: "isNewStr",
      key: "isNewStr",
      render: (val: string) =>(
          <Tag color="blue">
            {
             val 
            }
          </Tag>
        ) 
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "更新时间",
      dataIndex: "updateTime",
      key: "updateTime",
    },
    
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      width: "120px",
      render: (val: string, rows: any) => (
        <Space>
          <Button
            size="small"
            icon={setIcons("EditOutlined")}
            type="text"
            onClick={(e) => {
              setModalFormData({
                ...rows,
                urlStr: rows.url,
                url: [{ url: rows.url, uid: rows.url, name: "" }],
              });
              setOpen(true);
            }}
          >
            修改
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  useEffect(() => {
    !app_store_type && dispatch(dictInfoAsync("app_store_type") as any);
  }, []);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await getVersionList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const search = (val: any) => {
    setFormData({
      ...formData,
      ...val,
      ypageNum: 1,
    });
  };

  const reset = () => {
    setFormData({
      ypageNum: 1,
      ypageSize: 10,
    });
  };
  const resetModal = () => {
    setModalFormData({
      forcedUpdate: "0",
      isNew: "0",
      url: []
    });
    setOpen(false);
  };

  const confirmSend = async (val: any) => {
    console.log(val, "valvalval");
    const params = {
      url: val?.url?.[0]?.url || val.urlStr,
      appVersion: val.appVersion,
      content: val.content,
      remark: val.remark,
      forcedUpdate: val.forcedUpdate,
      isNew: val.isNew,
    }
    try {
      if (modalFormData.id) {
        await editAppVersion({...params, id: modalFormData.id});
        message.success("编辑成功");
      } else {
        await addAppVersion({...params, id: val.id});
        message.success("新增成功");
      }
      resetModal();
      getPageData();
    } catch (error) {}
  };

  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };
  return (
    <div>
      <Modal
        destroyOnClose
        open={open}
        title={modalFormData.id ? "编辑APP版本" : "新增APP版本"}
        width="600px"
        footer={<></>}
        onCancel={resetModal}
      >
        <PageTab
          subFoot
          search={confirmSend}
          reset={resetModal}
          formTab={modalFormTab}
          initialValues={modalFormData}
        ></PageTab>
      </Modal>
      {/* <PageTab
        search={search}
        reset={reset}
        formTab={formTab}
        footer={footer}
      ></PageTab> */}
      <Button icon={setIcons("PlusOutlined")} onClick={() => setOpen(true)}>新增</Button>
      <PageTable
        loading={loading}
        pageChange={pageChange}
        size="small"
        rowKey="id"
        columns={columns}
        data={pageData}
        pagination={{
          current: formData.ypageNum!,
          pageSize: formData.ypageSize!,
          total: total,
        }}
      ></PageTable>
    </div>
  );
};

export default AppVersionManager;
