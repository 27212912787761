import React, { useEffect, useRef, useState } from "react";
import PageTab from "@/components/i-pageTab/i-pageTab";
import PageTable from "@/components/i-pageTable/i-pageTable";

import {
  setIcons,
  showModal,
  downloadFile,
  getTimeFormat,
  getFirstDay,
} from "@/utils/common";
import {
  merOrderWithOutOilStatisticsList,
  merOrderWithOutOilStatisticsExport,
} from "@/api/statistics";

const MerOrderWithOutOilStatisticsList = () => {
  const [pageData, setPageData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const [formData, setFormData] =
    useState<SelectCmsMerOrderWithOutOilStatisticsPageParams>({
      startDate: getTimeFormat(getFirstDay(new Date()), "yyyy-MM-dd HH:mm:ss"),
      endDate: getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss"),
      merName: "",
      merMobile: "",
      ypageNum: 1,
      ypageSize: 10,
    });

    const formTab = [
      {
        label: "门店电话",
        name: "storeMobile",
        type: "input",
        width: "240px",
      },
      {
        label: "门店名称",
        name: "storeName",
        type: "input",
        width: "240px",
      },
      {
        label: "商户电话",
        name: "merMobile",
        type: "input",
        width: "240px",
      },
      {
        label: "商户名称",
        name: "merName",
        type: "input",
        width: "240px",
      },
      {
        label: "核销时间",
        name: "rangePicker",
        type: "rangePicker",
        width: "330px",
      },
    ];

  const footer = [
    {
      title: "导出",
      icon: setIcons("DownloadOutlined"),
      onClick: () => exportExcel(),
      loading: exportLoading,
    },
  ];

  const columns = [
    {
      title: "门店名称",
      dataIndex: "storeName",
      key: "storeName",
      fixed: "left",
    },

    {
      title: "门店电话",
      dataIndex: "storeMobile",
      key: "storeMobile",
      fixed: "left",
    },
    {
      title: "商户名称",
      dataIndex: "merName",
      key: "merName",
    },
    {
      title: "商户电话",
      dataIndex: "merMobile",
      key: "merMobile",
    },
    {
      title: "洗车单量",
      dataIndex: "washCount",
      key: "washCount",
    },
    {
      title: "洗车收益",
      dataIndex: "washIncomeAmount",
      key: "washIncomeAmount",
    },
    {
      title: "洗车实付",
      dataIndex: "washPayAmount",
      key: "washPayAmount",
    },
    {
      title: "美容单量",
      dataIndex: "beautyCount",
      key: "beautyCount",
      width: 100,
    },
    {
      title: "美容收益",
      dataIndex: "beautyIncomeAmount",
      key: "beautyIncomeAmount",
      width: 100,
    },
    {
      title: "美容实付",
      dataIndex: "beautyPayAmount",
      key: "beautyPayAmount",
      width: 100,
    },
    {
      title: "装饰单量",
      dataIndex: "decorationCount",
      key: "decorationCount",
    },
    {
      title: "装饰收益",
      dataIndex: "decorationIncomeAmount",
      key: "decorationIncomeAmount",
    },
    {
      title: "装饰实付",
      dataIndex: "decorationPayAmount",
      key: "decorationPayAmount",
    },
    {
      title: "养护单量",
      dataIndex: "repairCount",
      key: "repairCount",
    },
    {
      title: "养护收益",
      dataIndex: "repairIncomeAmount",
      key: "repairIncomeAmount",
    },
    {
      title: "养护实付",
      dataIndex: "repairPayAmount",
      key: "repairPayAmount",
    },
    {
      title: "改装付款单量",
      dataIndex: "refitCount",
      key: "refitCount",
    },
    {
      title: "改装付款收益",
      dataIndex: "refitIncomeAmount",
      key: "refitIncomeAmount",
    },
    {
      title: "改装付款实付",
      dataIndex: "refitPayAmount",
      key: "refitPayAmount",
    },
    {
      title: "维修付款单量",
      dataIndex: "serviceCount",
      key: "serviceCount",
    },
    {
      title: "维修付款收益",
      dataIndex: "serviceIncomeAmount",
      key: "serviceIncomeAmount",
    },
    {
      title: "维修付款实付",
      dataIndex: "servicePayAmount",
      key: "servicePayAmount",
    },
    {
      title: "门店总单量",
      dataIndex: "storeCount",
      key: "storeCount",
      fixed: "right",
    },
    {
      title: "门店总实付",
      dataIndex: "storePayAmount",
      key: "storePayAmount",
      fixed: "right",
    },
    {
      title: "门店总收益",
      dataIndex: "storeIncomeAmount",
      key: "storeIncomeAmount",
      fixed: "right",
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await merOrderWithOutOilStatisticsList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const exportExcel = async () => {
    setExportLoading(true);
    try {
      const data = await merOrderWithOutOilStatisticsExport(formData);
      downloadFile(
        data as any,
        "门店除机油订单统计" + getTimeFormat(new Date(), 'yyyy-MM-dd HH:mm:ss')
      );
    } catch (error) {
    } finally {
      setExportLoading(false);
    }
  };
  const search = (val: any) => {
    setFormData({
      ...formData,
      ...val,
      ypageNum: 1,
    });
  };

  const reset = () => {
    setFormData({
      startDate: getTimeFormat(getFirstDay(new Date()), "yyyy-MM-dd HH:mm:ss"),
      endDate: getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss"),
      merName: "",
      merMobile: "",
      ypageNum: 1,
      ypageSize: 10,
    });
  };

  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };
  return (
    <div>
      <PageTab
        search={search}
        reset={reset}
        formTab={formTab}
        footer={footer}
      ></PageTab>
      <PageTable
        loading={loading}
        pageChange={pageChange}
        rowKey="userId"
        columns={columns}
        data={pageData}
        pagination={{
          current: formData.ypageNum!,
          pageSize: formData.ypageSize!,
          total: total,
        }}
      ></PageTable>
    </div>
  );
};

export default MerOrderWithOutOilStatisticsList;
