import React, { useEffect, useRef, useState } from "react";
import PageTab from "@/components/i-pageTab/i-pageTab";
import PageTable from "@/components/i-pageTable/i-pageTable";
import styles from "@/static/css/setting.module.css";
import {
  menuList,
  menuEdit,
  menuDetail,
  menuAdd,
  menuDelete,
} from "@/api/setting";
import { convertToTree, setIcons, showModal } from "@/utils/common";
import getIconList from "@/utils/iconList";
import { Button, message, Modal, Space, Tag } from "antd";

const MenuManager = () => {
  const [modalFormData, setModalFormData] = useState<EditParams>({
    menuType: "M",
    visible: "0",
    isFrame: "1",
    status: "0",
  });
  const [pageData, setPageData] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [menuType, setMenuType] = useState("M");
  const [modalStr, setModalStr] = useState("添加菜单");

  useEffect(() => {
    getMenuList();
  }, []);

  const getMenuList = async (menuName?: string, status?: string) => {
    try {
      const { data } = await menuList({ status, menuName });
      const res = convertToTree(data, {
        id: "menuId",
        name: "menuName",
        parentId: "parentId",
      });
      setPageData(res);
    } catch (error) {}
  };

  const handleEdit = async (val: number) => {
    setModalStr("编辑菜单");
    try {
      const { data } = await menuDetail(val);
      setModalFormData(data);
      setMenuType(data.menuType!);
      setOpen(true);
    } catch (error) {}
  };

  const handleAdd = (rows: any) => {
    setModalStr("添加菜单");
    setModalFormData({
      ...modalFormData,
      menuId: rows.menuId,
      parentId: rows.parentId,
    });
    setOpen(true);
  };

  const resetForm = () => {
    setModalFormData({
      menuType: "M",
      visible: "0",
      isFrame: "1",
      status: "0",
    });
    setOpen(false);
    setMenuType("M");
  };

  const handleDelete = async (val: number) => {
    const data = await showModal({
      title: "系统提示",
      content: <div>是否确认删除选中的数据项？</div>,
    });
    if (data === "ok") {
      try {
        await menuDelete(val);
        message.success("删除成功");
        getMenuList();
      } catch (error) {}
    }
  };

  const addMenu = () => {
    setOpen(true);
    setModalStr("添加菜单");
  };

  const addUser = async (val: { menuId?: number; parentId?: number }) => {
    const data = val;
    try {
      if (modalStr === "编辑菜单") {
        await menuEdit(data);
        message.success("编辑成功");
      } else {
        data["parentId"] = val.menuId;
        delete data.menuId;
        await menuAdd(data);
        message.success("新增成功");
      }
      resetForm();
      setOpen(false);
      getMenuList();
    } catch (error) {}
  };

  const search = (val: { menuName?: string; status?: string }) => {
    getMenuList(val.menuName, val.status);
  };

  const menuTypeChange = (e: { target: { value: string } }) => {
    const val = e.target.value;
    setMenuType(val);
  };

  const formTab = [
    {
      label: "菜单名称",
      name: "menuName",
      type: "input",
    },
    {
      label: "状态",
      name: "status",
      type: "select",
      options: [
        { value: "0", label: "正常" },
        { value: "1", label: "停用" },
      ],
    },
  ];

  const columns = [
    {
      title: "菜单名称",
      dataIndex: "menuName",
      key: "menuName",
    },
    {
      title: "图标",
      dataIndex: "icon",
      key: "icon",
      render: (val: string) => (val ? setIcons(val) : <></>),
    },
    {
      title: "排序",
      dataIndex: "orderNum",
      key: "orderNum",
    },
    {
      title: "权限标识",
      dataIndex: "perms",
      key: "perms",
    },
    {
      title: "组件路径",
      dataIndex: "path",
      key: "path",
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (val: string) => (
        <Tag color="blue">{val === "0" ? "启用" : "停用"}</Tag>
      ),
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "操作",
      dataIndex: "menuId",
      key: "menuId",
      fixed: "right",
      width: "150px",
      render: (val: number, rows: any) => (
        <Space>
          <Button
            size="small"
            icon={setIcons("EditOutlined")}
            type="text"
            onClick={() => handleEdit(val)}
          >
            修改
          </Button>
          <Button
            size="small"
            icon={setIcons("PlusOutlined")}
            type="text"
            onClick={() => handleAdd(rows)}
          >
            新增
          </Button>
          <Button
            size="small"
            onClick={() => handleDelete(val)}
            icon={setIcons("DeleteOutlined")}
            type="text"
          >
            删除
          </Button>
        </Space>
      ),
    },
  ];

  const footer = [
    {
      title: "新增",
      icon: setIcons("PlusOutlined"),
      onClick: addMenu,
    },
  ];

  const modalForm = [
    {
      label: "上级菜单",
      name: "menuId",
      type: "treeSelect",
      params: {
        placeholder: "请选择",
        disabled: !!modalFormData.menuId,
        treeData: [
          { menuName: "主目录", menuId: 0, parentId: "", children: pageData },
        ],
        fieldNames: {
          label: "menuName",
          value: "menuId",
          children: "children",
        },
      },
      width: "460px",
      notShow: modalStr === "编辑菜单",
    },
    {
      label: "菜单类型",
      name: "menuType",
      type: "radio",
      width: "100%",
      options: [
        { value: "M", label: "目录" },
        { value: "C", label: "菜单" },
        { value: "F", label: "按钮" },
      ],
      params: {
        onChange: menuTypeChange,
      },
    },
    {
      label: "菜单图标",
      name: "icon",
      type: "select",
      options: getIconList,
    },
    {
      label: "菜单名称",
      name: "menuName",
      type: "input",
      rules: [{ required: true, message: "请填写菜单名称" }],
    },
    {
      label: "显示排序",
      name: "orderNum",
      type: "input",
      params: {
        type: "number",
      },
      rules: [{ required: true, message: "请填写排序" }],
    },
    {
      label: "是否外链",
      name: "isFrame",
      type: "radio",
      options: [
        { value: "0", label: "是" },
        { value: "1", label: "否" },
      ],
    },
    {
      label: "路由地址",
      name: "path",
      type: "input",
      rules: [{ required: true, message: "请填写路由地址" }],
    },
    {
      label: "显示状态",
      name: "visible",
      type: "radio",
      options: [
        { value: "0", label: "显示" },
        { value: "1", label: "隐藏" },
      ],
    },
    {
      label: "菜单状态",
      name: "status",
      type: "radio",
      options: [
        { value: "0", label: "正常" },
        { value: "1", label: "停用" },
      ],
    },
  ];
  const modalMenuForm = [
    ...modalForm.slice(0, 5),
    ...[
      {
        label: "组件路径",
        name: "component",
        type: "input",
      },
      {
        label: "权限字符",
        name: "perms",
        type: "input",
      },
      {
        label: "是否缓存",
        name: "isCache",
        type: "radio",
        options: [
          { value: "0", label: "缓存" },
          { value: "1", label: "不缓存" },
        ],
      },
    ],
    ...modalForm.slice(5),
  ];

  const modalComponentForm = [
    {
      label: "上级菜单",
      name: "menuId",
      type: "treeSelect",
      params: {
        placeholder: "请选择",
        disabled: !!modalFormData.menuId,
        treeData: [
          { menuName: "主目录", menuId: 0, parentId: "", children: pageData },
        ],
        fieldNames: {
          label: "menuName",
          value: "menuId",
          children: "children",
        },
      },
      width: '460px',
      notShow: modalStr === "编辑菜单",
    },
    {
      label: "菜单类型",
      name: "menuType",
      type: "radio",
      width: "100%",
      options: [
        { value: "M", label: "目录" },
        { value: "C", label: "菜单" },
        { value: "F", label: "按钮" },
      ],
      params: {
        onChange: menuTypeChange,
      },
    },
    {
      label: "菜单名称",
      name: "menuName",
      type: "input",
      rules: [{ required: true, message: "请填写菜单名称" }],
    },
    {
      label: "显示排序",
      name: "orderNum",
      type: "input",
      params: {
        type: "number",
      },
      rules: [{ required: true, message: "请填写排序" }],
    },
    {
      label: "权限字符",
      name: "perms",
      type: "input",
    },
  ];

  return (
    <div>
      <Modal
        open={open}
        title={modalStr}
        footer={<></>}
        width="32%"
        onCancel={resetForm}
        destroyOnClose
      >
        <PageTab
          subFoot
          search={addUser}
          reset={resetForm}
          formTab={
            menuType === "M"
              ? modalForm
              : menuType === "F"
              ? modalComponentForm
              : modalMenuForm
          }
          initialValues={modalFormData}
        ></PageTab>
      </Modal>
      <PageTab
        search={search}
        reset={(_) => getMenuList()}
        formTab={formTab}
        footer={footer}
      ></PageTab>
      <PageTable expandable rowKey="menuId" columns={columns} data={pageData}></PageTable>
    </div>
  );
};

export default MenuManager;
