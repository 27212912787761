import React, { useEffect, useRef, useState } from "react";
import PageTable from "@/components/i-pageTable/i-pageTable";
import PageTab from "@/components/i-pageTab/i-pageTab";
import {
  categoryList,
  categoryAdd,
  categoryEdit,
  categoryDetail,
} from "@/api/shopping";
import { Button, message, Modal, Space, Tag } from "antd";
import { resetForm, setIcons, showModal } from "@/utils/common";

const Classification = () => {
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectKeys, setSelectKeys] = useState<any[]>([]);
  const [pageData, setPageData] = useState<DictTypeListRes["rows"]>([]);
  const [formData, setFormData] = useState<any>({
    // status: "",
    ypageNum: 1,
    ypageSize: 10,
  });
  const [modalFormData, setModalFormData] = useState<DictTypeAddParams>({
    // status: "0",
  });

  const formTab = [
    {
      label: "分类名称",
      name: "categoryName",
      type: "input",
    },
    // {
    //   label: "状态",
    //   name: "status",
    //   type: "select",
    //   options: [
    //     { value: "0", label: "正常" },
    //     { value: "1", label: "停用" },
    //   ],
    // },
    // {
    //   label: "创建时间",
    //   name: "rangePicker",
    //   type: "rangePicker",
    //   width: "330px",
    // },
  ];

  const footer = [
    {
      title: "新增",
      icon: setIcons("PlusOutlined"),
      onClick: () => {
        handleAdd();
      },
    },
    // {
    //   title: "停用",
    //   icon: setIcons("DeleteOutlined"),
    //   disabled: !selectKeys.length,
    //   onClick: () => {
    //     // handleDelete(selectKeys);
    //   },
    // },
  ];

  const modalFormTab = [
    {
      label: "分类名称",
      name: "categoryName",
      type: "input",
      width: "300px",
      rules: [{ required: true, message: "请填分类名称" }],
    },
    // {
    //   label: "字典类型",
    //   name: "dictType",
    //   type: "input",
    //   width: "300px",
    //   rules: [{ required: true, message: "请填写字典类型" }],
    // },
  ];

  const columns = [
    {
      title: "顺序",
      dataIndex: "sort",
      key: "sort",
    },
    {
      title: "分类Id",
      dataIndex: "categoryId",
      key: "categoryId",
    },
    {
      title: "分类名称",
      dataIndex: "categoryName",
      key: "categoryName",
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (val: string) => (
        <Tag color="blue">{val === "0" ? "正常" : "停用"}</Tag>
      ),
    },
    {
      title: "更新时间",
      dataIndex: "updateTime",
      key: "updateTime",
    },
    {
      title: "操作",
      dataIndex: "categoryId",
      key: "categoryId",
      render: (val: string, rows: any) =>
        rows.status !== "1" && (
          <Space size="small">
            <Button
              size="small"
              icon={setIcons("EditOutlined")}
              type="text"
              onClick={() => {
                handleEdit(val);
              }}
            >
              修改
            </Button>
            <Button
              size="small"
              icon={setIcons("DisconnectOutlined")}
              type="text"
              onClick={() => {
                handleDelete(rows);
              }}
            >
              停用
            </Button>
          </Space>
        ),
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  useEffect(() => {}, []);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await categoryList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const search = (val: any) => {
    setFormData({ ...formData, ...val, ypageNum: 1 });
  };
  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };

  const handleAdd = () => {
    setOpen(true);
  };

  const reset = () => {
    setFormData({ ...resetForm(formData) });
    setSelectKeys([]);
  };
  const confirmData = async (val: any) => {
    try {
      if (val.categoryId) {
        await categoryEdit(val);
        message.success("编辑成功");
      } else {
        await categoryAdd(val);
        message.success("新增成功");
      }
      modalReset();
      getPageData();
    } catch (error) {}
  };
  const modalReset = () => {
    setModalFormData({});
    setOpen(false);
  };
  const selectionChange = (keys: any[]) => {
    setSelectKeys(keys);
  };

  const handleEdit = async (val: string) => {
    try {
      const { data } = await categoryDetail(val);
      setModalFormData(data);
      setOpen(true);
    } catch (error) {}
  };

  const handleDelete = async (val: any) => {
    try {
      const data = await showModal({
        title: "提示",
        content: "确定要停用？",
      });
      if (data === "ok") {
        await categoryEdit({
          ...val,
          status: "1",
        });
        message.success("停用成功");
        modalReset();
        getPageData();
      }
    } catch (error) {}
  };

  return (
    <div>
      <Modal
        destroyOnClose
        open={open}
        title={modalFormData.dictTypeId ? "编辑商品分类" : "新增商品分类"}
        width="24%"
        footer={<></>}
        onCancel={modalReset}
      >
        <PageTab
          subFoot
          search={confirmData}
          reset={modalReset}
          formTab={modalFormTab}
          initialValues={modalFormData}
        ></PageTab>
      </Modal>
      <PageTab
        reset={reset}
        search={search}
        formTab={formTab}
        footer={footer}
        defaultTimer={[]}
      ></PageTab>
      <PageTable
        selection
        selectionChange={selectionChange}
        selectedRowKeys={selectKeys}
        loading={loading}
        rowKey="categoryId"
        pageChange={pageChange}
        data={pageData}
        columns={columns}
        pagination={{
          total: total,
          current: formData.ypageNum,
        }}
      ></PageTable>
    </div>
  );
};

export default Classification;
