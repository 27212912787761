import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { dictBaseList } from "@/api/index";
const initialState: {state: Record<string, any>} = {
  state: {},
};

export const dictInfo = createSlice({
  name: "dictInfo",
  initialState,
  reducers: {
    setDictInfo: (state, action) => {
      if(action?.payload?.type){
        state.state[action.payload.type] = action?.payload?.data
      }
    },
  },
});

export const { setDictInfo } = dictInfo.actions;

export const dictInfoAsync = createAsyncThunk(
  "data/dictInfoAsync",
  async (type: string, {dispatch}) => {
    try {
      const {data: res} = await dictBaseList(type) as any;
     const data = res?.map((i: any)=> {
        return {
          label: i.dictLabel,
          value: i.dictValue,
        }
      })
      dispatch(setDictInfo({data, type}));
    } catch (error) {}
  }
);
export default dictInfo.reducer;
