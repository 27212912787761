import { Button, message, Modal, Space, Tag } from "antd";
import React, { useEffect, useRef, useState } from "react";
import PageTab from "@/components/i-pageTab/i-pageTab";
import PageTable from "@/components/i-pageTable/i-pageTable";
import { setIcons, showModal } from "@/utils/common";
import {
  allocatedList,
  unallocatedList,
  selectAll,
  authUserCancel,
} from "@/api/setting";
import { useLocation, useParams } from "react-router-dom";

const ScopeRole = (props: any) => {
  const params = useParams();
  const roleId = Number(params.id) ;
  // const location = useLocation();

  const [pageData, setPageData] = useState<any[]>([]);
  const [userPageData, setUserPageData] = useState<any[]>([]);
  const [changeKeys, setChangeKeys] = useState<any[]>([]);

  const [open, setOpen] = useState(false);

  const [formData, setFormData] = useState({
    phoneNumber: "",
    userName: "",
    ypageNum: 1,
    ypageSize: 15,
  });
  const [modalFormData, setModalFormData] = useState({
    phoneNumber: "",
    userName: "",
    ypageNum: 1,
    ypageSize: 15,
  });

  const formTab = [
    {
      label: "用户名称",
      name: "userName",
      type: "input",
    },
    {
      label: "手机号码",
      name: "phoneNumber",
      type: "input",
    },
  ];

  const footer = [
    {
      title: "添加用户",
      icon: setIcons("PlusOutlined"),
      onClick: () => addUser(),
    },
  ];

  const columns = [
    {
      title: "用户名称",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "用户昵称",
      dataIndex: "nickName",
      key: "nickName",
    },
    {
      title: "邮箱",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "手机号",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (val: string) => (
        <Tag color="blue">{val === "0" ? "正常" : "停用"}</Tag>
      ),
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "操作",
      dataIndex: "userId",
      key: "userId",
      fixed: "right",
      width: "150px",
      render: (val: number) => (
        <Space>
          <Button
            size="small"
            icon={setIcons("CloseCircleOutlined")}
            type="text"
            onClick={() => cancel(val)}
          >
            取消授权
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  useEffect(() => {
    getUserPageData();
  }, [modalFormData]);

  const getPageData = async () => {
    const data = await allocatedList({ ...formData, roleId:roleId });
    setPageData(data.rows);
  };
  const search = (val: any) => {
    setFormData({ ...formData, ...val });
  };
  const searchUser = (data: any) => {
    setModalFormData({ ...modalFormData, ...data });
  };
  const resetUser = () => {
    setModalFormData({
      phoneNumber: "",
      userName: "",
      ypageNum: 1,
      ypageSize: 15,
    });
  };

  const addUser = async () => {
    getUserPageData();
    setOpen(true);
  };
  const submit = async () => {
    if (!changeKeys.length) return message.error("请选择要分配的用户");
    try {
      let str = ''
      changeKeys.map((i,v)=> str+= `${i}${v < changeKeys.length - 1 ? ',' : ''}`)
      await selectAll({ userIds: str, roleId });
      message.success("分配成功");
      setOpen(false);
      getPageData();
    } catch (error) {}
  };

  const getUserPageData = async () => {
    try {
      const data = await unallocatedList({
        ...modalFormData,
        roleId,
      });
      setUserPageData(data.rows);
    } catch (error) {}
  };

  const selectionChange = (keys: any[]) => {
    setChangeKeys(keys);
  };
  const cancel = async (userId: number) => {
    try {
      const data = await showModal({
        title: "系统提示",
        content: "确认要取消选择的角色吗？",
      });
      if (data === "ok") {
        await authUserCancel({
          roleId,
          userId,
        });
        message.success("取消授权成功");
        getPageData();
      }
    } catch (error) {}
  };

  const reset = () => {
    setFormData({
      phoneNumber: "",
      userName: "",
      ypageNum: 1,
      ypageSize: 15,
    });
  };
  return (
    <div>
      <Modal
        destroyOnClose
        open={open}
        title="选择用户"
        width="48%"
        onOk={submit}
        onCancel={(_) => setOpen(false)}
      >
        <PageTab
          search={searchUser}
          reset={resetUser}
          formTab={formTab}
        ></PageTab>
        <PageTable
          selection
          selectionChange={selectionChange}
          rowKey="userId"
          columns={columns.slice(0, columns.length - 1)}
          data={userPageData}
        ></PageTable>
      </Modal>
      <PageTab
        search={search}
        reset={reset}
        formTab={formTab}
        footer={footer}
      ></PageTab>
      <PageTable rowKey="userId" columns={columns} data={pageData}></PageTable>
    </div>
  );
};

export default ScopeRole;
