import React, { useEffect, useState } from "react";
import PageTable from "@/components/i-pageTable/i-pageTable";
import PageTab from "@/components/i-pageTab/i-pageTab";
import {
  cashOrderList,
  cashOrderAudit,
  getTLBalance,
  pullTLStatus,
  merCashOrderExport,
  getMerTLBalance,
} from "@/api/merchant";
import { Button, message, Modal, Space, Tag, Image, Input } from "antd";
import {
  customRequest,
  downloadFile,
  getTimeFormat,
  normFile,
  setIcons,
  showModal,
} from "@/utils/common";
import { useSelector, useDispatch } from "react-redux";
import { dictInfoAsync } from "@/stores/dictInfo";

const WithdrawalManager = () => {
  const dispatch = useDispatch();
  const {
    state: { approval_state },
  } = useSelector((state: { dictInfo: any }) => state.dictInfo);
  const {
    state: { ns_channel_type },
  } = useSelector((state: { dictInfo: any }) => state.dictInfo);

  const [total, setTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(false);
  
  const [exportLoading, setExportLoading] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalFormData, setModalFormData] = useState<{
    settleAmount: number | string;
    tlAmount: number | string;
    listVoucherUrl?: any[];
  }>({
    settleAmount: 0,
    tlAmount: 0,
    listVoucherUrl: []
  });

  const [selectData, setSelectData] =
    useState<SelectCmsMerCashOrderPageRes["rows"][0]>();

  const [pageData, setPageData] = useState<
    SelectCmsMerCashOrderPageRes["rows"]
  >([]);
  const [formData, setFormData] = useState<SelectCmsMerCashOrderPageParams>({
    endDate: "",
    startDate: "",
    status: "",
    merMobile: "",
    ypageSize: 10,
    ypageNum: 1,
  });

  const formTab = [
    {
      label: "商户手机号",
      name: "merMobile",
      type: "input",
    },
    {
      label: "提现状态",
      name: "status",
      type: "select",
      options: approval_state,
    },
    {
      label: "时间范围",
      name: "rangePicker",
      type: "rangePicker",
      width: "380px",
    },
  ];

  const footer = [
    {
      title: "导出",
      icon: setIcons("DownloadOutlined"),
      onClick: () => exportExcel(),
      loading: exportLoading,
    },
    {
      title: "通联备付金查询",
      onClick: async () => {
        try {
          const { data } = await getTLBalance();
          showModal({
            title: "通联备付金查询",
            content: <div>通联备付金：{data}</div>,
            footer: null,
          });
        } catch (error) {}
      },
    },
  ];

  const modalFormTab = [
    {
      label: "打款凭证",
      name: "listVoucherUrl",
      type: "upload",
      labelCol: { span: 0, offset: 1 },
      width: "520px",
      getValueFromEvent: normFile,
      params: {
        maxCount: 4,
        fileList: modalFormData.listVoucherUrl,
        beforeUpload: () => {
          setUploadProgress(true);
        },
        uploadMessage: uploadProgress ? (
          <div>
            {setIcons("LoadingOutlined")}
            <div>上传中...</div>
          </div>
        ) : (
          <div>
            {setIcons("PlusOutlined")}
            <div style={{ marginTop: 8 }}>上传</div>
          </div>
        ),
        customRequest: async (e: any) => {
          try {
            const data = await customRequest(
              e,
              modalFormData.listVoucherUrl as any[]
            );
            setModalFormData({
              ...modalFormData,
              listVoucherUrl: data as any,
            });
          } catch (error) {
          } finally {
            setUploadProgress(false);
          }
        },
        onRemove: (e: any) => {
          setModalFormData({
            ...modalFormData,
            listVoucherUrl: modalFormData.listVoucherUrl?.filter(
              (i) => i.uid !== e.uid
            ),
          });
        },
      },
    },
    {
      label: "申请提现金额",
      name: "",
      type: "text",
      params: {
        text: selectData?.amount,
      },
    },
    {
      label: "提现金额",
      name: "settleAmount",
      type: "input",
      labelCol: { span: 0, offset: 1 },
      rules: [{ required: true, message: "请输入提现金额" }],
      params: {
        type: "number",
      },
    },
    {
      label: "提现手续费",
      name: "",
      type: "text",
      params: {
        text: "0.38%",
        style: { color: "red" },
      },
    },
    {
      label: "纳税渠道",
      name: "nsChannelType",
      labelCol: { span: 0, offset: 1 },
      type: "select",
      rules: [{ required: true, message: "请选择纳税渠道" }],
      options: ns_channel_type,
    },
    {
      label: "可提现余额",
      name: "tlAmount",
      type: "input",
      help:
        modalFormData.settleAmount > modalFormData.tlAmount
          ? "商家通联可提现余额不足"
          : "",
      validateStatus:
        modalFormData.settleAmount > modalFormData.tlAmount
          ? "error"
          : ("validating" as any),
      params: {
        disabled: true,
      },
      labelCol: { span: 0, offset: 1 },
    },
  ];

  const columns = [
    {
      title: "订单号",
      dataIndex: "cashOrderId",
      key: "cashOrderId",
      width: "220px",
    },
    {
      title: "商户名称",
      dataIndex: "merName",
      key: "merName",
      width: "220px",
    },
    {
      title: "商户ID",
      dataIndex: "merId",
      key: "merId",
      width: "220px",
    },
    {
      title: "商家手机号",
      dataIndex: "merMobile",
      key: "merMobile",
    },
    {
      title: "开户银行",
      dataIndex: "bankName",
      key: "bankName",
    },
    {
      title: "收款账号",
      dataIndex: "bankNo",
      key: "bankNo",
    },
    {
      title: "收款户名",
      dataIndex: "merRealName",
      key: "merRealName",
    },

    {
      title: "用户提现金额",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "真实到账金额",
      dataIndex: "settleAmount",
      key: "settleAmount",
    },
    {
      title: "平台服务费",
      dataIndex: "disparityAmount",
      key: "disparityAmount",
    },
    {
      title: "认证状态",
      dataIndex: "tlAuthStatus",
      key: "tlAuthStatus",
      render: (val: string) =>
        val ? (
          <Tag color="blue">
            {val === "2" ? "成功" : val === "3" ? "失败" : " "}
          </Tag>
        ) : (
          ""
        ),
    },
    {
      title: "驳回原因",
      dataIndex: "rejectReason",
      key: "rejectReason",
      width: "220px",
    },
    {
      title: "审核失败原因",
      dataIndex: "thirdMsg",
      key: "thirdMsg",
    },
    {
      title: "审核通过时间",
      dataIndex: "checkTime",
      key: "checkTime",
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "打款凭证",
      dataIndex: "voucherUrlList",
      key: "voucherUrlList",
      render: (val: string[]) =>
        val?.length ? (
          <div
            style={{ color: "#1890ff", cursor: "pointer" }}
            onClick={(_) => {
              showModal({
                title: "打款凭证",
                content: (
                  <Space wrap>
                    {val?.map((i: string) => (
                      <Image key={i} width={120} src={i}></Image>
                    ))}
                  </Space>
                ),
                footer: null,
              });
            }}
          >
            查看详情
          </div>
        ) : (
          <></>
        ),
    },

    {
      title: "提现渠道",
      dataIndex: "nsChannelTypeLabel",
      key: "nsChannelTypeLabel",
      fixed: "right",
      render: (val: string) => (val ? <Tag color="blue">{val}</Tag> : ""),
    },
    {
      title: "提现状态",
      dataIndex: "status",
      key: "status",
      fixed: "right",
      render: (val: string) => <Tag color="blue">{approval_state.find((i:{ value: string})=>i.value === val)?.label}</Tag>,
    },
    {
      title: "操作",
      dataIndex: "cashOrderId",
      key: "cashOrderId",
      fixed: "right",
      width: "120px",
      render: (val: string, rows: SelectCmsMerCashOrderPageRes["rows"][0]) => (
        <Space size="small">
          {rows.status === "10000001" || rows.status === "10000006" ? (
            <Button
              size="small"
              icon={setIcons("CheckCircleOutlined")}
              type="text"
              onClick={async (e) => {
                try {
                  const { data } = await getMerTLBalance(rows.merId);
                  setModalFormData({
                    settleAmount: (rows.amount - rows.amount * 0.0038).toFixed(
                      2
                    ),
                    tlAmount: data,
                    listVoucherUrl: []
                  });
                } catch (error) {
                  setModalFormData({
                    settleAmount: (rows.amount - rows.amount * 0.0038).toFixed(
                      2
                    ),
                    tlAmount: 0,
                    listVoucherUrl: []
                  });
                }
                setSelectData(rows);
                setOpen(true);
              }}
            >
              通过
            </Button>
          ) : (
            <></>
          )}
          {rows.status === "10000001" || rows.status === "10000006" ? (
            <Button
              size="small"
              icon={setIcons("StopOutlined")}
              type="text"
              onClick={(e) => {
                setRejectOpen(true);
                setSelectData(rows);
              }}
            >
              拒绝
            </Button>
          ) : (
            <></>
          )}
          {rows.status === "10000005" ? (
            <Button
              size="small"
              icon={setIcons("ReloadOutlined")}
              type="text"
              onClick={async (e) => {
                try {
                  await pullTLStatus(val);
                  message.success("同步状态成功！");
                  getPageData();
                } catch (error) {}
              }}
            >
              同步
            </Button>
          ) : (
            <></>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  useEffect(() => {
    !approval_state && dispatch(dictInfoAsync("approval_state") as any);
    !ns_channel_type && dispatch(dictInfoAsync("ns_channel_type") as any);
  }, []);

  const confirmData = async (val: any) => {
    const params = {
      cashOrderId: selectData?.cashOrderId,
      nsChannelType: val.nsChannelType,
      settleAmount: val.settleAmount,
      status: "10000003",
      listVoucherUrl: val?.listVoucherUrl?.map((i: { url: string }) => i.url),
    };
    if (val.nsChannelType === "10190009" && !params.listVoucherUrl?.length) {
      message.error("请上传打款凭证");
      return;
    }
    try {
      await cashOrderAudit(params);
      message.success("提现申请成功");
      setOpen(false);
      getPageData();
    } catch (error) {}
  };

  const exportExcel = async () => {
    setExportLoading(true);
    try {
      const data = await merCashOrderExport(formData);
      downloadFile(
        data as any,
        "商户提现订单" + getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss")
      );
    } catch (error) {
    } finally {
      setExportLoading(false);
    }
  };

  const confirmRejectData = async (val: { rejectReason: string }) => {
    const params = {
      cashOrderId: selectData?.cashOrderId,
      rejectReason: val.rejectReason,
      status: "10000004",
    };
    try {
      await cashOrderAudit(params);
      message.success("驳回成功！");
      setRejectOpen(false);
      getPageData();
    } catch (error) {}
  };

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await cashOrderList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const search = (val: any) => {
    setFormData({ ...formData, ...val });
  };
  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };

  const reset = () => {
    setFormData({
      endDate: "",
      startDate: "",
      status: "",
      ypageNum: 1,
      ypageSize: 10,
    });
  };

  return (
    <div>
      <Modal
        destroyOnClose
        open={rejectOpen}
        title="驳回"
        width="20%"
        footer={<></>}
        onCancel={(_) => setRejectOpen(false)}
      >
        <PageTab
          subFoot
          search={confirmRejectData}
          reset={(_) => setRejectOpen(false)}
          formTab={[
            {
              label: "驳回原因",
              name: "rejectReason",
              type: "textArea",
              rules: [{ required: true, message: "请输入驳回原因" }],
            },
          ]}
        ></PageTab>
      </Modal>
      <Modal
        destroyOnClose
        open={open}
        title="通过提现"
        width="28%"
        footer={<></>}
        onCancel={(_) => setOpen(false)}
      >
        <div style={{ color: "#fc4b44", marginBottom: "12px" }}>
          请务必仔细确认提现商家的商家信息、开户信息、认证信息无误后，为其进行手动打款并上传具有法律效应的打款凭证图片。
        </div>
        <PageTab
          subFoot
          search={confirmData}
          reset={(_) => setOpen(false)}
          formTab={modalFormTab}
          initialValues={modalFormData}
        ></PageTab>
      </Modal>
      <PageTab
        reset={reset}
        search={search}
        formTab={formTab}
        defaultTimer={[]}
        footer={footer}
      ></PageTab>
      <PageTable
        rowKey="cashOrderId"
        pageChange={pageChange}
        data={pageData}
        columns={columns}
        loading={loading}
        pagination={{
          total: total,
          current: formData.ypageNum,
        }}
      ></PageTable>
    </div>
  );
};

export default WithdrawalManager;
