import http from "../utils/request";

// 商户提现列表分页查询
export const cashOrderList = (
  params: SelectCmsMerCashOrderPageParams
): Promise<SelectCmsMerCashOrderPageRes> => {
  return http.post("/cms/auth/mer/cash/order/list", params);
};

// 商户提现审核
export const cashOrderAudit = (
  params: CmsCashOrderAuditParams
): Promise<CmsCashOrderAuditRes> => {
  return http.post("/cms/auth/mer/cash/order/audit", params);
};

// 获取通联可提现余额
export const getTLBalance = (): Promise<CmsCashOrderAuditRes> => {
  return http.get("/cms/auth/mer/cash/order/getTLBalance");
};

// 商户--门店订单列表分页查询
export const merOrderList = (
  params: SelectCmsMerOrderPageParams
): Promise<SelectCmsMerOrderPageRes> => {
  return http.post("/cms/auth/mer/order/list", params);
};

// 商户--商户门店订单导出
export const merOrderExport = (
  params: SelectCmsMerOrderPageParams
): Promise<defaultRes> => {
  return http.post("/cms/auth/mer/order/merOrderExport", params,{responseType: "blob"});
};


// 订单核销
export const merOrderCmsUse = (merOrderId: string): Promise<defaultRes> => {
  return http.post(`/cms/auth/mer/order/cmsUse`, merOrderId, {
    headers: { "Content-Type": "text/plain" },
  });
};
// CMS使用——订单退款审核
export const cmsConfirmRefund = (params: {
  orderId: string;
  refundAmount: number;
  refundReason: string;
}): Promise<defaultRes> => {
  return http.post("/cms/auth/mer/order/cmsConfirmRefund", params);
};

// 商户门店列表分页查询
export const storeList = (
  params: SelectCmsMerStorePageParams
): Promise<SelectCmsMerStorePageRes> => {
  return http.post("/cms/auth/mer/store/list", params);
};

// 商户门店列表分页查询
export const storeDetail = (
  storeId : string
): Promise<GetDetailRes> => {
  return http.post(`/cms/auth/mer/store/detail/${storeId}`);
};


// 商户提现状态同步
export const pullTLStatus = (cashOrderId: string): Promise<defaultRes> => {
  return http.get(
    `/cms/auth/mer/cash/order/pullTLStatus?cashOrderId=${cashOrderId}`
  );
};
// 商户提现状态同步
export const merCashOrderExport = (
  params: MerCashOrderExportParams
): Promise<defaultRes> => {
  return http.post(`/cms/auth/mer/cash/order/merCashOrderExport`, params, {
    responseType: "blob",
  });
};

// 获取商户通联可提现余额
export const getMerTLBalance = (merId: string): Promise<defaultRes> => {
  return http.get(`/cms/auth/mer/cash/order/getMerTLBalance?merId=${merId}`);
};

// 商户列表分页查询
export const merInfoList = (
  params: SelectCmsMerchantInfoPageParams
): Promise<SelectCmsMerchantInfoPageRes> => {
  return http.post(`/cms/auth/mer/info/list`, params);
};

// 商户信息修改
export const merchantsEdit = (
  params: MerchantsEditParams
): Promise<defaultRes> => {
  return http.post(`/cms/auth/mer/info/merchantsEdit`, params);
};

// 商户入驻审核
export const merchantsAudit = (params: {
  entryRemark?: string;
  entryStatus: string;
  merId: string;
}): Promise<defaultRes> => {
  return http.post(`/cms/auth/mer/info/merchantsAudit`, params);
};

// 修改商户状态
export const updateMerchantsStatus = (params: {
  id: string;
  status: string;
}): Promise<defaultRes> => {
  return http.post(`/cms/auth/mer/info/updateMerchantsStatus`, params);
};
// 商户绑定俱乐部
export const merchantsClubBind = (params: {
  merchantId: string;
  operationId: string;
}): Promise<defaultRes> => {
  return http.post(`/cms/auth/mer/info/merchantsClubBind`, params);
};

// 商户解绑俱乐部
export const merDelete = (
  merchantId: string | string[]
): Promise<defaultRes> => {
  return http.delete(`/cms/auth/mer/info/merchantsClubBind/${merchantId}`);
};

// CMS使用——订单分佣
export const getOrderReward = (orderId: string): Promise<GetOrderRewardRes> => {
  return http.get(`/cms/auth/mer/order/getOrderReward?orderId=${orderId}`);
};

// 商户详情
export const getMerDetail = (merId: string): Promise<GetMerInfoDRes> => {
  return http.get(`/cms/auth/mer/info/detail/${merId}`);
};


