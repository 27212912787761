import React, { useEffect, useRef, useState } from "react";
import PageTab from "@/components/i-pageTab/i-pageTab";
import PageTable from "@/components/i-pageTable/i-pageTable";
import {
  appUserInfoList,
  appDetail,
  appEditUser,
  appUserInfoDel,
  appUserInfoResetAppUserPwd,
  getOperationUserDetail,
  getOperationAuthDetail,
  listUserAccountChangesById,
  appEditAccount,
} from "@/api/operation";
import { Button, message, Modal, Space, Switch, Tag } from "antd";
import {
  customRequest,
  setIcons,
  normFile,
  resetForm,
  getTimeFormat,
  getFirstDay,
  showModal,
} from "@/utils/common";
import { useSelector, useDispatch, useStore } from "react-redux";
import { dictInfoAsync } from "@/stores/dictInfo";
import IForm from "@/components/i-form/i-form";
import { decimalReg } from "@/utils/regs";

const urlTypeList = [
  { label: "内部", value: "0" },
  { label: "h5", value: "1" },
  { label: "小程序", value: "2" },
];

const AppUserInfo = () => {
  const dispatch = useDispatch();
  const {
    state: { providers_level, account_changes_business_type },
  } = useSelector((state: { dictInfo: any }) => state.dictInfo);
  const [pageData, setPageData] = useState<SelectCmsAppUserPageRes["rows"]>([]);
  const [userOutPageData, setUserOutPageData] = useState<
    ListUserAccountChangesByIdRes["rows"]
  >([]);
  const [mainPicUploadProgress, setMainPicUploadProgress] = useState(false);

  const [total, setTotal] = useState(0);
  const [userOutTotal, setUserOutTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [userOutopen, setuserOutOpen] = useState(false);
  const [eidtUserOpen, setEidtUserOpen] = useState(false);
  
  const [modalFormData, setModalFormData] = useState<pageHomeAddParams>({
    accountType: "3",
  });

  const [formData, setFormData] = useState<SelectCmsAppUserPageParams>({
    startDate: '',
    endDate: '',
    invitation: "",
    labelName: "",
    mobile: "",
    recommenderPhone: "",
    status: "",
    userId: "",
    ypageNum: 1,
    ypageSize: 10,
  });

  const [userOutFormData, setUserOutFormData] = useState({
    userId: "",
    ypageNum: 1,
    ypageSize: 10,
  });

  const [modalFormuserEdit, setModalFormuserEdit] = useState({
    avatar: [],
  });

  const userEditFormTab = [
    {
      label: "头像",
      name: "avatar",
      type: "upload",
      labelCol1: { span: 0, offset: 3 },
      getValueFromEvent: normFile,
      params: {
        maxCount: 1,
        fileList: modalFormuserEdit.avatar,
        beforeUpload: () => {
          setMainPicUploadProgress(true);
        },
        uploadMessage: mainPicUploadProgress ? (
          <div>
            {setIcons("LoadingOutlined")}
            <div>上传中...</div>
          </div>
        ) : (
          <div>
            {setIcons("PlusOutlined")}
            <div style={{ marginTop: 8 }}>上传</div>
          </div>
        ),
        customRequest: async (e: any) => {
          try {
            const data = await customRequest(e, modalFormuserEdit.avatar as any[]);
  
            setModalFormuserEdit({
              ...modalFormuserEdit,
              avatar: data as any,
            });
          } catch (error) {
          } finally {
            setMainPicUploadProgress(false);
          }
        },
        onRemove: () => {
          setModalFormuserEdit({
            ...modalFormData,
            avatar: [],
          });
        },
      },
    },
    {
      label: "用户昵称",
      name: "nickName",
      type: "input",
      labelCol1: { span: 0, offset: 1 },
      rules: [{ required: true, message: "请输入昵称" }],
    },{
      label: "个性签名",
      name: "personalizedSignature",
      type: "input",
    },
     {
      label: "用户等级",
      name: "userLevel",
      type: "select",
      labelCol1: { span: 0, offset: 1 },
      options: providers_level,
    },
    // {
    //   label: "状态",
    //   name: "userStatus",
    //   type: "radio",
    //   labelCol1: { span: 0, offset: 1 },
    //   options: [
    //     {
    //       label: "正常",
    //       value: "0",
    //     },
    //     {
    //       label: "用",
    //       value: "1",
    //     },
    //   ],
    // },
    {
      label: "用户类型",
      name: "userType",
      type: "radio",
      labelCol1: { span: 0, offset: 1 },
      options: [
        {
          label: "管理",
          value: "0",
        },
        {
          label: "普通",
          value: "1",
        },
      ],
    },
    {
      label: "是否可提现",
      name: "withdrawStatus",
      type: "radio",
      labelCol1: { span: 0, offset: 1 },
      options: [
        {
          label: "是",
          value: "0",
        },
        {
          label: "否",
          value: "1",
        },
      ],
    }
  ];

  const modalFormTab = [
    {
      label: "数额方式",
      name: "accountTrend",
      type: "select",
      labelCol1: { span: 0, offset: 1 },
      rules: [{ required: true, message: "请选择类型" }],
      options: [
        {
          label: "赚取",
          value: "1",
        },
        {
          label: "消耗/扣减",
          value: "2",
        },
      ],
    },
    // {
    //   label: "账户类型",
    //   name: "accountType",
    //   type: "select",
    //   labelCol1: { span: 0, offset: 1 },
    //   options: [
    //     {
    //       label: "提现类型",
    //       value: "0",
    //     },
    //     {
    //       label: "转账类型",
    //       value: "1",
    //     },
    //   ],
    // },
    {
      label: "添加类型",
      name: "accountType",
      type: "select",
      rules: [{ required: true, message: "请选择类型" }],
      labelCol1: { span: 0, offset: 1 },
      options: [
        {
          label: "金额",
          value: "1",
        },
        // {
        //   label: "积分",
        //   value: "2",
        // },
        {
          label: "转赠金额",
          value: "3",
        }
      ],
    },
    {
      label: "数额",
      name: "amount",
      type: "input",
      rules: [
        { required: true, message: "请输入数额" },
        {
          pattern: decimalReg,
          message: "请输入正确数额",
        },
      ],
    },
    // {
    //   label: "是否展示",
    //   name: "status",
    //   type: "radio",
    //   labelCol1: { span: 0, offset: 1 },
    //   options: [
    //     {
    //       label: "展示",
    //       value: "0",
    //     },
    //     {
    //       label: "不展示",
    //       value: "1",
    //     },
    //   ],
    // },
  ];

  const formTab = [
    {
      label: "账号",
      name: "mobile",
      type: "input",
    },
    {
      label: "用户昵称",
      name: "nickName",
      type: "input",
      width: "280px",
    },
    {
      label: "推荐人手机号",
      name: "recommendMobile",
      type: "input",
      width: "280px",
    },
    {
      label: "用户等级",
      name: "userLevel",
      type: "select",
      options: providers_level,
    },

    // {
    //   label: "账号状态",
    //   name: "userStatus",
    //   type: "select",
    //   options: [
    //     {
    //       label: "正常",
    //       value: "0",
    //     },
    //     {
    //       label: "禁用",
    //       value: "1",
    //     },
    //   ],
    // },
    {
      label: "用户类型",
      name: "userType",
      type: "select",
      options: [
        {
          label: "管理",
          value: "0",
        },
        {
          label: "普通",
          value: "1",
        },
      ],
    },
    // {
    //   label: "时间范围",
    //   name: "rangePicker",
    //   type: "rangePicker",
    //   width: "380px",
    // },
  ];

  const userOutcolumns = [
    {
      title: "业务id",
      dataIndex: "businessId",
      key: "businessId",
      width: "220px",
    },
    {
      title: "业务表指向",
      dataIndex: "accountChangesBusinessType",
      key: "accountChangesBusinessType",
      render: (val: string) => (
        <Tag color="blue">
          {
            account_changes_business_type?.find(
              (i: { value: string }) => i.value === val
            )?.label
          }
        </Tag>
      ),
    },
    {
      title: "资金走向类型",
      dataIndex: "accountTrend",
      key: "accountTrend",
      render: (val: string) => (
        <Tag color="blue">{val === "10004001" ? "存入" : "支出"}</Tag>
      ),
    },
    {
      title: "虚拟金变动前金额",
      dataIndex: "beforeAccountAmount",
      key: "beforeAccountAmount",
    },
    {
      title: "虚拟金交易金额",
      dataIndex: "accountAmount",
      key: "accountAmount",
    },
    {
      title: "虚拟金交易后金额",
      dataIndex: "afterAccountAmount",
      key: "afterAccountAmount",
    },
    {
      title: "佣金变动前金额",
      dataIndex: "beforeBrokerageAmount",
      key: "beforeBrokerageAmount",
    },
    {
      title: "佣金交易金额",
      dataIndex: "brokerageAmount",
      key: "brokerageAmount",
    },
    {
      title: "佣金变动后金额",
      dataIndex: "afterBrokerageAmount",
      key: "afterBrokerageAmount",
    },
    {
      title: "现金变动前资金",
      dataIndex: "beforeCashAmount",
      key: "beforeCashAmount",
    },

    {
      title: "现金交易金额",
      dataIndex: "cashAmount",
      key: "cashAmount",
    },
    {
      title: "现金变动后资金",
      dataIndex: "afterCashAmount",
      key: "afterCashAmount",
    },
    {
      title: "商家收益变动前资金",
      dataIndex: "beforeMerchantRevenue",
      key: "beforeMerchantRevenue",
    },

    {
      title: "商家收益交易金额",
      dataIndex: "merchantRevenue",
      key: "merchantRevenue",
    },

    {
      title: "商家收益变动后资金",
      dataIndex: "afterMerchantRevenue",
      key: "afterMerchantRevenue",
    },

    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
  ];

  const columns = [
    {
      title: "用户ID",
      dataIndex: "userBusinessId",
      key: "userBusinessId",
    },
    {
      title: "用户头像",
      dataIndex: "avatar",
      key: "avatar",
      render: (data: string) => data && <img style={{ height: "32px" }} src={data} alt="" />
    },
    {
      title: "昵称",
      dataIndex: "nickName",
      key: "nickName",
    },
    {
      title: "账号",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "注册方式",
      dataIndex: "userRegType",
      key: "userRegType",
      render: (val: number | string) => (
        <Tag color={val == 0 ? "blue" : "green"}>{val == 0 ? "手机号注册" : "邮箱注册"}</Tag>
      ),
    },
    {
      title: "金额",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "转赠金额",
      dataIndex: "transferAmount",
      key: "transferAmount",
    },
    {
      title: "积分",
      dataIndex: "integral",
      key: "integral",
    },
    {
      title: "个性签名",
      dataIndex: "personalizedSignature",
      key: "personalizedSignature",
    },
    // {
    //   title: "推荐人ID",
    //   dataIndex: "recommenderUserId",
    //   key: "recommenderUserId",
    // },
    {
      title: "推荐人Id",
      dataIndex: "recommendUserId",
      key: "recommendUserId",
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
      // render: (val: string) => <div>{getTimeFormat(val)}</div>
    },
    {
      title: "用户等级",
      dataIndex: "userLevel",
      key: "userLevel",
      render: (val: string) => (
        <Tag color="blue">
          {
            providers_level?.find((i: { value: string }) => i.value === val)
              ?.label
          }
        </Tag>
      ),
    },
    // {
    //   title: "状态",
    //   dataIndex: "userStatus",
    //   key: "userStatus",
    //   render: (val: string, rows: any) => (
    //     <Switch onChange={(e)=>changeUserStatus(e,rows)} checked={val === "0"} />
    //   ),
    // },
    {
      title: "用户类型",
      dataIndex: "userType",
      key: "userType",
      render: (val: string) => (
        <Tag color="blue">{val === "0" ? "管理" : "普通"}</Tag>
      ),
    },

    {
      title: "操作",
      dataIndex: "userId",
      key: "userId",
      fixed: "right",
      render: (val: string, rows: any) => (
        <Space>
          {rows.userStatus === "0" ? (
            <>
              <Button
                size="small"
                icon={setIcons("CloudUploadOutlined")}
                type="text"
                onClick={(e) => {
                  setOpen(true);
                  setModalFormData({
                    userId: val,
                  });
                }}
              >
                添加金额
              </Button>
              <Button
                size="small"
                icon={setIcons("SnippetsOutlined")}
                type="text"
                onClick={() => {
                  outDetail(val);
                }}
              >
                修改
              </Button>
              {/* <Button
                size="small"
                icon={setIcons("SyncOutlined")}
                type="text"
                onClick={(e) => {
                  resetMobileUser(val);
                }}
              >
                重置
              </Button> */}
              {/* <Button
                size="small"
                icon={setIcons("StopOutlined")}
                type="text"
                onClick={(e) => {
                  offUser(val);
                }}
              >
                注销
              </Button> */}
              {/* <Button
                size="small"
                icon={setIcons("ContactsOutlined")}
                type="text"
                onClick={() => {
                  authDetail(val);
                }}
              >
                认证信息
              </Button> */}
            </>
          ) : (
            <></>
          )}

          {/* <Button
            size="small"
            icon={setIcons("SnippetsOutlined")}
            type="text"
            onClick={() => {
              setuserOutOpen(true)
              setUserOutFormData({
                ...userOutFormData,
                userId: val,
              });
              // getUserOutList();
            }}
          >
            账目变动信息
          </Button> */}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getPageData();
    console.log(providers_level, 'providers_level');

  }, [formData]);

  useEffect(() => {
    userOutFormData.userId && getUserOutList();
  }, [userOutFormData]);

  useEffect(() => {
    !providers_level && dispatch(dictInfoAsync("providers_level") as any);
    
    !account_changes_business_type &&
      dispatch(dictInfoAsync("account_changes_business_type") as any);
  }, []);

  const changeUserStatus = async (e: any, rows: any) => {
    console.log(e, rows, 'e, rows');
    
    Modal.confirm({
      title: '系统提示',
      content: <div>是否{e ? "启用" : "禁用"}当前选中用户吗？</div>,
      onOk: async () => {
        await appEditUser({...rows, userStatus: e ? "0" : "1"})
        message.success(e ? "启用成功" : "禁用成功")
        getPageData()
      }
    })
  }

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await appUserInfoList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const search = (val: any) => {
    setFormData({
      ...formData,
      ...val,
      ypageNum: 1,
    });
  };

  const reset = () => {
    setFormData({
      ...resetForm(formData),
      startDate: '',
      endDate: '',
    });
  };
  const resetModal = () => {
    setModalFormData({
      accountType: "3",
    });
    setOpen(false);
  };

  const resetUserOut = () => {
    setUserOutFormData({
      userId: "",
      ypageNum: 1,
      ypageSize: 10,
    });
    setuserOutOpen(false);
  };

  const getUserOutList = async () => {
    try {
      const data = await listUserAccountChangesById({
        ypageNum: userOutFormData.ypageNum,
        ypageSize: userOutFormData.ypageSize,
        userId: userOutFormData.userId,
      });
      setUserOutPageData(data.rows);
      setUserOutTotal(data.total);
    } catch (error) {}
  };

  const confirmSend = async (val: any) => {
    try {
      await appEditAccount({
        ...val,
      });
      message.success("新增成功");
      resetModal();
      getPageData();
    } catch (error) {}
  };

  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };
  const userOutpageChange = (current: number, pageSize: number) => {
    setUserOutFormData({
      ...userOutFormData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };

  const offUser = async (userId: string) => {
    const data = await showModal({
      title: "系统提示",
      content: <div>是否注销当前选中用户吗？</div>,
    });
    if (data === "ok") {
      try {
        await appUserInfoDel(userId);
        message.success("用户注销成功");
        getPageData();
      } catch (error) {}
    }
  };
  const resetMobileUser = async (userId: string) => {
    const data = await showModal({
      title: "系统提示",
      content: <div>是否重置当前用户密码为手机号？</div>,
    });
    if (data === "ok") {
      try {
        await appUserInfoResetAppUserPwd(userId);
        message.success("用户密码重置成功");
        getPageData();
      } catch (error) {}
    }
  };

  const authDetail = async (val: string) => {
    try {
      const { data } = await getOperationAuthDetail(val);
      if (!data) {
        message.error("当前用户未认证通过");
        return;
      }
      Modal.info({
        width: "48%",
        okText: "好的",
        icon: null,
        content: (
          <IForm
            formTab={[
              {
                label: "身份证名",
                name: data.certName,
              },
              {
                label: "身份证号",
                name: data.certId,
              },
              {
                label: "身份证信息面",
                name: data.certBackUrl,
                type: "image",
              },
              {
                label: "身份证国徽面",
                name: data.certFrontUrl,
                type: "image",
              },
              {
                label: "实名认证状态",
                name: data.authStatus === "0" ? "已认证" : "未认证",
              },
            ]}
          ></IForm>
        ),
      });
    } catch (error) {}
  };

  const outDetail = async (val: string) => {
    try {
      const { data } = await appDetail(val);
      setModalFormuserEdit({...data,
        avatar: data.avatar ? [{ url: data.avatar, uid: data.avatar, name: "" }] : [],
      });
      setEidtUserOpen(true);
    } catch (error) {}
  };

  const confirmUser =async (val:any)=> {
    try {
     await appEditUser({...val, 
      avatar: val?.avatar?.[0]?.url
     })
      message.success('编辑成功！')
      setEidtUserOpen(false)
      getPageData();
    } catch (error) {
      
    }
  }

  const resetEditUser =()=> {
    setEidtUserOpen(false)
    setModalFormuserEdit({
      avatar: []
    })
  }

  return (
    <div>
      <Modal
        destroyOnClose
        open={eidtUserOpen}
        title="编辑用户信息"
        width="24%"
        footer={<></>}
        onCancel={resetEditUser}
      >
        <PageTab
          subFoot
          search={confirmUser}
          reset={resetEditUser}
          formTab={userEditFormTab}
          initialValues={modalFormuserEdit}
        ></PageTab>
      </Modal>
      <Modal
        destroyOnClose
        open={userOutopen}
        title="账目变动信息"
        width="66%"
        footer={<></>}
        onCancel={resetUserOut}
      >
        <PageTable
          loading={loading}
          pageChange={userOutpageChange}
          size="small"
          rowKey="changesId"
          columns={userOutcolumns}
          data={userOutPageData}
          pagination={{
            current: userOutFormData.ypageNum!,
            pageSize: userOutFormData.ypageSize!,
            total: userOutTotal,
          }}
        ></PageTable>
      </Modal>
      <Modal
        destroyOnClose
        open={open}
        title="添加金额"
        width="380px"
        footer={<></>}
        onCancel={resetModal}
      >
        <PageTab
          subFoot
          search={confirmSend}
          reset={resetModal}
          formTab={modalFormTab}
          initialValues={modalFormData}
        ></PageTab>
      </Modal>
      <PageTab
        search={search}
        reset={reset}
        formTab={formTab}
        // footer={footer}
      ></PageTab>
      <PageTable
        loading={loading}
        pageChange={pageChange}
        size="small"
        rowKey="userId"
        columns={columns}
        data={pageData}
        pagination={{
          current: formData.ypageNum!,
          pageSize: formData.ypageSize!,
          total: total,
        }}
      ></PageTable>
    </div>
  );
};

export default AppUserInfo;
