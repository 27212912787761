import {
  HashRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import React, { useState } from "react";
import { useSelector, useDispatch, useStore } from "react-redux";
import { setTagList, setKeyStr, setOpenKeys } from "@/stores/syncStore";
import styles from "@/static/css/index.module.css";

import UserManager from "../pages/setting/userManager";
import AllocationRole from "../pages/setting/userManager/allocationRole";
import MenuManager from "../pages/setting/menuManager";
import RoleManager from "../pages/setting/roleManager";
import ScopeRole from "../pages/setting/roleManager/scopeRole";
import DivisionManager from "@/pages/setting/divisionManager";
import PostManager from "@/pages/setting/postManager";
import Dict from "@/pages/setting/dict";
import DictType from "@/pages/setting/dictType";
import ConfigManager from "@/pages/setting/configManager";
import Operlog from "@/pages/setting/log/operlog";
import Logininfor from "@/pages/setting/log/logininfor";
import AreaManager from "@/pages/setting/areaManager";
import PayManager from "@/pages/setting/payManager";
import Agreement from "@/pages/setting/agreement";

import WithdrawalManager from "@/pages/merchant/withdrawalManager";
import MerchantOrderManager from "@/pages/merchant/merchantOrderManager";
import MerchantManager from "@/pages/merchant/merchantManager";
import MerInfo from "@/pages/merchant/merInfo";

import MerAndVipStatisticsList from "@/pages/statistics/merAndVipStatisticsList";
import MerOrderStatisticsList from "@/pages/statistics/merOrderStatisticsList";
import MerOrderWithOutOilStatisticsList from "@/pages/statistics/merOrderWithOutOilStatisticsList";
import TransferOrderManager from "@/pages/statistics/transferOrderManager";


import OrderManager from "@/pages/shopping/orderManager";
import Goods from "@/pages/shopping/goods";
import UserWithdrawal from "@/pages/shopping/userWithdrawal";
import Classification from "@/pages/shopping/classification";


import AppStoreManager from "@/pages/operation/appStoreManager";
import AppBannerManager from "@/pages/operation/appBannerManager";

import AppUserManager from "@/pages/operation/appUserManager";
import OperationManager from "@/pages/operation/operationManager";
import AppUserInfo from "@/pages/operation/appUserInfo";
import BusinessData from "@/pages/operation/businessData";
import FinanceData from "@/pages/operation/financeData";
import IncomeFoot from "@/pages/operation/incomeFoot";
import RateProcess from "@/pages/operation/rateProcess";
import AppVersionManager from "@/pages/operation/appVersionManager";


import UserCommission from "@/pages/orderManager/userCommission";
import IncomeOrderList from "@/pages/orderManager/incomeOrderList";
import OilOrder from "@/pages/orderManager/oilOrder";
import Equipment from "@/pages/equipment/index";


import BookclassificationManager from "@/pages/other/bookclassificationManager";
import BookManager from "@/pages/other/bookManager";


import Home from "../pages/home";
import Login from "../pages/views/login";
import PageNotFound from "../pages/views/notFound";
import { getStorage } from "../utils/common";

import {
  TransitionGroup,
  CSSTransition,
  SwitchTransition,
} from "react-transition-group";

const routes = [
  {
    path: "/",
    meta: {
      title: "首页",
    },
    element: <Home />,
  },
  {
    path: "/home",
    meta: {
      title: "首页",
    },
    element: <Home />,
  },
  {
    path: "system/userManager",
    meta: {
      title: "用户管理",
    },
    element: <UserManager />,
  },
  {
    path: "userManager/allocationRole/:id",
    meta: {
      title: "分配角色",
    },
    element: <AllocationRole />,
  },

  {
    path: "system/menu",
    meta: {
      title: "菜单管理",
    },
    element: <MenuManager />,
  },
  {
    path: "system/agreement",
    meta: {
      title: "协议管理",
    },
    element: <Agreement />,
  },
  
  {
    path: "system/roleManager",
    meta: {
      title: "角色管理",
    },
    element: <RoleManager />,
  },
  {
    path: "roleManager/scopeRole/:id",
    meta: {
      title: "分配用户",
    },
    element: <ScopeRole />,
  },
  {
    path: "system/divisionManager",
    meta: {
      title: "部门管理",
    },
    element: <DivisionManager />,
  },
  {
    path: "system/postManager",
    meta: {
      title: "岗位管理",
    },
    element: <PostManager />,
  },
  {
    path: "system/dict/:id",
    meta: {
      title: "字典类型管理",
    },
    element: <Dict />,
  },
  {
    path: "system/dictType",
    meta: {
      title: "字典管理",
    },
    element: <DictType />,
  },
  {
    path: "system/configManager",
    meta: {
      title: "参数配置",
    },
    element: <ConfigManager />,
  },
  {
    path: "system/log/operlog",
    meta: {
      title: "操作日志",
    },
    element: <Operlog />,
  },
  {
    path: "system/log/logininfor",
    meta: {
      title: "登录日志",
    },
    element: <Logininfor />,
  },

  {
    path: "system/areaManager",
    meta: {
      title: "地区管理",
    },
    element: <AreaManager />,
  },
  {
    path: "system/payManager",
    meta: {
      title: "支付管理",
    },
    element: <PayManager />,
  },

  {
    path: "merchant/withdrawalManager",
    meta: {
      title: "商户提现",
    },
    element: <WithdrawalManager />,
  },
  {
    path: "merchant/merchantOrderManager",
    meta: {
      title: "门店订单管理",
    },
    element: <MerchantOrderManager />,
  },
  {
    path: "merchant/merchantManager",
    meta: {
      title: "门店管理",
    },
    element: <MerchantManager />,
  },
  {
    path: "merchant/merInfo",
    meta: {
      title: "商家管理",
    },
    element: <MerInfo />,
  },

  {
    path: "statistics/merOrderStatisticsList",
    meta: {
      title: "门店与推荐会员订单统计",
    },
    element: <MerOrderStatisticsList />,
  },
  {
    path: "statistics/merAndVipStatisticsList",
    meta: {
      title: "用户-门店与推荐会员订单统计",
    },
    element: <MerAndVipStatisticsList />,
  },
  {
    path: "statistics/transferOrderManager",
    meta: {
      title: "转增订单管理",
    },
    element: <TransferOrderManager />,
  },
  {
    path: "statistics/merOrderWithOutOilStatisticsList",
    meta: {
      title: "门店除机油订单统计",
    },
    element: <MerOrderWithOutOilStatisticsList />,
  },

  {
    path: "shopping/orderManager",
    meta: {
      title: "订单管理",
    },
    element: <OrderManager />,
  },
  {
    path: "shopping/goods",
    meta: {
      title: "会员商品管理",
    },
    element: <Goods />,
  },
  {
    path: "shopping/userWithdrawal",
    meta: {
      title: "用户提现管理",
    },
    element: <UserWithdrawal />,
  },
  {
    path: "shopping/classification",
    meta: {
      title: "分类管理",
    },
    element: <Classification />,
  },
  
  {
    path: "operation/appStoreManager",
    meta: {
      title: "App图标管理",
    },
    element: <AppStoreManager />,
  },
  {
    path: "operation/appBannerManager",
    meta: {
      title: "轮播图管理",
    },
    element: <AppBannerManager />,
  },
  
  {
    path: "operation/appUserManager",
    meta: {
      title: "App用户中心图标管理",
    },
    element: <AppUserManager />,
  },

  {
    path: "operation/operationManager",
    meta: {
      title: "运营商管理",
    },
    element: <OperationManager />,
  },
  {
    path: "operation/appUserInfo",
    meta: {
      title: "app用户管理",
    },
    element: <AppUserInfo />,
  },

  {
    path: "operation/appVersionManager",
    meta: {
      title: "app版本管理",
    },
    element: <AppVersionManager />,
  },
  
  {
    path: "operation/rateProcess",
    meta: {
      title: "评价审核",
    },
    element: <RateProcess />,
  },
  
  {
    path: "operation/businessData",
    meta: {
      title: "运营商业务数据",
    },
    element: <BusinessData />,
  },
  {
    path: "operation/financeData",
    meta: {
      title: "运营商财务数据",
    },
    element: <FinanceData />,
  },
  {
    path: "operation/incomeFoot",
    meta: {
      title: "收益结算",
    },
    element: <IncomeFoot />,
  },
  {
    path: "orderManager/userCommission",
    meta: {
      title: "用户佣金订单",
    },
    element: <UserCommission />,
  },
  {
    path: "orderManager/incomeOrderList",
    meta: {
      title: "商家收益订单",
    },
    element: <IncomeOrderList />,
  },
  {
    path: "orderManager/oilOrder",
    meta: {
      title: "用户加油订单",
    },
    element: <OilOrder />,
  },
  {
    path: "equipment",
    meta: {
      title: "设备管理",
    },
    element: <Equipment />,
  },
  {
    path: "other/BookclassificationManager",
    meta: {
      title: "文章分类管理",
    },
    element: <BookclassificationManager />,
  },
  {
    path: "other/BookManager",
    meta: {
      title: "文章管理",
    },
    element: <BookManager />,
  },
  
  
  {
    path: "*",
    meta: {
      title: "404",
    },
    element: <PageNotFound />,
  },
];

const Routers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const tagList = useSelector(
    (state: { store: { tagList: any[] } }) => state.store.tagList
  );
  const menuTree = useSelector(
    (state: { menuTree: { value: any[] } }) => state.menuTree.value
  );
  const submenuKeys = useSelector(
    (state: { store: { submenuKeys: any[] } }) => state.store.submenuKeys
  );
  React.useEffect(() => {
    const pathList = location.pathname.split("/").filter((i) => i);
    let str = "";
    const pathAllList = pathList?.map((i) => {
      str += i + "/";
      return str.replace(/\/$/, "");
    });
    const pathStr = pathList.join("/");
    // console.log(pathAllList, "pathAllListpathAllList");
    // dispatch(setOpenKeys(pathAllList));
    if (location.pathname === "/") {
      dispatch(setKeyStr("home"));
      return;
    } else {
      dispatch(setKeyStr(pathStr));
    }
    if (menuTree.length && !tagList.some((i) => i.link === pathStr)) {
      const title =
        menuTree.find((i) => i.path === pathStr)?.menuName ||
        routes.find((i) => {
          return (
            i.path !== "/" && pathStr.indexOf(i.path.replace(":id", "")) !== -1
          );
        })?.meta.title;

      const breadcrumbData =
        pathStr === "/" || pathStr === "home"
          ? []
          : [
              {
                title:
                  submenuKeys?.find((i) => i.path === pathList[0])?.name || "",
                className: styles.dColor,
              },
              {
                title: title,
                className: styles.dColor,
              },
            ].filter((i) => i.title);

      dispatch(
        setTagList([
          ...tagList,
          {
            link: pathStr,
            title: title,
            breadcrumbData: [
              {
                title: "首页",
                className: "selectBreadcrumb",
                path: "",
              },
              ...breadcrumbData,
            ],
            selectedKeys: pathAllList,
          },
        ])
      );
    }
  }, [location.pathname, menuTree]);

  return (
    <SwitchTransition>
      <CSSTransition key={location.pathname} timeout={500} classNames="fade">
        <Routes location={location}>
          {routes.map(({ path, element }) => (
            <Route key={path} path={path} element={element}></Route>
          ))}
        </Routes>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default Routers;
