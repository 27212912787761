import http from "@/utils/request";

// CMS文章-下拉文章分类列表
export const categorybaseList = (params: any): Promise<any> => {
  return http.post("/biz/article/category/baseList", params);
};

// CMS文章-文章分类新增
export const categoryAdd = (params: defaultParams): Promise<any> => {
  return http.post("/biz/article/category/add", params);
};

// CMS文章-文章分类列表
export const infobaseList = (params: defaultParams): Promise<any> => {
  return http.post("/biz/article/category/list", params);
};
// CMS文章-文章分类列表
export const Bookclassificationdetail = (id: string): Promise<any> => {
  return http.get(`/biz/article/category/detail/${id}`)
};


// CMS文章-文章分类删除
export const categoryDelete = (id: string): Promise<any> => {
  return http.delete(`/biz/article/category/${id}`);
};

// CMS文章-文章分类修改
export const categoryEdit = (params: defaultParams): Promise<any> => {
  return http.post("/biz/article/category/edit", params);
};


// CMS文章-文章列表
export const bookList = (params: defaultParams): Promise<any> => {
  return http.post("/biz/article/info/list", params);
};

// CMS文章-文章新增
export const bookAdd = (params: defaultParams): Promise<any> => {
  return http.post("/biz/article/info/add", params);
};

// CMS文章-文章修改
export const bookEdit = (params: defaultParams): Promise<any> => {
  return http.post("/biz/article/info/edit", params);
};


// CMS文章-文章删除
export const bookDelete = (id: string): Promise<any> => {
  return http.delete(`/biz/article/info/${id}`);
};


// CMS文章-文章详情
export const bookDetail = (id: string): Promise<any> => {
  return http.get(`/biz/article/info/detail/${id}`);
};
