import { Button, Form, Input, message, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import PageTable from "@/components/i-pageTable/i-pageTable";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import styles from "@/static/css/index.module.css";
import { getAuthRole, authRole } from "@/api/setting";
import { useSelector, useDispatch, useStore } from "react-redux";
import { setTagList } from "@/stores/syncStore";

const AllocationRole = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const userId = Number(params.id);
  const [pageData, setPageData] = useState([]);
  const [changeKeys, setChangeKeys] = useState<number[]>([]);

  const tagList = useSelector(
    (state: { store: { tagList: any[] } }) => state.store.tagList
  );

  const [form] = Form.useForm();
  const columns = [
    {
      title: "角色编号",
      dataIndex: "roleId",
      key: "roleId",
    },
    {
      title: "角色名称",
      dataIndex: "roleName",
      key: "roleName",
    },
    {
      title: "权限字符",
      dataIndex: "roleKey",
      key: "roleKey",
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
  ];

  useEffect(() => {
    getPageData();
  }, []);

  const getPageData = async () => {
    try {
      const { roles, user } = await getAuthRole(userId);
      setPageData(roles);
      console.log(user?.roles, "data");
      setChangeKeys(user?.roles.map((i: { roleId: number }) => i.roleId) || []);
      form.setFieldsValue({
        userName: user.userName,
        nickName: user.nickName,
      });
    } catch (error) {}
  };

  const selectionChange = (selectKeys: any[]) => {
    setChangeKeys(selectKeys);
  };
  const save = async () => {
    if (!changeKeys.length) {
      message.error("请勾选需要分配的角色");
      return;
    }
    try {
      await authRole({
        roleIds: changeKeys,
        userId: userId,
      });
      message.success("分配成功");
      back();
    } catch (error) {}
  };
  const back = () => {
    dispatch(
      setTagList(
        tagList.filter((item) => "/" + item.link !== location.pathname)
      )
    );
    console.log(tagList, "tagListtagList");
    navigate(-1);
  };

  return (
    <div>
      <div className={styles.allocation_title}>基本信息</div>
      <Form
        form={form}
        disabled
        layout="inline"
        labelCol={{ span: 3, offset: 10 }}
      >
        <Form.Item name="nickName" label="用户昵称" style={{ width: "35%" }}>
          <Input></Input>
        </Form.Item>
        <Form.Item name="userName" label="登录账号" style={{ width: "35%" }}>
          <Input></Input>
        </Form.Item>
      </Form>
      <div className={styles.allocation_title}>角色信息</div>
      <PageTable
        selection
        selectedRowKeys={changeKeys}
        selectionChange={selectionChange}
        rowKey="roleId"
        columns={columns}
        data={pageData}
      ></PageTable>
      <div style={{ textAlign: "center" }}>
        <Space>
          <Button type="primary" onClick={save}>
            提交
          </Button>
          <Button onClick={back}>返回</Button>
        </Space>
      </div>
    </div>
  );
};

export default AllocationRole;
