import React, { useEffect, useState } from "react";
import PageTable from "@/components/i-pageTable/i-pageTable";
import PageTab from "@/components/i-pageTab/i-pageTab";
import { operlogList } from "@/api/setting";
import { Tag } from "antd";
import { resetForm } from "@/utils/common";

const operatorType = ["其它", "后台用户", "手机端用户"];

const selectOptions = [
  { label: "其它", value: 0 },
  { label: "新增", value: 1 },
  { label: "修改", value: 2 },
  { label: "删除", value: 3 },
];

const Dict = () => {
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState<GetCmsSysOperLogPageRes["rows"]>([]);

  const [formData, setFormData] = useState<GetCmsSysOperLogPageParams>({
    businessType: 0,
    endTime: "",
    operName: "",
    operParam: "",
    startTime: "",
    title: "",
    ypageNum: 1,
    ypageSize: 10,
  });

  const formTab = [
    {
      label: "标题",
      name: "title",
      type: "input",
    },
    {
      label: "请求参数",
      name: "operParam",
      type: "input",
    },
    {
      label: "操作人员",
      name: "operName",
      type: "input",
    },
    {
      label: "字典类型",
      name: "businessType",
      type: "select",
      options: selectOptions,
    },
    {
      label: "时间范围",
      name: "rangePicker",
      type: "rangePicker",
      width: "380px",
    },
  ];

  const columns = [
    {
      title: "模块标题",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "请求url",
      dataIndex: "operUrl",
      key: "operUrl",
    },
    {
      title: "请求方式",
      dataIndex: "requestMethod",
      key: "requestMethod",
    },
    {
      title: "请求参数",
      dataIndex: "operParam",
      key: "operParam",
      width: "360px",
    },
    {
      title: "方法名称",
      dataIndex: "method",
      key: "method",
      width: "260px",
    },
    {
      title: "返回参数",
      dataIndex: "jsonResult",
      key: "jsonResult",
      width: "360px",
    },
    {
      title: "错误消息",
      dataIndex: "errorMsg",
      key: "errorMsg",
      width: "260px",
    },
    {
      title: "操作人员",
      dataIndex: "operName",
      key: "operName",
    },
    {
      title: "操作地点",
      dataIndex: "operLocation",
      key: "operLocation",
    },
    {
      title: "主机地址",
      dataIndex: "operIp",
      key: "operIp",
    },
    {
      title: "部门名称",
      dataIndex: "deptName",
      key: "deptName",
    },
    {
      title: "操作地点",
      dataIndex: "operLocation",
      key: "operLocation",
    },
    {
      title: "操作类别",
      dataIndex: "operatorType",
      key: "operatorType",
      render: (val: number) => <Tag color="blue">{operatorType[val]}</Tag>,
    },
    {
      title: "操作状态",
      dataIndex: "status",
      key: "status",
      render: (val: number) => (
        <Tag color="blue">{val === 0 ? "正常" : "异常"}</Tag>
      ),
    },
    {
      title: "业务状态",
      dataIndex: "businessType",
      key: "businessType",
      render: (val: number) => (
        <Tag color="blue">
          {selectOptions.find((item) => item.value === val)?.label}
        </Tag>
      ),
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await operlogList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const search = (val: any) => {
    setFormData({ ...formData, ...val, ypageNum: 1 });
  };
  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };

  const reset = () => {
    setFormData({ ...resetForm(formData) });
  };

  return (
    <div>
      <PageTab reset={reset} search={search} formTab={formTab} defaultTimer={[]}></PageTab>
      <PageTable
        loading={loading}
        rowKey="operId"
        pageChange={pageChange}
        data={pageData}
        columns={columns}
        pagination={{
          total: total,
          current: formData.ypageNum,
        }}
      ></PageTable>
    </div>
  );
};

export default Dict;
