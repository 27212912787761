import React, { useEffect, useState } from "react";
import PageTable from "@/components/i-pageTable/i-pageTable";
import PageTab from "@/components/i-pageTab/i-pageTab";
import { agreementList, agreementAdd, agreementEdit } from "@/api/setting";
import { Button, message, Modal, Space, Tag } from "antd";
import { resetForm, setIcons } from "@/utils/common";
import { useSelector, useDispatch, useStore } from "react-redux";
import { dictInfoAsync } from "@/stores/dictInfo";

const Agreement = () => {
  const dispatch = useDispatch();
  const {
    state: { pay_select_status },
  } = useSelector((state: { dictInfo: any }) => state.dictInfo);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [pageData, setPageData] = useState<GetCmsAgreementPageRes["rows"]>([]);

  const [formData, setFormData] = useState<defaultParams>({
    ypageNum: 1,
    ypageSize: 10,
  });

  const [modalFormData, setModalFormData] = useState<{ [key: string]: any }>({
    name: "",
    agreementContext: "",
    agreementId:""
  });

  const modalFormTab = [
    {
      label: "协议号",
      name: "agreementId",
      type: "input",
      rules: [{ required: true, message: "请输入协议号" }],
      params: {
        disabled: !!modalFormData.type
      }
    },
    {
      label: "协议名称",
      name: "name",
      type: "input",
      rules: [{ required: true, message: "请输入协议名称" }],
    },
    {
      label: "内容",
      name: "agreementContext",
      type: "quill",
      rules: [{ required: true, message: "请输入协议内容" }],

      // labelCol: { span: 0, offset: 4 },
    },
  ];

  const columns = [
    {
      title: "协议号",
      dataIndex: "agreementId",
      key: "agreementId",
    },
    {
      title: "名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "更新时间",
      dataIndex: "updateTime",
      key: "updateTime",
    },
    {
      title: "操作",
      dataIndex: "agreementId",
      key: "agreementId",
      fixed: "right",
      width: "150px",
      render: (val: number, rows: any) => (
        <Space>
          <Button
            onClick={() => {
              setModalFormData({...rows, type: 'edit'})
              setOpen(true)
            }}
            size="small"
            icon={setIcons("EditOutlined")}
            type="text"
          >
            修改
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  useEffect(() => {
    !pay_select_status && dispatch(dictInfoAsync("pay_select_status") as any);
  }, []);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await agreementList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };

  const resetModal = () => {
    setModalFormData({
      name: "",
      agreementContext: "",
    });
    setOpen(false);
  };

  const confirmData = async (val: any) => {
    if (val.type) {
      try {
        await agreementEdit(val);
        message.success("编辑成功！");
        resetModal();
      } catch (error) {}
    } else {
      try {
        await agreementAdd(val);
        message.success("新增成功！");
        resetModal();
      } catch (error) {}
    }
    getPageData()
  };

  return (
    <div>
      <Modal
        destroyOnClose
        open={open}
        title={modalFormData.agreementId ? "编辑协议" : "新增协议"}
        width="60%"
        footer={<></>}
        onCancel={resetModal}
      >
        <PageTab
        layout="vertical"
          subFoot
          search={confirmData}
          reset={resetModal}
          formTab={modalFormTab}
          initialValues={modalFormData}
        ></PageTab>
      </Modal>
      <Button onClick={()=>setOpen(true)} icon={setIcons('PlusOutlined')}>新增</Button>
      <PageTable
        loading={loading}
        rowKey="agreementId"
        pageChange={pageChange}
        data={pageData}
        columns={columns}
        pagination={{
          total: total,
          current: formData.ypageNum,
        }}
      ></PageTable>
    </div>
  );
};

export default Agreement;
